import type { ModalProps } from "~/components/modal";
import type {
  PhysicalAddressInput,
  CompanyResidencesQuery,
  PhysicalAddressFieldsFragment,
  ResidenceFieldsFragment,
  SavePhysicalAddressMutation
} from "~/types/api";
import { LoadScript } from "@react-google-maps/api";
import _ from "lodash";
import { useEffect, useRef, useState, useCallback } from "react";
import Alert from "~/components/alert";
import { IconSearch } from "~/components/icons";
import Link from "~/components/link";
import Modal from "~/components/modal";
import { Buttons, Errors, Input, RemixForm } from "~/components/remix-form";
import { useFetcherData } from "~/utils/remix";

interface OuterProps extends BaseProps {
  companyId?: string;
  loadGoogleApi?: boolean;
}

const UNIT_TYPES = [
  "Unit",
  "Suite",
  "Apt",
  "Floor",
  "Room",
  "Building",
  "No.",
  "PMB",
  "Box",
  "P.O. Box",
  "#"
];

export default function PhysicalAddressForm({
  companyId,
  loadGoogleApi = true,
  ...props
}: OuterProps) {
  const fetcher = useFetcherData<CompanyResidencesQuery>(
    companyId ? "/resources/residences/company" : undefined,
    { id: companyId }
  );

  if (!companyId) {
    return loadGoogleApi ? (
      <LoadScript
        onError={() => {}}
        loadingElement={<div />}
        googleMapsApiKey="AIzaSyBe87vRMllAhTrMqoDsA8ZioBtChYlP7Qs"
        libraries={["places"]}
      >
        <Form {...props} />
      </LoadScript>
    ) : (
      <Form {...props} />
    );
  } else if (!fetcher.data) {
    return null;
  }

  return loadGoogleApi ? (
    <LoadScript
      onError={() => {}}
      loadingElement={<div />}
      googleMapsApiKey="AIzaSyBe87vRMllAhTrMqoDsA8ZioBtChYlP7Qs"
      libraries={["places"]}
    >
      <Form
        {...props}
        residences={fetcher.data.contact.residences.filter(
          (r) => !r.endDate && r.physicalAddress
        )}
      />
    </LoadScript>
  ) : (
    <Form
      {...props}
      residences={fetcher.data.contact.residences.filter(
        (r) => !r.endDate && r.physicalAddress
      )}
    />
  );
}

const get = (result: google.maps.places.PlaceResult, field: string): string => {
  const components = result.address_components || [];
  let match = components.find((ac) => ac.types.includes(field));
  if (!match && field === "locality") {
    match = components.find((ac) => ac.types.includes("postal_town"));
  }
  return match
    ? ["locality", "country"].includes(field)
      ? match.long_name
      : match.short_name
    : "";
};

interface BaseProps extends ModalProps {
  onSave?: (
    result: PhysicalAddressFieldsFragment | ResidenceFieldsFragment,
    replacing?: string
  ) => void;
  defaults?: PhysicalAddressInput;
  replacing?: string;
  migrating?: boolean;
  initialSearch?: string;
  defaultUnitType?: string;
}

interface InnerProps extends BaseProps {
  residences?: ResidenceFieldsFragment[];
}

const Form = ({
  onSave,
  replacing,
  onClose,
  defaults = {},
  defaultUnitType,
  initialSearch,
  residences
}: InnerProps) => {
  const unitRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();
  const [mode, _setMode] = useState<"Address" | "PO Box">("Address");

  const setMode = (mode: "Address" | "PO Box") => {
    setData((data) => ({
      ...data,
      unitType: mode === "Address" ? "" : "P.O. Box"
    }));
    _setMode(mode);
    autocomplete?.setOptions({
      types: mode === "PO Box" ? ["(regions)"] : ["geocode"]
    });
    setTimeout(() => inputRef.current?.focus(), 50);
  };

  const [data, setData] = useState<PhysicalAddressInput>({
    ...defaults,
    unitType: defaultUnitType
  });
  const [googleQuery, setGoogleQuery] = useState(initialSearch || "");
  const [populated, setPopulated] = useState(false);
  const [residenceId, setResidenceId] = useState("");

  const handleSuccess = (result: SavePhysicalAddressMutation) => {
    const pa = result.savePhysicalAddress;
    onSave?.(pa, replacing);
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    if (residenceId) {
      e.preventDefault();
      onSave?.(residences!.find((r) => r.id === residenceId)!, replacing);
    }
  };

  const extractGoogleResult = useCallback(
    (result: google.maps.places.PlaceResult) => {
      if (result.address_components) {
        setPopulated(true);
        let street = get(result, "intersection") || get(result, "route");
        if (street && street === street.toLowerCase()) {
          street = _.startCase(street);
        }
        const political = get(result, "political");
        const county = get(result, "administrative_area_level_2");
        setData((data) => ({
          ...data,
          premise: get(result, "premise"),
          number: get(result, "street_number"),
          street,
          city: get(result, "locality") || get(result, "neighborhood"),
          neighborhood: get(result, "neighborhood"),
          state: get(result, "administrative_area_level_1"),
          zip: get(result, "postal_code"),
          country: get(result, "country"),
          county,
          area: political === county ? undefined : political,
          unit: data.unit || get(result, "subpremise"),
          googleFormatted: result.formatted_address,
          latitude: result.geometry!.location!.lat().toString(),
          longitude: result.geometry!.location!.lng().toString(),
          northeastLatitude: result
            .geometry!.viewport!.getNorthEast()
            .lat()
            .toString(),
          northeastLongitude: result
            .geometry!.viewport!.getNorthEast()
            .lng()
            .toString(),
          southwestLatitude: result
            .geometry!.viewport!.getSouthWest()
            .lat()
            .toString(),
          southwestLongitude: result
            .geometry!.viewport!.getSouthWest()
            .lng()
            .toString()
        }));
        unitRef.current && unitRef.current.focus();
      }
    },
    []
  );

  useEffect(() => {
    // Input ref isn't set yet when this first runs, probably due to the modal. Wait a few ms...
    setTimeout(() => {
      if (inputRef.current) {
        setAutocomplete(new google.maps.places.Autocomplete(inputRef.current));
        inputRef.current.focus();
      }
    }, 50);
    return () => {
      setAutocomplete((ac) => {
        if (ac) {
          google.maps.event.clearInstanceListeners(ac);
        }
        return undefined;
      });
    };
  }, [inputRef]);

  useEffect(() => {
    let listener: google.maps.MapsEventListener;
    if (autocomplete) {
      listener = autocomplete.addListener("place_changed", () => {
        const result = autocomplete.getPlace();
        extractGoogleResult(result);
      });
    }
    return () => {
      if (listener) {
        google.maps.event.removeListener(listener);
      }
    };
  }, [extractGoogleResult, autocomplete]);

  // Prevent Google autocomplete selection from submitting form
  const blockEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const update = (field: string, value: unknown) =>
    setData((data) => ({ ...data, [field]: value }));

  return (
    <Modal onClose={onClose}>
      <Modal.Header
        title={replacing ? "Change an Address" : "Add an Address"}
      />
      <RemixForm
        data={data}
        fetcher
        action="/resources/physical-addresses/save"
        onSuccess={handleSuccess}
        onSubmit={submit}
      >
        <input
          type="hidden"
          name="communityId"
          value={data.communityId || ""}
        />
        <input type="hidden" name="latitude" value={data.latitude || ""} />
        <input type="hidden" name="longitude" value={data.longitude || ""} />
        <input
          type="hidden"
          name="northeastLatitude"
          value={data.northeastLatitude || ""}
        />
        <input
          type="hidden"
          name="northeastLongitude"
          value={data.northeastLongitude || ""}
        />
        <input
          type="hidden"
          name="southwestLatitude"
          value={data.southwestLatitude || ""}
        />
        <input
          type="hidden"
          name="southwestLongitude"
          value={data.southwestLongitude || ""}
        />
        <input
          type="hidden"
          name="googleFormatted"
          value={data.googleFormatted || ""}
        />
        <Modal.Body>
          {replacing && (
            <Alert mode="warning">
              This new address will be added to the contact, and the prior
              address will be saved under &quot;Former Addresses&quot;
            </Alert>
          )}
          <Errors />
          <div className="mb-4">
            <Link to={() => setMode(mode === "Address" ? "PO Box" : "Address")}>
              Search for {mode === "Address" ? "a PO Box" : "a full address"}{" "}
              instead
            </Link>
          </div>
          <Input
            type="text"
            name="googleQuery"
            value={googleQuery}
            data-1p-ignore
            onChange={(e) => setGoogleQuery(e.target.value)}
            labels={<IconSearch />}
            forwardRef={inputRef}
            onKeyPress={blockEnter}
            noLabel
            helpAfter={
              mode === "PO Box"
                ? "To add a PO Box, first search for the zip code, then add the PO Box as the unit number."
                : undefined
            }
            placeholder={`Search for ${
              mode === "Address" ? "an address" : "a zip code"
            }...`}
          />
          {residences && residences.length > 0 && (
            <Input
              name="residenceId"
              value={residenceId}
              onChange={(_name, value) => setResidenceId(value as string)}
              label="Pick a Location"
              type="combo"
              options={_.sortBy(
                residences.map((r) => ({
                  value: r.id,
                  label: `${
                    r.physicalAddress!.state && r.physicalAddress!.city
                      ? `${r.physicalAddress!.state} - ${
                          r.physicalAddress!.city
                        }: `
                      : ""
                  } ${r.physicalAddress!.formatted}`
                })),
                "label"
              )}
            />
          )}

          {data.googleFormatted && (
            <>
              {data.premise && (
                <>
                  <input type="hidden" name="premise" value={data.premise} />
                  <Input
                    name="premise"
                    value={data.premise}
                    label="Premise/Building Name"
                    disabled
                  />
                </>
              )}

              {(data.street || data.number) && (
                <div className="grid grid-cols-4 gap-8">
                  <div>
                    <input
                      type="hidden"
                      name="number"
                      value={data.number || ""}
                    />
                    <Input name="number" disabled />
                  </div>
                  <div className="col-span-3">
                    <input
                      type="hidden"
                      name="street"
                      value={data.street || ""}
                    />
                    <Input name="street" disabled />
                  </div>
                </div>
              )}
              {(data.street || data.number || mode === "PO Box") && (
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      name="unitType"
                      label="Unit Type"
                      value={data.unitType}
                      onChange={update}
                      type="combo"
                      options={UNIT_TYPES}
                    />
                  </div>
                  <div className="col-md-9">
                    <Input
                      name="unit"
                      label="Unit Number"
                      forwardRef={unitRef}
                    />
                  </div>
                </div>
              )}
              {data.area &&
                (!data.county ? (
                  <Input name="area" value={data.area} disabled />
                ) : (
                  <input type="hidden" name="area" value={data.area} />
                ))}
              {data.neighborhood &&
                (!data.street && !data.number && mode === "Address" ? (
                  <Input
                    name="neighborhood"
                    value={data.neighborhood}
                    disabled
                  />
                ) : (
                  <input
                    type="hidden"
                    name="neighborhood"
                    value={data.neighborhood}
                  />
                ))}
              <div className="grid grid-cols-3 gap-8">
                <input type="hidden" name="city" value={data.city || ""} />
                <input type="hidden" name="state" value={data.state || ""} />
                <input type="hidden" name="zip" value={data.zip || ""} />
                <Input name="city" value={data.city || ""} disabled />
                <Input name="state" value={data.state || ""} disabled />
                <Input name="zip" value={data.zip || ""} disabled />
              </div>
              {data.county && (
                <>
                  <input type="hidden" name="county" value={data.county} />
                  <Input name="county" value={data.county} disabled />
                </>
              )}
              {data.country &&
                (data.country !== "United States" ? (
                  <Input name="country" value={data.country} disabled />
                ) : (
                  <input type="hidden" name="country" value={data.country} />
                ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal disabled={!populated && !residenceId} />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
