import type { ModalProps } from "~/components/modal";
import type {
  SaveContactMutation,
  ContactPickerFieldsFragment
} from "~/types/api";
import _ from "lodash";
import { useState } from "react";
import Modal from "~/components/modal";
import { Buttons, Errors, Input, RemixForm } from "~/components/remix-form";
import { RemixFormContext } from "~/contexts";

interface Props extends ModalProps {
  onSave(result: ContactPickerFieldsFragment): void;
  prefill?: string;
}

export default function AddCompany({ onClose, onSave, prefill }: Props) {
  const [name, setName] = useState(prefill || "");

  const handleSuccess = (result: SaveContactMutation) => {
    onSave(result.saveContact);
    onClose();
  };

  return (
    <>
      <Modal.Header title="Add a Company" />
      <RemixForm
        fetcher
        action="/resources/contacts/save"
        onSuccess={handleSuccess}
      >
        <input type="hidden" name="mode" value="Company" />
        <Modal.Body>
          <Errors />
          <Input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
          />
          <RemixFormContext.Consumer>
            {(form) =>
              (
                (form?.errors &&
                  _.isArray(form.errors) &&
                  form.errors[0]?.changeset?.errors["name"]) ||
                []
              ).some((m) => m.startsWith("already exists")) && (
                <Input
                  name="confirmUnique"
                  type="checkbox"
                  label="Confirm that this is a different company"
                  inline
                />
              )
            }
          </RemixFormContext.Consumer>
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal disabled={!name} />
        </Modal.Footer>
      </RemixForm>
    </>
  );
}
