import type {
  ComboBoxOption,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type { SalesStagesQuery } from "~/types/api";
import _ from "lodash";
import { useMemo, useCallback } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export type SalesStagePickerProps = ComboBoxParentPropsSingle & {
  omitWon?: boolean;
};

export default function SalesStagePicker({
  onChange,
  omitWon = false,
  ...rest
}: SalesStagePickerProps) {
  const fetcher = useFetcherData<SalesStagesQuery>(
    "/resources/sales/stage-picker"
  );
  const salesStages = useMemo(
    () => fetcher.data?.salesStages || [],
    [fetcher.data]
  );
  const options = useMemo(
    () =>
      _.sortBy(salesStages, "probability")
        .filter((ss) => !omitWon || ss.probability < 100)
        .map(
          (ss): ComboBoxOption => ({
            value: ss.id,
            label: ss.description,
            subtitle: ss.notes
          })
        ),
    [omitWon, salesStages]
  );

  const localOnChange = useCallback(
    (name: string, value: string) => {
      const stage = salesStages.find((s) => s.id === value)!;
      onChange?.(name, value, stage);
    },
    [onChange, salesStages]
  );

  return (
    <ComboBox
      placeholder="Select a sales stage..."
      {...rest}
      options={options}
      onChange={localOnChange}
    />
  );
}
