import type {
  FindOrSaveShortLinkMutation,
  AttachedFileFieldsFragment
} from "~/types/api";
import { useFetcher } from "@remix-run/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import Button from "~/components/button";
import { IconLink, IconLoading } from "~/components/icons";
import { fetcherSucceeded } from "~/components/remix-form";
import { checkBrowser } from "~/utils/protocols";

interface Props {
  href: string;
  description?: string;
  mode?: "button" | "link";
  attachedFile?: AttachedFileFieldsFragment;
}

export default function ShortLinkGenerator({
  href,
  description,
  attachedFile,
  mode = "button"
}: Props) {
  const codes = _.orderBy(
    attachedFile?.link?.codes || [],
    ["visits", "id"],
    ["desc", "asc"]
  );
  const [pending, setPending] = useState(false);
  const [code, setCode] = useState<string>(codes.length ? codes[0].code : "");
  const [copied, setCopied] = useState(false);

  // Safari doesn't yet support permissions API to allow indirect clipboard access
  const safari = checkBrowser().isSafari;
  const fetcher = useFetcher<FindOrSaveShortLinkMutation>();

  const generateUrl = async () => {
    setPending(true);
    const fd = new FormData();
    fd.set("url", href);
    if (description) fd.set("description", description);
    if (attachedFile) fd.set("attachedFileId", attachedFile.id);
    fetcher.submit(fd, { action: "/resources/links/generate", method: "post" });
  };

  useEffect(() => {
    if (fetcherSucceeded(fetcher)) {
      if (fetcher.data) {
        setPending(false);
        setCode(fetcher.data.findOrSaveShortLink.codes[0].code);
        !safari &&
          navigator.clipboard
            ?.writeText(
              `pfcs.co/${fetcher.data.findOrSaveShortLink.codes[0].code}`
            )
            .then(() => setCopied(true))
            .catch(() => {
              // Ignore errors
            });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.state, safari]);

  return code ? (
    mode === "button" ? (
      <span style={{ paddingTop: 3, paddingRight: 9, float: "left" }}>
        {copied && "Copied to Clipboard: "}
        <a href={`http://pfcs.co/${code}`}>
          pfcs.co/
          {code}
        </a>
      </span>
    ) : (
      <a href={`http://pfcs.co/${code}`}>
        pfcs.co/
        {code}
      </a>
    )
  ) : mode === "button" ? (
    <Button small onClick={generateUrl}>
      {pending ? <IconLoading fixed /> : <IconLink fixed />} Get PFCS.co URL
    </Button>
  ) : (
    <a onClick={generateUrl} title="Get PFCS.co URL">
      {pending ? <IconLoading fixed /> : <IconLink fixed />}
    </a>
  );
}
