import * as Sentry from "@sentry/react";
import _ from "lodash";

export interface NestedFile {
  file: File;
  path: string[];
}

const getFile = (entry: FileSystemEntry): Promise<File> => {
  return new Promise((resolve) => {
    (entry as FileSystemFileEntry).file((result) => {
      resolve(result as File);
    });
  });
};

const readDirectory: (
  directory: FileSystemDirectoryEntry,
  parentPath: string[]
) => Promise<NestedFile[]> = async (directory, parentPath) => {
  const path = [...parentPath, directory.name];
  return new Promise((resolve) => {
    let files: NestedFile[] = [];
    const reader = directory.createReader();
    const read = () => {
      reader.readEntries(async (entries) => {
        if (entries.length === 0) {
          resolve(files);
          return;
        }
        for (const entry of entries) {
          if (entry.isFile) {
            const file = await getFile(entry);
            files.push({ file, path });
          } else if (entry.isDirectory) {
            files = [
              ...files,
              ...(await readDirectory(entry as FileSystemDirectoryEntry, path))
            ];
          }
        }
        read();
      });
    };
    read();
  });
};

export const filterDataTransferFiles = (items: DataTransferItemList) => {
  const filtered: DataTransferItem[] = [];
  for (let index = 0; index < items.length; index++) {
    if (items[index].kind === "file") {
      filtered.push(items[index]);
    }
  }
  return filtered;
};

/**
 * Converts dragged files to a nested list of files. This is necessary because
 * the DataTransferItem interface does not provide a way to get the full path
 * of a file. It also doesn't provide directory info, so we need to use
 * webkitGetAsEntry to get that.
 * @param items DataTransferItem[]
 * @returns NestedItem[]
 */
export const convertDataTransferToFiles: (
  items: DataTransferItem[]
) => Promise<NestedFile[]> = async (items) => {
  const _items = [];
  for (let index = 0; index < items.length; index++) {
    _items.push({
      file: items[index].getAsFile() as File,
      wkItem: items[index].webkitGetAsEntry()
    });
    if (!items[index].webkitGetAsEntry()) {
      Sentry.addBreadcrumb({
        category: "debug",
        message: "wkItem",
        data: { item: JSON.stringify(items[index]) },
        level: "info"
      });
    }
  }

  let fileItems: NestedFile[] = [];
  for (const _item of _items) {
    const { file, wkItem } = _item;
    if (wkItem) {
      if (wkItem.isFile) {
        fileItems.push({ file: file!, path: [] });
      } else if (wkItem.isDirectory) {
        fileItems = [
          ...fileItems,
          ...(await readDirectory(wkItem as FileSystemDirectoryEntry, []))
        ];
      }
    }
  }

  fileItems = fileItems.filter((i) => !i.file.name.startsWith("."));
  return _.sortBy(
    fileItems,
    (i) => (i.path.length ? i.path : ["zzz"]),
    "file.name"
  );
};
