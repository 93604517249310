import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";

export const TEN_TOUCH_TYPES = [
  "Email",
  "Mailchimp",
  "LinkedIn Connection",
  "LinkedIn InMail",
  "US Mail",
  "Handwritten Note",
  "Manual Email",
  "Call",
  "Meeting",
  "Other"
];

export type TenTouchTypePickerPropsSingle = ComboBoxParentPropsSingle & {
  contentOnly?: boolean;
};
export type TenTouchTypePickerPropsMulti = ComboBoxParentPropsMultiple & {
  contentOnly?: boolean;
};

export default function TenTouchTypePicker({
  contentOnly,
  ...rest
}: TenTouchTypePickerPropsSingle | TenTouchTypePickerPropsMulti) {
  return (
    <ComboBox
      {...rest}
      placeholder={
        rest.multiple ? "Select at least one touch type..." : "Select a type..."
      }
      options={contentOnly ? TEN_TOUCH_TYPES.slice(0, 5) : TEN_TOUCH_TYPES}
    />
  );
}
