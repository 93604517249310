import type { PhotoViewerFieldsFragment } from "~/types/api";
import clsx from "clsx";
import { photoNumber } from "~/utils/photos";

interface Props {
  photo: PhotoViewerFieldsFragment;
  selected?: boolean;
  onClick?: (id: string) => void;
}

export default function PhotoThumbnail({ photo, onClick, selected }: Props) {
  const label = photoNumber(photo);

  return (
    <div
      className={clsx("photo-thumb", selected && "photo-thumb-selected")}
      onClick={() => onClick?.(photo.id)}
    >
      <img
        src={photo.upload?.thumbUrl || photo.upload?.smallOrFullUrl || ""}
        className="center-block"
      />
      <div className="photo-number">{label}</div>
    </div>
  );
}
