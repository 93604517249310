import type { ReactNode } from "react";
import LinkifyJS from "linkify-react";
import React from "react";

const opts = { target: "_blank" };

type Props = { lineBreaks?: boolean; children: ReactNode };

export default function Linkify({ children, lineBreaks }: Props) {
  return lineBreaks ? (
    <LinkifyJS options={opts} tagName="div" className="auto-line-breaks">
      {children}
    </LinkifyJS>
  ) : (
    <LinkifyJS options={opts}>{children}</LinkifyJS>
  );
}
