export function checkBrowser() {
  if (typeof document !== "undefined") {
    const ua = navigator.userAgent.toLowerCase();
    return {
      isSafari:
        (~ua.indexOf("safari") && !~ua.indexOf("chrome")) ||
        Object.prototype.toString
          .call(window.HTMLElement)
          .indexOf("Constructor") > 0
    };
  } else {
    return {
      isSafari: false
    };
  }
}
