import type { ErrorsWithChangeset } from "~/components/remix-form";
import type { NestedFile } from "~/utils/file-reader";
import _ from "lodash";
import { useEffect, useCallback, useReducer, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { v1 as uuidv1 } from "uuid";
import {
  convertDataTransferToFiles,
  filterDataTransferFiles
} from "~/utils/file-reader";
import { normalizedNumber } from "~/utils/formatting";

const IGNORED_FILES = [
  ".ds_store",
  "thumbs.db",
  "desktop.ini",
  "@please use new pdx file server"
];

export enum FileState {
  Pending,
  PossibleDuplicate,
  Reading,
  Checking,
  CheckingMD5,
  Ready,
  Waiting,
  Uploading,
  ReadyRemote,
  UploadingRemote,
  Error,
  Done
}

export interface FileUploadEntry {
  index: number;
  state: FileState;
  path: string[];
  file: File;
  fullPath: string;
  folder: string;
  uuid: string;
  md5?: string | null;
  xxHash?: string | null;
  error?: ErrorsWithChangeset;
  duplicate?: boolean;
  link?: string;
  progress: number;
  remoteId?: string;
  uploadUrl?: string;
  awsUploadId?: string | null;
}

interface BatchUploadOptions {
  filterType?: string[];
  defaultState?: FileState;
  mutate: (file: FileUploadEntry) => Promise<unknown>;
  s3?: boolean;
  afterUpload?: (id: string) => Promise<unknown>;
  concurrency?: number;
  remoteConcurrency?: number;
  files?: NestedFile[];
}

interface State {
  defaultState: FileState;
  files: FileUploadEntry[];
  filterType?: string[];
  state: FileState;
  error: ErrorsWithChangeset | null;
  startTime?: number;
  timeRemaining?: number;
  totalSize?: number;
}

export interface BatchUploaderProgress {
  completed: number;
  pending: number;
  total: number;
  percentage: number;
}

interface BatchUploader {
  setFiles: (list: FileList | File | NestedFile[] | null) => void;
  addFiles: (list: FileList | File | NestedFile[] | null) => void;
  files: FileUploadEntry[];
  startUpload: () => void;
  retry: () => void;
  status: FileState;
  error: ErrorsWithChangeset | null;
  progress: BatchUploaderProgress;
  removeFile: (entry: FileUploadEntry) => void;
  updateFile: (
    file: FileUploadEntry,
    updates: Partial<FileUploadEntry>
  ) => void;
  applyUpdates: (
    updater: (items: FileUploadEntry[]) => FileUploadEntry[]
  ) => void;
  timeRemaining?: number;
  removeDuplicates: () => void;
  dropFiles: (items: DataTransferItemList) => void;
}

type BatchUploadAction =
  | { type: "UPDATE_PROGRESS" }
  | { type: "SET_FILES"; value: FileList | File | NestedFile[] | null }
  | { type: "REMOVE_FILE"; value: FileUploadEntry }
  | { type: "COMPLETE_FILE"; value: FileUploadEntry }
  | { type: "ADD_FILES"; value: FileList | File | NestedFile[] | null }
  | { type: "CLEAR_FILES" | "DONE" | "ERROR" | "UPLOAD" | "RETRY" }
  | { type: "REMOVE_DUPLICATES" }
  | {
      type: "APPLY_UPDATES";
      updater: (items: FileUploadEntry[]) => FileUploadEntry[];
    }
  | {
      type: "UPDATE_FILE";
      value: FileUploadEntry;
      updates: Partial<FileUploadEntry>;
    };

type RemoteUploadInfo = { id: string; url: string };

const reducer: React.Reducer<State, BatchUploadAction> = (state, action) => {
  switch (action.type) {
    case "ERROR":
      return { ...state, state: FileState.Error };
    case "COMPLETE_FILE": {
      const index = state.files.findIndex((f) => f.uuid === action.value.uuid);
      const match = state.files[index];
      const completed = state.files.filter(
        (f) => f.uuid === action.value.uuid || f.state === FileState.Done
      );
      const completedBytes = completed.reduce(
        (acc, file) => acc + file.file.size,
        0
      );
      const percentDone = completedBytes / state.totalSize!;
      const timeElapsed = performance.now() - state.startTime!;
      const remaining = timeElapsed / percentDone - timeElapsed;
      // console.log(
      //   completed.length,
      //   "completed after ",
      //   performance.now() - state.startTime!,
      //   "ms",
      //   completedBytes,
      //   "bytes (",
      //   completedBytes / state.totalSize!,
      //   ")",
      //   "remaining: ",
      //   remaining
      // );
      return {
        ...state,
        timeRemaining: remaining,
        files: [
          ...state.files.slice(0, index),
          { ...match, state: FileState.Done },
          ...state.files.slice(index + 1)
        ]
      };
    }
    case "REMOVE_FILE":
      return {
        ...state,
        files: state.files.filter((f) => f.uuid !== action.value.uuid)
      };
    case "REMOVE_DUPLICATES":
      return { ...state, files: state.files.filter((f) => !f.duplicate) };

    case "UPDATE_PROGRESS":
      return {
        ...state,
        timeRemaining: state.timeRemaining
          ? state.timeRemaining - 1000
          : state.timeRemaining
      };
    case "UPDATE_FILE": {
      const index = state.files.findIndex((f) => f.uuid === action.value.uuid);
      const match = state.files[index];
      return {
        ...state,
        files: [
          ...state.files.slice(0, index),
          { ...match, ...action.updates },
          ...state.files.slice(index + 1)
        ]
      };
    }
    case "APPLY_UPDATES":
      return { ...state, files: action.updater(state.files) };
    case "DONE":
      console.timeEnd("useBatchUploader");
      return { ...state, state: FileState.Done };
    case "UPLOAD":
      console.time("useBatchUploader");

      return {
        ...state,
        state: FileState.Uploading,
        startTime: performance.now(),
        totalSize: state.files.reduce((acc, file) => acc + file.file.size, 0)
      };
    case "RETRY": {
      const files = state.files.map(
        (f): FileUploadEntry => ({
          ...f,
          state:
            f.state === FileState.Error
              ? f.uploadUrl
                ? FileState.ReadyRemote
                : FileState.Ready
              : f.state
        })
      );
      return { ...state, files, state: FileState.Uploading };
    }
    case "SET_FILES": {
      return {
        ...state,
        files: parseFiles(action.value, state.filterType, state.defaultState)
      };
    }
    case "ADD_FILES": {
      return {
        ...state,
        files: [
          ...state.files,
          ...parseFiles(
            action.value,
            state.filterType,
            state.defaultState,
            state.files.length
          )
        ]
      };
    }
    default:
      return state;
  }
};

export const parseFile = (
  file: File,
  index: number,
  state: FileState,
  path: string[] = []
): FileUploadEntry => ({
  file,
  index,
  progress: 0,
  path,
  state,
  folder: file.webkitRelativePath.split("/").slice(1, -1).join("/"),
  fullPath: file.webkitRelativePath.split("/").slice(1).join("/"),
  uuid: uuidv1()
});

export const parseFiles = (
  files: null | FileList | File | NestedFile[],
  filterType: string[] | undefined,
  state: FileState,
  offset = 0
) => {
  if (!files) {
    return [];
  }

  let list: (NestedFile | File)[] =
    "type" in files
      ? [files as File]
      : typeof FileList !== "undefined" && files instanceof FileList
        ? ([...files] as File[])
        : (files as NestedFile[]);

  if (filterType) {
    list = list.filter((file) =>
      filterType!.includes("file" in file ? file.file.type : file.type)
    );
  }
  // Ignore macOS temporary files
  list = list.filter((f) => {
    const name = "file" in f ? f.file.name.toLowerCase() : f.name.toLowerCase();
    return (
      !IGNORED_FILES.includes(name) &&
      !name.match(/^(~\$|~wr.*tmp$|shortcut.*lnk$)/)
    );
  });

  return _.sortBy(list, (item) =>
    normalizedNumber(
      "file" in item ? item.file.webkitRelativePath : item.webkitRelativePath
    )
  ).map((item, i) =>
    "file" in item
      ? parseFile(item.file, offset + i, state, item.path)
      : parseFile(item, offset + i, state)
  );
};

export const useBatchUploader = (
  options: BatchUploadOptions
): BatchUploader => {
  const [params] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, {
    files: parseFiles(
      options.files || [],
      options.filterType,
      options.defaultState ?? FileState.Ready
    ),
    defaultState: options.defaultState ?? FileState.Ready,
    state: FileState.Ready,
    filterType: options.filterType,
    error: null
  });

  const startUpload = useCallback(() => {
    dispatch({ type: "UPLOAD" });
    setInterval(() => {
      dispatch({ type: "UPDATE_PROGRESS" });
    }, 1000);
  }, []);

  const remoteUploadNextFile = useCallback(() => {
    const file = state.files.find((f) => f.state === FileState.ReadyRemote);
    if (file) {
      dispatch({
        type: "UPDATE_FILE",
        value: file,
        updates: { state: FileState.UploadingRemote }
      });
      if (params.get("debug")) {
        dispatch({
          type: "COMPLETE_FILE",
          value: file
        });
        return;
      }
      fetch(file.uploadUrl!, { method: "PUT", body: file.file })
        .then((response) => {
          if (!response.ok) {
            throw new Error();
          }
          if (options.afterUpload) {
            options
              .afterUpload(file.remoteId!)
              .then(() =>
                dispatch({
                  type: "COMPLETE_FILE",
                  value: file
                })
              )
              .catch(() => {
                dispatch({
                  type: "UPDATE_FILE",
                  value: file,
                  updates: {
                    state: FileState.Error
                  }
                });
              });
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_FILE",
            value: file,
            updates: {
              state: FileState.Error
            }
          });
        });
    }
  }, [state.files, options, params]);

  const uploadNextFile = useCallback(() => {
    const file = state.files.find((f) => f.state === FileState.Ready);
    if (file) {
      dispatch({
        type: "UPDATE_FILE",
        value: file,
        updates: { state: FileState.Uploading }
      });
      options
        .mutate(file)
        .then(async (result) => {
          const json =
            result instanceof Response
              ? await (result as Response).json()
              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (result as any);
          if (json.errors) {
            dispatch({
              type: "UPDATE_FILE",
              value: file,
              updates: {
                error: json.errors,
                state: FileState.Error
              }
            });
          } else if (options.s3) {
            const { id, url } = result as RemoteUploadInfo;
            dispatch({
              type: "UPDATE_FILE",
              value: file,
              updates: {
                state: FileState.ReadyRemote,
                uploadUrl: url,
                remoteId: id
              }
            });
          } else {
            dispatch({
              type: "COMPLETE_FILE",
              value: file
            });
          }
        })
        .catch((error) =>
          dispatch({
            type: "UPDATE_FILE",
            value: file,
            updates: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              error: error as any,
              state: FileState.Error
            }
          })
        );
    }
  }, [options, state.files]);

  useEffect(() => {
    if (state.state === FileState.Uploading) {
      if (state.files.every((f) => f.state === FileState.Done)) {
        dispatch({ type: "DONE" });
      } else if (
        state.files.every((f) =>
          [FileState.Error, FileState.Done].includes(f.state)
        )
      ) {
        dispatch({ type: "ERROR" });
      }

      if (
        state.files.filter((f) => f.state === FileState.Uploading).length <
        (options.concurrency || 1)
      ) {
        uploadNextFile();
      }
      if (
        state.files.filter((f) => f.state === FileState.UploadingRemote)
          .length < (options.remoteConcurrency || options.concurrency || 1)
      ) {
        remoteUploadNextFile();
      }
    }
  }, [
    options.concurrency,
    options.remoteConcurrency,
    state.files,
    state.state,
    uploadNextFile,
    remoteUploadNextFile
  ]);

  const warnIfIncomplete = useCallback(
    (event: BeforeUnloadEvent) => {
      if (state.state === FileState.Uploading) {
        event.returnValue =
          "An upload is in progress! Are you sure you want to leave this page?";
        return event.returnValue;
      }

      return null;
    },
    [state.state]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", warnIfIncomplete);
    return () => window.removeEventListener("beforeunload", warnIfIncomplete);
  }, [warnIfIncomplete]);

  const setFiles = useCallback(
    (list: FileList | File | NestedFile[] | null) =>
      dispatch({ type: "SET_FILES", value: list }),
    []
  );
  const addFiles = useCallback(
    (list: FileList | File | NestedFile[] | null) =>
      dispatch({ type: "ADD_FILES", value: list }),
    []
  );

  const removeFile = useCallback((entry: FileUploadEntry) => {
    dispatch({
      type: "REMOVE_FILE",
      value: entry
    });
  }, []);
  const updateFile = useCallback(
    (file: FileUploadEntry, updates: Partial<FileUploadEntry>) => {
      dispatch({ type: "UPDATE_FILE", value: file, updates });
    },
    []
  );

  const applyUpdates = useCallback(
    (updater: (items: FileUploadEntry[]) => FileUploadEntry[]) => {
      dispatch({ type: "APPLY_UPDATES", updater });
    },
    []
  );

  const progress: BatchUploaderProgress = useMemo(() => {
    const p: BatchUploaderProgress = {
      completed: state.files.filter((f) => f.state === FileState.Done).length,
      pending: state.files.filter((f) => f.state === FileState.Uploading)
        .length,
      total: state.files.length,
      percentage: 0
    };

    if (p.total) {
      p.percentage = ((p.completed + p.pending / 2) / p.total) * 100;
    }
    return p;
  }, [state.files]);

  const retry = useCallback(() => {
    dispatch({ type: "RETRY" });
  }, []);

  const removeDuplicates = useCallback(() => {
    dispatch({ type: "REMOVE_DUPLICATES" });
  }, []);

  const dropFiles = useCallback((items: DataTransferItemList) => {
    const filtered = filterDataTransferFiles(items);
    if (filtered.length > 0) {
      convertDataTransferToFiles(filtered).then((result) => {
        dispatch({ type: "ADD_FILES", value: result });
      });
    }
  }, []);

  const value = useMemo(
    () => ({
      setFiles,
      addFiles,
      dropFiles,
      progress,
      retry,
      startUpload,
      updateFile,
      applyUpdates,
      timeRemaining: state.timeRemaining,
      error: state.error,
      files: state.files,
      status: state.state,
      removeFile,
      removeDuplicates
    }),
    [
      addFiles,
      applyUpdates,
      progress,
      retry,
      setFiles,
      startUpload,
      state.error,
      state.files,
      state.state,
      state.timeRemaining,
      updateFile,
      removeFile,
      removeDuplicates,
      dropFiles
    ]
  );
  return value;
};
