import type { ReactNode } from "react";
import clsx from "clsx";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  buttons?: ReactNode;
  labels?: ReactNode;
}

export default function InputGroup({
  className = "",
  labels,
  buttons,
  children,
  ...rest
}: Props) {
  return (
    <div
      className={clsx((labels || buttons) && "input-group", className)}
      {...rest}
    >
      {labels && <span className="input-group-addon">{labels}</span>}
      {children}
      {buttons && <span className="input-group-btn">{buttons}</span>}
    </div>
  );
}
