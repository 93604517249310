import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useLocationPusher } from "~/components/link";
import { formatDate, quartersInRange } from "~/utils/dates";

export interface QuarterPickerProps
  extends Omit<ComboBoxParentPropsSingle, "onChange"> {
  start?: Date;
  redirect?: boolean;
  onChange?: (name: string, value: string) => void;
  future?: number;
}

export default function QuarterPicker({
  start = new Date(2017, 0, 1),
  onChange,
  redirect,
  future,
  placeholder = "Quarter...",
  ...props
}: QuarterPickerProps) {
  const push = useLocationPusher();

  const handleChange = (sName: string, sValue: string) => {
    if (redirect) {
      push({ [props.name]: sValue, month: undefined, year: undefined });
    } else {
      onChange?.(sName, sValue);
    }
  };
  const options = quartersInRange({ start, future: future })
    .reverse()
    .map((q) => ({
      value: formatDate(q, { format: "YYYY-'Q'Q" }),
      label: formatDate(q, { format: "YYYY: 'Q'Q" })
    }));

  return (
    <ComboBox
      placeholder={placeholder}
      {...props}
      options={options}
      onChange={handleChange}
    />
  );
}
