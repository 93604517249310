import clsx from "clsx";
import _ from "lodash";
import { useState } from "react";
import { formatNumber } from "~/utils/formatting";

export interface NumberInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "value" | "onChange"
  > {
  onChange?: (field: string, value: string) => void;
  higherPrecision?: boolean;
  name: string;
  value?: string | null;
  className?: string;
  money?: boolean;
  percent?: boolean;
}

export default function NumberInput({
  onChange,
  name,
  value,
  percent,
  higherPrecision,
  money,
  defaultValue,
  ...rest
}: NumberInputProps) {
  const [currentVal, setCurrentVal] = useState(
    value || (defaultValue as string)
      ? `${formatNumber(value || (defaultValue as string), {
          zeroes: true,
          format: _.isInteger(value || defaultValue)
            ? `${money ? "$" : ""}0,0`
            : higherPrecision
              ? `${money ? "$" : ""}0,0.00[00000]`
              : `${money ? "$" : ""}0,0.00`
        })}${percent ? "%" : ""}`
      : ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentVal(event.target.value);
    onChange?.(name, event.target.value);
  };
  return (
    <input
      {...rest}
      type="text"
      name={name}
      className={clsx("form-control", rest.className)}
      value={currentVal}
      onChange={handleChange}
    />
  );
}
