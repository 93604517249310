import type {
  ComboBoxOption,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type {
  DocumentHeaderFieldsFragment,
  DocumentShowFieldsFragment,
  DocumentSectionsQuery
} from "~/types/api";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ComboBox from "~/components/combobox";
import {
  ContentTopicContext,
  useOptionalEmployee,
  useOptionalProject
} from "~/contexts";
import { eventManager } from "~/utils/event-manager";
import { useFetcherData } from "~/utils/remix";

const renderSingleValue = (option: ComboBoxOption) => (
  <div className="truncate pr-16">
    <strong>{option.section}</strong> {option.name}
  </div>
);

const renderOption = (option: ComboBoxOption) => (
  <div
    style={
      option.indentLevel && option.indentLevel > 0
        ? { marginLeft: option.indentLevel * 15 }
        : {}
    }
  >
    <strong>{option.section}</strong> {option.name}
  </div>
);

export interface SectionComboProps extends ComboBoxParentPropsSingle {
  skip?: string | null;
  excludeFolders?: boolean;
}

export default function SectionCombo({
  skip,
  excludeFolders,
  ...rest
}: SectionComboProps) {
  const employee = useOptionalEmployee();
  const contentTopic = useContext(ContentTopicContext);
  const project = useOptionalProject();
  const [documents, setDocuments] = useState<DocumentHeaderFieldsFragment[]>(
    []
  );
  const fetcher = useFetcherData<DocumentSectionsQuery>(
    "/resources/documents/sections",
    employee
      ? { employeeId: employee.id, employeeScope: employee.scope }
      : contentTopic
        ? { contentTopicId: contentTopic.id }
        : { projectNumber: project!.number }
  );

  useEffect(() => {
    const handler = (result: DocumentShowFieldsFragment) => {
      setDocuments((documents) => [...documents, result]);
    };
    eventManager.subscribe("section-added", handler);
    return () => eventManager.unsubscribe("section-added", handler);
  }, []);

  useEffect(() => {
    if (fetcher.data) {
      setDocuments(fetcher.data.documents);
    }
  }, [fetcher.data]);

  const options = useMemo(() => {
    const nestedOptions = (
      d: DocumentSectionsQuery["documents"][0],
      docs: DocumentSectionsQuery["documents"],
      indent = 1
    ) => {
      const children = _.sortBy(
        docs.filter((dd) => dd.documentId === d.id && !dd.pile),
        "author"
      );
      let options: ComboBoxOption[] = [];
      children.forEach((c) => {
        if (!skip || skip !== c.id) {
          options.push({
            value: c.id,
            name: c.author || "",
            label: c.author || "",
            indentLevel: indent,
            extra: { parentId: d.id }
          });
          options = options.concat(nestedOptions(c, docs, indent + 1));
        }
      });
      return options;
    };

    let options: ComboBoxOption[] = [];
    _.sortBy(
      documents.filter((d) => !d.pile && !d.documentId),
      "section",
      "author"
    ).forEach((d) => {
      if (!skip || skip !== d.id) {
        options.push({
          value: d.id,
          name: d.author || "No Description",
          section: d.section || "",
          label: `${d.section}: ${d.author || "No Description"}`
        });
        if (!excludeFolders) {
          options = options.concat(nestedOptions(d, documents));
        }
      }
    });
    return options;
  }, [documents, excludeFolders, skip]);

  const filterOptions = useCallback(
    (options: ComboBoxOption[], query: string) => {
      const search = query.toLowerCase();

      const result = options.filter(
        (o) => o.label.toLowerCase().indexOf(search) > -1
      );

      const withAncestors = (option: ComboBoxOption): ComboBoxOption[] => {
        if (!option.extra) {
          return [option];
        }
        const ancestors = options.filter(
          (o) => o.value === (option.extra as { parentId: string }).parentId
        );
        if (ancestors.length) {
          return [...ancestors.flatMap((o) => withAncestors(o)), option];
        }
        return [option];
      };
      const ids = result.flatMap((o) => withAncestors(o)).map((o) => o.value);
      return options.filter((o) => ids.includes(o.value));
    },
    []
  );

  return (
    <ComboBox
      {...rest}
      options={options}
      filterOptions={filterOptions}
      renderSingleValue={renderSingleValue}
      renderOption={renderOption}
      menuWidth={400}
    />
  );
}
