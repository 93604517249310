import type {
  ComboBoxOption,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type { PhotosetsQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import Badge from "~/components/badge";
import ComboBox from "~/components/combobox";
import { useOptionalProject } from "~/contexts";
import DisplayNames from "~/utils/display-names";
import { plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export interface PhotosetPickerProps extends ComboBoxParentPropsSingle {
  keyPhotoCount?: boolean;
}

export default function InspectionPicker({
  keyPhotoCount = false,
  placeholder = "Select an Investigation...",
  ...rest
}: PhotosetPickerProps) {
  const project = useOptionalProject();
  const fetcher = useFetcherData<PhotosetsQuery>(
    project ? "/resources/photosets/picker" : undefined,
    { projectId: project!.id }
  );

  const options = useMemo(() => {
    if (!project) return [];
    const sorted = _.sortBy(fetcher.data?.photosets || [], (ps) => [
      ps.pfcs,
      ps.pfcs ? "" : ps.party || "",
      ps.inspectionDate
    ]);
    const pfcs = sorted.filter((ps) => ps.pfcs);
    const other = sorted.filter((ps) => !ps.pfcs);

    const options = [
      {
        label: "PFCS Photos",
        options: pfcs.length
          ? pfcs.map(
              (item): ComboBoxOption => ({
                value: item.id,
                rightLabel:
                  keyPhotoCount && item.keyPhotoCount ? (
                    <Badge
                      mode="primary"
                      label={plural("Key Photo", item.keyPhotoCount, true)}
                    />
                  ) : undefined,
                label: DisplayNames.photoset(item)
              })
            )
          : [{ value: "z", label: "No Photos Available...", disabled: true }]
      }
    ];

    options.push({
      label: "Other Parties' Photos",
      options: other.length
        ? other.map(
            (item): ComboBoxOption => ({
              value: item.id,
              rightLabel:
                keyPhotoCount && item.keyPhotoCount ? (
                  <Badge
                    mode="primary"
                    label={plural("Key Photo", item.keyPhotoCount, true)}
                  />
                ) : undefined,
              label: `${DisplayNames.photoset(item)}: ${item.description}`
            })
          )
        : [
            {
              value: "z",
              label: "No Photos Available...",
              disabled: true
            }
          ]
    });

    return options;
  }, [keyPhotoCount, fetcher.data, project]);

  return (
    <ComboBox
      {...rest}
      placeholder={!project ? "No Project Selected" : placeholder}
      options={options}
    />
  );
}
