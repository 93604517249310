import Link from "~/components/link";

interface Props {
  project: { number: string; name: string };
  page?: string;
}

export default function ProjectLink({ project, page }: Props) {
  return (
    <Link
      to={`/projects/${project.number}${
        page ? (page.startsWith("/") ? page : `/${page}`) : ""
      }`}
    >
      {project.number} {project.name}
    </Link>
  );
}
