import type { ComboBoxParentProps } from "~/components/combobox";
import type { TrainingCategoriesQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export default function TrainingCategoryPicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<TrainingCategoriesQuery>(
    "/resources/training/category-picker"
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.trainingCategories || []).map((tc) => ({
        value: tc.id,
        label: tc.name
      })),
    [fetcher.data]
  );

  return <ComboBox {...props} options={options} />;
}
