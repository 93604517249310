import * as dfn from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import _ from "lodash";

export interface DateOptTypes {
  timeZone?: string;
  format?:
    | "L"
    | "L LT"
    | "M/D/YY"
    | "MMMM Do"
    | "MMMM YYYY"
    | "YYYY-MM-DD"
    | "YYYY-M-D"
    | "MMM YYYY"
    | "M/D"
    | "L [at] LT"
    | "LT"
    | "ddd"
    | "MMM"
    | "MMMM"
    | "YYYYMMDD"
    | "[ - ]L"
    | "[ - ]L LT"
    | "YYYY"
    | "YYYY-MM"
    | "YYYY-'Q'Q"
    | "YYYY: 'Q'Q"
    | "HH:mm:ss"
    | "h:mm a";
}

export type ParseDateInput = string | Date | number;

export const parseDateOrNull = (date: ParseDateInput, format?: string) => {
  try {
    const result = parseDate(date, format);
    return isNaN(result.getTime()) ? null : result;
  } catch {
    return null;
  }
};

export const parseDate = (date: ParseDateInput, format?: string) => {
  if (_.isDate(date)) return date;

  if (_.isNumber(date)) {
    const num = date > 2000000000 ? date / 1000 : date;
    return dfn.fromUnixTime(num);
  }
  if (date.match(/^\d{4}-Q\d$/)) {
    return dfn.parse(date, "yyyy-'Q'Q", new Date());
  }
  if (date.match(/^\d{4}-\d{2}$/)) {
    return dfn.parse(date, "yyyy-MM", new Date());
  }
  if (date.match(/^\d{2}:\d{2}:\d{2}$/)) {
    return dfn.parse(date, "HH:mm:ss", new Date());
  }
  if (date.match(/^\d{2}:\d{2}:\d{2}\.\d{3}$/)) {
    return dfn.parse(date, "HH:mm:ss.SSS", new Date());
  }
  if (date.match(/^\d{2}:\d{2}:\d{2}\.\d{6}$/)) {
    return dfn.parse(date, "HH:mm:ss.SSSSSS", new Date());
  }
  if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return dfn.parseISO(date);
  }
  if (date.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    return dfn.parse(date, "yyyy-M-d", new Date());
  }
  if (date.match(/^\d{1,2}\/\d{1,2}$/)) {
    return dfn.parse(date, "MM/dd", new Date());
  }
  if (date.match(/^\d{1,2}\/\d{1,2}\/\d{2}$/)) {
    return dfn.parse(date, "MM/dd/yy", new Date());
  }
  if (date.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
    return dfn.parse(date, "MM/dd/yyyy", new Date());
  }
  if (date.match(/^\d{4}-\d{2}-\d{2}T.*Z$/)) {
    return dfn.parseISO(date);
  }
  if (date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
    return dfn.parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
  }
  if (date.match(/^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2}:\d{2}$/)) {
    return dfn.parse(date, "MM/dd/yyyy HH:mm:ss", new Date());
  }
  if (format) {
    return dfn.parse(date, format, new Date());
  }
  throw `Invalid date format: ${date}`;
};

export const dateOnlyFormat = (format: DateOptTypes["format"]) =>
  (
    [
      "L",
      "M/D/YY",
      "MM/DD",
      "MMMM Do",
      "MMMM YYYY",
      "YYYY-MM-DD",
      "YYYY-M-D",
      "MMM YYYY",
      "M/D",
      "ddd",
      "MMM",
      "MMMM",
      "YYYYMMDD",
      "[ - ]L",
      "YYYY",
      "YYYY-MM",
      "YYYY-'Q'Q",
      "YYYY: 'Q'Q"
    ] as DateOptTypes["format"][]
  ).includes(format);

export const formatDate = (
  date: ParseDateInput | undefined | null,
  opts: DateOptTypes = {}
) => {
  if (!date) return "";
  try {
    let _date = parseDate(date);
    const format = opts.format || "L";

    // If we're formatting a full timestamp to a date-only format, we need to
    // force the date to be calculated in the Pacific timezone. Otherwise, we
    // could run into hydration issues where the date is rendered client-side
    // in the browser's timezone and one day earlier in the server's timezone.
    // e.g. 11:00pm PST on 2020-01-01 is 1:00am CST on 2020-01-02.
    if (
      _.isString(date) &&
      date.match(/T[0-9.:]*Z$/) &&
      !opts.timeZone &&
      dateOnlyFormat(format)
    ) {
      opts.timeZone = "America/Los_Angeles";
    }

    if (opts.timeZone) {
      _date = toZonedTime(_date, opts.timeZone);
    }

    switch (format) {
      case "L":
        return dfn.format(_date, "M/d/yyyy");
      case "L LT":
        return dfn.format(_date, "M/d/yyyy p");
      case "M/D/YY":
        return dfn.format(_date, "M/d/yy");
      case "MMMM Do":
        return dfn.format(_date, "MMMM do");
      case "MMMM YYYY":
        return dfn.format(_date, "MMMM yyyy");
      case "YYYY-MM-DD":
        return dfn.format(_date, "yyyy-MM-dd");
      case "YYYY-M-D":
        return dfn.format(_date, "yyyy-M-d");
      case "MMM YYYY":
        return dfn.format(_date, "MMM yyyy");
      case "M/D":
        return dfn.format(_date, "M/d");
      case "L [at] LT":
        return dfn.format(_date, "M/d/yyyy 'at' p");
      case "LT":
        return dfn.format(_date, "p");
      case "ddd":
        return dfn.format(_date, "EEE");
      case "MMM":
        return dfn.format(_date, "MMM");
      case "MMMM":
        return dfn.format(_date, "MMMM");
      case "YYYYMMDD":
        return dfn.format(_date, "yyyyMMdd");
      case "[ - ]L":
        return dfn.format(_date, "' - 'M/d/yyyy");
      case "[ - ]L LT":
        return dfn.format(_date, "' - 'M/d/yyyy p");
      case "YYYY":
        return dfn.format(_date, "yyyy");
      case "YYYY-MM":
        return dfn.format(_date, "yyyy-MM");
      case "YYYY-'Q'Q":
        return dfn.format(_date, "yyyy-'Q'Q");
      case "YYYY: 'Q'Q":
        return dfn.format(_date, "yyyy: 'Q'Q");
      case "HH:mm:ss":
        return dfn.format(_date, "HH:mm:ss");
      case "h:mm a":
        return dfn.format(_date, "h:mm a");
      default:
        throw `Invalid date format: ${format}`;
    }
  } catch {
    return _.isString(date) ? date : "";
  }
};

export const formatDateRange = (
  first: ParseDateInput,
  last: ParseDateInput,
  opts: { short?: boolean } = {}
) => {
  const _first = parseDate(first);
  const _last = parseDate(last);

  if (_first.getFullYear() === _last.getFullYear() && opts.short) {
    return `${formatDate(_first, { format: "M/D" })}-${formatDate(_last)}`;
  }
  return `${formatDate(_first)}-${formatDate(_last)}`;
};

export const addMinutes = (date: ParseDateInput, count: number) => {
  const _date = parseDate(date);
  return dfn.addMinutes(_date, count);
};

export const addSeconds = (date: ParseDateInput, count: number) => {
  const _date = parseDate(date);
  return dfn.addSeconds(_date, count);
};

export const addDays = (date: ParseDateInput, count: number) => {
  const _date = parseDate(date);
  return dfn.addDays(_date, count);
};

export const addMonths = (date: ParseDateInput, count: number) => {
  const _date = parseDate(date);
  return dfn.addMonths(_date, count);
};

export const addYears = (date: ParseDateInput, count: number) => {
  const _date = parseDate(date);
  return dfn.addYears(_date, count);
};

export const quartersInRange = (opts: {
  start?: Date;
  end?: Date;
  future?: number;
}) => {
  let start = dfn.startOfQuarter(opts.start || new Date(2005, 0, 7));
  const end = dfn.addQuarters(
    dfn.startOfQuarter(opts.end || new Date()),
    opts.future || 0
  );
  const dates = [];
  while (dfn.isSameQuarter(start, end) || dfn.isBefore(start, end)) {
    dates.push(start);
    start = dfn.addQuarters(start, 1);
  }
  return dates;
};

export const yearsInRange = (
  opts: {
    start?: number;
    end?: number;
    future?: number;
  } = {}
) => {
  const start = opts.start || 2005;
  const end = (opts.end || new Date().getFullYear()) + (opts.future || 0);
  return _.range(start, end + 1);
};

export const dateIsAfter = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isAfter(_d1, _d2);
};

export const isSameOrBefore = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isEqual(_d1, _d2) || dfn.isBefore(_d1, _d2);
};

export const isSameOrAfter = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isEqual(_d1, _d2) || dfn.isAfter(_d1, _d2);
};

export const dayIsSameOrBefore = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isSameDay(_d1, _d2) || dfn.isBefore(_d1, _d2);
};

export const dayIsSameOrAfter = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isSameDay(_d1, _d2) || dfn.isAfter(_d1, _d2);
};

export const dayIsAfter = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return !dfn.isSameDay(_d1, _d2) && dfn.isAfter(_d1, _d2);
};

export const dayIsBefore = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return !dfn.isSameDay(_d1, _d2) && dfn.isBefore(_d1, _d2);
};

export const monthIsBefore = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return !dfn.isSameMonth(_d1, _d2) && dfn.isBefore(_d1, _d2);
};

export const monthIsAfter = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return !dfn.isSameMonth(_d1, _d2) && dfn.isAfter(_d1, _d2);
};

export const monthIsSameOrAfter = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isSameMonth(_d1, _d2) || dfn.isAfter(_d1, _d2);
};
export const monthIsSameOrBefore = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isSameMonth(_d1, _d2) || dfn.isBefore(_d1, _d2);
};

export const dayIsSame = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.isSameDay(_d1, _d2);
};

export const startOfDay = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.startOfDay(_date);
};

export const endOfDay = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.endOfDay(_date);
};
export const startOfQuarter = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.startOfQuarter(_date);
};
export const endOfQuarter = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.endOfQuarter(_date);
};
export const startOfYear = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.startOfYear(_date);
};
export const endOfYear = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.endOfYear(_date);
};
export const startOfMonth = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.startOfMonth(_date);
};
export const endOfMonth = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.endOfMonth(_date);
};
export const startOfWeek = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.startOfWeek(_date);
};
export const endOfWeek = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.endOfWeek(_date);
};

export const monthIsSame = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return _d1 && _d2 && dfn.isSameMonth(_d1, _d2);
};

export const getUnixTime = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.getUnixTime(_date);
};

export const differenceInDays = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.differenceInDays(_d1, _d2);
};

export const differenceInWeeks = (d1: ParseDateInput, d2: ParseDateInput) => {
  const _d1 = parseDate(d1);
  const _d2 = parseDate(d2);
  return dfn.differenceInWeeks(_d1, _d2);
};

export const monthsInRange = (opts: {
  start?: Date | string;
  end?: Date;
  future?: number;
}) => {
  const _start = opts.start ? parseDate(opts.start) : new Date(2005, 7, 1);
  let start = dfn.startOfMonth(_start);
  const end = addMonths(
    dfn.startOfMonth(opts.end || new Date()),
    opts.future || 0
  );
  const dates = [];
  while (isSameOrBefore(start, end)) {
    dates.push(start);
    start = addMonths(start, 1);
  }
  return dates;
};

export const weeksInRange = (
  d1: ParseDateInput,
  d2: ParseDateInput
): [weekStart: Date, weekEnd: Date][] => {
  const _d1 = startOfWeek(parseDate(d1));
  const _d2 = startOfDay(endOfWeek(parseDate(d2)));
  if (dateIsAfter(_d1, _d2)) return [];

  const results: [weekStart: Date, weekEnd: Date][] = [];
  let current = _d1;

  while (dayIsSameOrBefore(current, _d2)) {
    results.push([current, startOfDay(endOfWeek(current))]);
    current = addDays(current, 7);
  }

  return results;
};

export const isPastDue = (date?: Date | string | null, end?: Date) =>
  !!date && dayIsAfter(end || new Date(), parseDate(date));

export type DateRange = [start: Date, end: Date];

export const timeSince = (
  date: ParseDateInput,
  opts: { suffix?: string } = {}
) => {
  const suffix = Object.hasOwn(opts, "suffix") ? opts.suffix : " ago";
  const _date = parseDate(date);
  return _date && dfn.formatDistanceToNow(_date) + suffix;
};

export const startOfWorkWeek = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.startOfWeek(_date, { weekStartsOn: 1 });
};

export const getWeek = (date: ParseDateInput) => {
  const _date = parseDate(date);
  return dfn.getWeek(_date);
};

export const zoneToUtc = (date: ParseDateInput, zone: string) => {
  const _date = parseDate(date);
  return fromZonedTime(_date, zone);
};

export const utcToZone = (date: ParseDateInput, zone: string) => {
  const _date = parseDate(date);
  return toZonedTime(_date, zone);
};

export const maxDate = (dates: ParseDateInput[]) => {
  const _dates = dates.map((d) => parseDate(d));
  return dfn.max(_dates);
};

export const dateIsValid = (date: Date | null) => {
  return date && dfn.isValid(date);
};

export const lastMonth = () => addDays(startOfMonth(new Date()), -1);
