import type {
  DocumentRowFieldsFragment,
  ParentDocumentFieldsFragment
} from "~/types/api";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import Thumbnail from "~/components/documents/thumbnail";
import {
  IconFolder,
  IconAttachment,
  IconStar,
  IconExcel,
  IconWord,
  IconPowerPoint,
  IconVideo
} from "~/components/icons";
import Link from "~/components/link";
import Panel from "~/components/panel";
import DisplayNames from "~/utils/display-names";
import { getPrimaryFile, getFileType, getDocLink } from "~/utils/documents";

type Props = {
  documents: DocumentRowFieldsFragment[];
  parent?: ParentDocumentFieldsFragment;
  library?: boolean;
};

const SIZE = 250;
const PAPER_RATIO = 0.772727273;

export default function Thumbs({ documents, parent, library }: Props) {
  const [params] = useSearchParams();

  const cell = (d: DocumentRowFieldsFragment) => {
    const primary = getPrimaryFile(d);
    const type = getFileType(primary?.upload?.filename);
    return (
      <Link
        to={
          d.mode === "Folder"
            ? { search: `mode=thumbs&parentId=${d.id}` }
            : getDocLink(d)
        }
        key={d.id}
        style={{
          width: SIZE * PAPER_RATIO
        }}
      >
        <div
          className="flex items-center justify-center"
          style={{
            width: SIZE * PAPER_RATIO,
            height: SIZE
          }}
        >
          {primary?.upload?.thumbUrl ? (
            <Thumbnail maxHeight={SIZE} document={d.referenceDocument || d} />
          ) : (
            <div
              className="flex flex-col items-center justify-center space-y-4 rounded-md border border-gray-200 text-gray-400"
              style={{
                height: SIZE,
                width: SIZE * PAPER_RATIO
              }}
            >
              {d.mode === "Folder" ? (
                <span>
                  <IconFolder className="fa-3x" />
                </span>
              ) : type === "Excel" ? (
                <>
                  <div>
                    <IconExcel className="fa-3x" />
                  </div>
                  <div>Excel File</div>
                </>
              ) : type === "Word" ? (
                <>
                  <div>
                    <IconWord className="fa-3x" />
                  </div>
                  <div>Word File</div>
                </>
              ) : type === "PowerPoint" ? (
                <>
                  <div>
                    <IconPowerPoint className="fa-3x" />
                  </div>
                  <div>PowerPoint File</div>
                </>
              ) : type === "Video" ? (
                <>
                  <div>
                    <IconVideo className="fa-3x" />
                  </div>
                  <div>Video</div>
                </>
              ) : primary ? (
                <>
                  <div>
                    <IconAttachment className="fa-3x" />
                  </div>
                  <div>No Thumbnail</div>
                </>
              ) : (
                <>
                  <div>
                    <IconAttachment className="fa-3x" />
                  </div>
                  <div>No Attachment</div>
                </>
              )}
            </div>
          )}
        </div>
        <div
          className="relative mt-2 items-center px-4 text-center"
          style={{
            wordBreak: "break-word"
          }}
        >
          {d.key && (
            <>
              <IconStar />{" "}
            </>
          )}
          {DisplayNames.document(d.referenceDocument || d)}
        </div>
      </Link>
    );
  };

  const uncategorized = library
    ? documents
    : documents.filter(
        (d) =>
          d.mode !== "Header" &&
          (!d.document || params.get("parentId") === d.document.id)
      );
  const topLevel = library
    ? []
    : documents.filter((d) => d.mode === "Header" && !d.document);
  const renderPanel = (
    title: string,
    documents: DocumentRowFieldsFragment[]
  ) => (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Body>
        <div
          style={{
            display: "grid",
            gridGap: 20,
            gridTemplateColumns: `repeat(auto-fill, ${SIZE * 0.75}px)`
          }}
        >
          {documents.map(cell)}
        </div>
      </Panel.Body>
    </Panel>
  );

  const renderMissing = () =>
    renderPanel(
      parent ? parent.author! : library ? "Results" : "No Section",
      uncategorized
    );

  const getTitle = (document: DocumentRowFieldsFragment) =>
    document.mode === "Header"
      ? `${document.section} ${document.author || "Missing Section Name"}`
      : `${document.author}`;

  const renderSection = (
    section: DocumentRowFieldsFragment,
    parents: DocumentRowFieldsFragment[] = []
  ) => {
    const title = [...parents.map(getTitle), getTitle(section)].join(": ");
    return (
      <Fragment key={section.id}>
        {renderPanel(
          title,
          documents.filter((d) => d.document?.id === section.id)
        )}
      </Fragment>
    );
  };

  console.log(uncategorized.length);
  return (
    <div>
      {uncategorized.length > 0 && renderMissing()}
      {topLevel.map((d) => renderSection(d))}
    </div>
  );
}
