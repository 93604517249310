import type { CSSProperties, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import React from "react";

export interface PanelHeaderProps extends PropsWithChildren<unknown> {
  title?: React.ReactNode;
  button?: React.ReactNode;
  floatButton?: boolean;
  className?: string;
  smallText?: boolean;
  buttonClassName?: string;
  rawTitle?: ReactNode;
  subtitle?: React.ReactNode;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
}

export default function Header({
  title,
  children,
  className,
  subtitle,
  rawTitle,
  button,
  floatButton,
  style = {},
  headerStyle = {},
  buttonClassName = "",
  smallText
}: PanelHeaderProps) {
  return (
    <div
      className={clsx(
        "panel-heading show-children-when-hovering relative",
        className
      )}
      style={style}
    >
      <div className="flex w-full flex-wrap items-center justify-between space-x-2">
        <div className="flex-1">
          {rawTitle ? (
            rawTitle
          ) : smallText ? (
            <strong>{title}</strong>
          ) : (
            <h1 className="panel-title" style={headerStyle}>
              {title}
            </h1>
          )}
          {subtitle && <div style={{ marginTop: 9 }}>{subtitle}</div>}
        </div>
        {children}
        {button && (
          <div
            style={
              floatButton
                ? { position: "absolute", right: 5, top: 5 }
                : buttonClassName
                  ? undefined
                  : { marginTop: -10, marginBottom: -10 }
            }
            className={buttonClassName}
          >
            {button}
          </div>
        )}
      </div>
    </div>
  );
}
