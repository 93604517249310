import type { CustomFieldType } from "~/components/edit-custom-fields";
import clsx from "clsx";
import Decimal from "decimal.js";
import numeral from "numeral";
import _pluralize from "pluralize";
import Badge from "~/components/badge";
import { IconCheck, IconCircle } from "~/components/icons";
import Linkify from "~/components/linkify";
import { formatDate } from "~/utils/dates";
import { truncate } from "~/utils/text";

interface MoneyOptTypes {
  zeroes?: false;
  decorator?: true;
  round?: boolean;
}

/**
 * Formats a number to money format. Defaults to 123,457.00 without a decorator.
 * @param value Number to format.
 * @param {Object} opts Options
 * @param {boolean} opts.zeroes Set to false to hide zero values with "-"
 * @param {boolean} opts.decorator Set to true to show a dollar sign
 */
export type FormatNumberValue = string | number | null | Decimal | undefined;
export const formatMoney = (
  value: FormatNumberValue,
  opts: MoneyOptTypes = {}
): string => {
  if (value === undefined || value === null) return "";
  const num =
    typeof value === "number"
      ? value
      : Decimal.isDecimal(value)
        ? value.toString()
        : parseFloat(value);
  if (num === 0 && opts.zeroes === false) {
    return "-";
  }
  const numberFormat = opts.round ? "0,0" : "0,0.00";
  return formatNumber(value, {
    format: opts.decorator ? `($${numberFormat})` : `(${numberFormat})`,
    zeroes: opts.zeroes !== false
  });
};

export type FormatNumberOpts = {
  zeroes?: boolean;
  format?: string;
};
export const formatNumber = (
  value: string | number | null | Decimal | undefined,
  opts: FormatNumberOpts = {}
): string => {
  if (value === undefined || value === null) return "";
  const v = typeof value === "string" ? parseFloat(value) : value;
  if ((!v || v === 0 || (Decimal.isDecimal(v) && v.eq(0))) && !opts.zeroes) {
    return "-";
  }
  return numeral(Decimal.isDecimal(v) ? v.toString() : v).format(
    opts.format || "0,0.0"
  );
};

export const formatBytes = (value: string | number | null | undefined) => {
  if (value === undefined || value === null) return "";
  const v = typeof value === "string" ? parseFloat(value) : value;
  return numeral(v).format("0.0 ib").replace("iB", "B");
};

export const plural = (
  word: string,
  count: number | string,
  include?: boolean,
  opts: FormatNumberOpts = {}
) => {
  const result = _pluralize(word, Number(count));
  return include
    ? `${formatNumber(count, {
        format: opts.format ?? "0,0",
        zeroes: opts.zeroes ?? true
      })} ${result}`
    : result;
};

type FormattedCustomFieldProps<T> = {
  item: T;
  field: string;
  fieldType: CustomFieldType;
  fieldName?: keyof T;
  onClick?: (item: T, field: string) => void;
  truncateMultiline?: boolean;
};
export const FormattedCustomField = <T,>({
  item,
  field,
  fieldType,
  fieldName,
  onClick,
  truncateMultiline
}: FormattedCustomFieldProps<T>) => {
  const _item = item[(fieldName || "customFields") as keyof T];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const object = typeof _item === "string" ? (JSON.parse(_item) as any) : _item;
  let value = object[field];
  if (["Number", "Accounting"].includes(fieldType)) {
    value = (value || "").replace(/[^\d.-]/g, "");
    return (
      <td
        className={fieldType === "Number" ? "text-center" : "text-right"}
        key={field}
      >
        {value ? (
          /\d/.test(value) && /^[$.\d,-]+$/.test(value) ? (
            fieldType === "Number" ? (
              formatNumber(value, { format: "0,0" })
            ) : (
              formatMoney(value)
            )
          ) : (
            <>
              {value}
              <br />
              <Badge mode="warning" label="Invalid Number" />
            </>
          )
        ) : null}
      </td>
    );
  } else if (fieldType === "Checkmark") {
    return (
      <td className="text-center" key={field}>
        {(value === true || value === "1") && <IconCheck />}
      </td>
    );
  } else if (fieldType === "Checklist") {
    return (
      <td
        className={clsx("text-center", onClick && "cursor-pointer")}
        key={field}
        onClick={() => onClick?.(item, field)}
      >
        {value === true || value === "1" ? (
          <IconCheck />
        ) : (
          <IconCircle className="text-gray-400" />
        )}
      </td>
    );
  } else if (fieldType === "Date") {
    return (
      <td className="text-center" key={field}>
        {value ? formatDate(value) : null}
      </td>
    );
  } else if (fieldType === "Multiline Text" && truncateMultiline) {
    return (
      <td key={field} className="min-w-[200px]">
        <Linkify>{truncate(value || "", 75)}</Linkify>
      </td>
    );
  }
  return (
    <td key={field}>
      <Linkify>{value}</Linkify>
    </td>
  );
};

export const totalCustomField = <T,>(
  items: T[],
  field: string,
  fieldType: CustomFieldType,
  fieldName?: keyof T
) => {
  if (["Checkmark", "Checklist"].includes(fieldType)) {
    const count = items.filter((i) => {
      const _item = i[(fieldName || "customFields") as keyof T];
      const object =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof _item === "string" ? (JSON.parse(_item) as any) : _item;
      return [true, "1"].includes(object[field]);
    }).length;
    return (
      <td className="text-center" key={field}>
        {count}/{items.length}
        {fieldType === "Checklist" &&
          ` (${formatNumber(count / items.length, {
            format: "0%",
            zeroes: true
          })})`}
      </td>
    );
  } else if (["Accounting", "Number"].includes(fieldType)) {
    let bad = false;
    const sum = items.reduce((acc, i) => {
      const _item = i[(fieldName || "customFields") as keyof T];
      const object =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof _item === "string" ? (JSON.parse(_item) as any) : _item;
      let data = object[field];
      if (!data) {
        return acc;
      }
      if (!/^[$.\d,-]+$/.test(data.trim())) {
        bad = true;
        return acc;
      }
      data = data.trim().replace(/[^\d.-]/g, "");
      if (!data) {
        return acc;
      }
      return acc + parseFloat(data);
    }, 0);
    return bad ? (
      <td className="text-center" key={field}>
        Invalid Values
      </td>
    ) : fieldType === "Accounting" ? (
      <td className="text-right" key={field}>
        {formatMoney(sum, { decorator: true })}
      </td>
    ) : (
      <td className="text-center" key={field}>
        {formatNumber(sum, { format: "0,0" })}
      </td>
    );
  }
  const count = items.filter((i) => {
    const _item = i[(fieldName || "customFields") as keyof T];
    const object =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof _item === "string" ? (JSON.parse(_item) as any) : _item;
    return object[field];
  }).length;

  return (
    <td
      key={field}
      className={fieldType === "Date" ? "text-center" : undefined}
    >
      {count}/{items.length}
    </td>
  );
};

export const normalizedNumber = (text?: string | null): string | null => {
  if (!text) {
    return null;
  }
  const result =
    text
      .toLowerCase()
      .replace(/\s+/g, ".")
      .replace(/([^\d.])(\d)/g, "$1.$2")
      .replace(/(\d)([^\d.])/g, "$1.$2")
      .split(/(\d+)/)
      .map((part) =>
        /^\d+$/.test(part)
          ? formatNumber(parseInt(part), { format: "0000000000" })
          : part
      )
      .join("") || null;
  return result;
};
