import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { DocumentPickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { IconExternalLink } from "~/components/icons";
import Link from "~/components/link";
import { useFetcherData } from "~/utils/remix";

export type DocumentPickerProps = ComboBoxParentPropsSingle & {
  project?: { number: string };
  projectId?: string | null;
  contentTopicId?: string | null;
  socialMedia?: boolean;
  viewLink?: boolean;
};

export default function DocumentPicker({
  project,
  projectId,
  contentTopicId,
  socialMedia,
  onChange,
  viewLink,
  ...rest
}: DocumentPickerProps) {
  const fetcher = useFetcherData<DocumentPickerQuery>(
    project || projectId || contentTopicId
      ? "/resources/documents/picker"
      : undefined,
    {
      projectId,
      projectNumber: project?.number,
      contentTopicId,
      socialMedia
    }
  );

  const documents = useMemo(
    () => fetcher.data?.documents || [],
    [fetcher.data]
  );

  const options = useMemo(() => {
    return _.sortBy(documents, "displayName").map((d) => ({
      value: d.id,
      label: d.displayName,
      full: d
    }));
  }, [documents]);

  const handleChange = (name: string, value: string) => {
    const full = value && options.find((o) => o.value === value)?.full;
    onChange?.(name, value, full);
  };

  const selected = documents.find((d) => d.id === rest.value);

  return (
    <div className="inline-flex items-center">
      <div className="flex-1">
        <ComboBox {...rest} options={options} onChange={handleChange} />
      </div>
      {viewLink && selected && (
        <div>
          <Link
            to={
              selected.project
                ? `/projects/${selected.project.number}/files/${selected.id}`
                : `/content/${selected.contentTopic!.id}/files/${selected.id}`
            }
            target="_blank"
          >
            <IconExternalLink /> View
          </Link>
        </div>
      )}
    </div>
  );
}
