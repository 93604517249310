import { useEffect, useState } from "react";

interface Props {
  url: string;
}

export default function RemoteText({ url }: Props) {
  const [value, setValue] = useState("");

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then(setValue);
  }, [url]);

  return (
    <pre className="whitespace-pre-line break-normal border-none bg-white">
      {value}
    </pre>
  );
}
