import type { ComboBoxParentProps } from "~/components/combobox";
import type { EmployeePickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { STAFF_SECTIONS } from "~/constants";
import { useFetcherData } from "~/utils/remix";

export type EmployeePickerProps = ComboBoxParentProps & {
  searchPriorStaff?: boolean;
};

export default function EmployeePicker(props: EmployeePickerProps) {
  const fetcher = useFetcherData<EmployeePickerQuery>(
    "/resources/employees/picker"
  );

  const options = useMemo(() => {
    const sorted = _.sortBy(
      (fetcher.data?.employees || []).filter(
        (e) => e.employmentStatus === "Active" || e.id === props.value
      ),
      "future",
      "displayAs"
    );
    const grouped = _.groupBy(sorted, "department");
    return STAFF_SECTIONS.map((section) => ({
      label: section,
      options: (grouped[section] || []).map((e) => ({
        value: e.id,
        label: e.displayAs
      }))
    }));
  }, [fetcher.data, props.value]);

  return <ComboBox options={options} {...props} />;
}
