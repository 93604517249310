import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { FeaturePickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { IconExternalLink } from "~/components/icons";
import Link from "~/components/link";
import { useFetcherData } from "~/utils/remix";

export type FeaturePickerProps = ComboBoxParentPropsSingle & {
  viewLink?: boolean;
  excludeId?: string;
};

export default function FeaturePicker({
  viewLink,
  excludeId,
  ...props
}: FeaturePickerProps) {
  const fetcher = useFetcherData<FeaturePickerQuery>(
    "/resources/features/picker"
  );

  const options = useMemo(
    () =>
      _.sortBy(
        (fetcher.data?.features || []).filter((f) => f.id !== excludeId),
        ["category", "title"]
      ).map((f) => ({
        value: f.id,
        label: `${f.category} ${f.title}`
      })),
    [fetcher.data, excludeId]
  );

  return (
    <div className="inline-flex items-center">
      <div className="flex-1">
        <ComboBox
          options={options}
          placeholder="Select a Parent Feature..."
          {...props}
        />
      </div>
      {viewLink && props.value && (
        <div>
          <Link to={`/features/${props.value}`} target="_blank">
            <IconExternalLink /> View
          </Link>
        </div>
      )}
    </div>
  );
}
