import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle,
  GroupedComboBoxOption
} from "~/components/combobox";
import type { ElementsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import React, { useMemo } from "react";
import Badge from "~/components/badge";
import ComboBox from "~/components/combobox";
import { useOptionalProject } from "~/contexts";
import DisplayNames from "~/utils/display-names";
import { plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

const labelForGroup = (group: string) => {
  switch (group) {
    case "A":
      return "A - Substructure";
    case "B":
      return "B - Superstructure";
    case "C":
      return "C - Interiors";
    case "D":
      return "D - Services";
    case "E":
      return "E - Equipment and Furnishings";
    case "F":
      return "F - Special Construction and Demolition";
    case "G":
      return "G - Building Sitework";
    case "H":
      return "H - Other";
    default:
      return "";
  }
};

export type ElementPickerPropsSingle = ComboBoxParentPropsSingle & {
  disableNoPhotos?: boolean;
  template?: boolean;
  untagged?: boolean;
  keyPhotoCount?: boolean;
};
export type ElementPickerPropsMulti = ComboBoxParentPropsMultiple & {
  disableNoPhotos?: boolean;
  template?: boolean;
  untagged?: boolean;
  keyPhotoCount?: boolean;
};

export default function ElementPicker({
  template,
  untagged,
  disableNoPhotos,
  keyPhotoCount,
  ...rest
}: ElementPickerPropsSingle | ElementPickerPropsMulti) {
  const project = useOptionalProject();
  const loader = useLoaderData<{ elementPicker?: ElementsQuery } | null>();
  const fetcher = useFetcherData<ElementsQuery>(
    loader?.elementPicker ? undefined : "/resources/elements/picker",
    {
      projectId: project?.id,
      template
    }
  );

  const options = useMemo(() => {
    const groups = _.groupBy(
      _.sortBy(
        loader?.elementPicker?.elements || fetcher.data?.elements,
        "number"
      ),
      (e) => e.number[0]
    );
    const options: GroupedComboBoxOption[] = [];

    Object.keys(groups).forEach((group) => {
      options.push({
        label: labelForGroup(group),
        options: groups[group].map((item) => {
          const count = item.pfcsPhotoCount + item.otherPhotoCount;
          const disabled = disableNoPhotos && count === 0;
          return {
            value: item.id,
            rightLabel:
              keyPhotoCount &&
              item.pfcsKeyPhotoCount + item.otherKeyPhotoCount ? (
                <Badge
                  mode="primary"
                  label={plural(
                    "Key Photo",
                    item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
                    true
                  )}
                />
              ) : undefined,
            label: `${DisplayNames.element(item)}${
              disabled && disableNoPhotos ? " (No Photos)" : ""
            }`,
            disabled: disabled
          };
        })
      });
    });
    if (untagged) {
      options.push({
        label: "Without Elements",
        options: [{ value: "untagged", label: "*** Without Elements ***" }]
      });
    }
    return options;
  }, [
    disableNoPhotos,
    loader?.elementPicker?.elements,
    fetcher.data?.elements,
    keyPhotoCount,
    untagged
  ]);

  return (
    <ComboBox placeholder="Select an Element..." options={options} {...rest} />
  );
}
