import type { ComboBoxParentProps } from "~/components/combobox";
import type { CommunitiesQuery, EditCommunityQuery } from "~/types/api";
import _ from "lodash";
import { useMemo, useState, useCallback } from "react";
import Badge from "~/components/badge";
import Button from "~/components/button";
import ComboBox from "~/components/combobox";
import CommunityForm from "~/components/communities/form";
import { useFetcherData } from "~/utils/remix";

export default function CommunityPicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<CommunitiesQuery>(
    "/resources/communities/picker"
  );

  const [adding, setAdding] = useState(false);

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.communities || [], "name").map((c) => ({
        label: c.name,
        rightLabel: <Badge label={`${c.city}, ${c.state}`} />,
        value: c.id
      })),
    [fetcher.data]
  );

  const handleAdded = useCallback(
    (value: EditCommunityQuery["community"]) => {
      if (value) {
        if (props.multiple) {
          props.onChange?.(props.name, [value.id]);
        } else {
          props.onChange?.(props.name, value.id);
        }
      }
      setAdding(false);
    },
    [props]
  );

  return (
    <div className="flex items-center gap-2">
      <div className="flex-1">
        <ComboBox
          options={options}
          {...props}
          placeholder={props.placeholder || "Select a Community..."}
        />
      </div>
      <Button mode="success" onClick={() => setAdding(true)}>
        Add New Community
      </Button>
      {adding && <CommunityForm onClose={handleAdded} />}
    </div>
  );
}
