import type { ReactNode } from "react";
import type {
  AttachedFileFieldsFragment,
  DocumentFieldsFragment,
  SourceFileFieldsFragment
} from "~/types/api";
import { useFetcher } from "@remix-run/react";
import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import Button from "~/components/button";
import ButtonGroup from "~/components/button-group";
import ButtonLink from "~/components/button-link";
import RemoteText from "~/components/documents/remote-text";
import { IconCheck, IconFullScreen, IconX } from "~/components/icons";
import Panel from "~/components/panel";
import ShortLinkGenerator from "~/components/short-link-generator";
import { ViewerContext } from "~/contexts";
import { useAllowed } from "~/utils/auth";
import { getPrimaryFile } from "~/utils/documents";

interface Props {
  file?: SourceFileFieldsFragment | DocumentFieldsFragment;
  noMargin?: boolean;
  noFileWarning?: ReactNode;
  selectedFile?: File | null;
  shortLink?: boolean;
  wrapped?: boolean;
  onClose?: () => void;
  buttons?: ReactNode;
}

export default function Viewer({
  file,
  noMargin,
  noFileWarning = "Enter all available information to the left",
  selectedFile,
  shortLink = true,
  onClose,
  wrapped,
  buttons
}: Props) {
  const viewer = useContext(ViewerContext);
  const [showMetadata, setShowMetadata] = useState(false);
  const currentAttachedFile = viewer ? viewer.currentAttachedFile : null;
  const [height, setHeight] = useState<number>();
  const viewerRef = useRef<HTMLDivElement>(null);
  const objectURLRef = useRef<string | null>(null);
  const fileRef = useRef<File | null>(null);
  const fetcher = useFetcher<unknown>();
  const isAdmin = useAllowed("Admin/Developer");

  const attachedFile = useMemo(
    () =>
      file
        ? currentAttachedFile === "sourceFile"
          ? getPrimaryFile(file, { forceSourceFile: true })
          : currentAttachedFile
            ? file.attachedFiles.find((af) => af.id === currentAttachedFile)
            : getPrimaryFile(file)
        : null,
    [currentAttachedFile, file]
  );

  const isMovie = useMemo(() => {
    return attachedFile?.upload?.filename.match(/\.(mp4|m4v|mpg)$/i);
  }, [attachedFile]);

  const isPDF = useMemo(() => {
    const af = attachedFile;

    return (
      (af && af.upload?.filename.match(/\.pdf$/i)) ||
      (selectedFile && selectedFile.type === "application/pdf")
    );
  }, [attachedFile, selectedFile]);

  useEffect(() => {
    const resizePDF = () => {
      const v = viewerRef.current;
      // Sometimes offsetParent can be null?
      if (v && v.offsetParent) {
        if ((v.offsetParent as HTMLElement).offsetLeft < 100) {
          setHeight((v.offsetWidth / 8.5) * 11);
        } else {
          let offset =
            (v.offsetParent as HTMLElement).offsetTop +
            v.offsetTop -
            window.scrollY;
          if (offset < 0) {
            offset = 0;
          }
          setHeight(
            window.innerHeight - (wrapped ? 38 : noMargin ? 36 : 100) - offset
          );
        }
      }
    };

    if (isPDF || isMovie) {
      resizePDF();
      window.onresize = resizePDF;
      window.onscroll = resizePDF;
    }

    return () => {
      window.onresize = null;
      window.onscroll = null;
      if (objectURLRef.current) {
        window.URL.revokeObjectURL(objectURLRef.current);
        objectURLRef.current = null;
      }
    };
  }, [isMovie, isPDF, noMargin, wrapped]);

  const makePrimary = (af: AttachedFileFieldsFragment) =>
    fetcher.submit(
      { id: af.id, primaryFile: "1" },
      { action: "/resources/attached-files/save", method: "post" }
    );

  const makeSourceFilePrimary = (file: DocumentFieldsFragment) => {
    const primary = file.attachedFiles.find((af) => af.primaryFile);
    if (primary) {
      fetcher.submit(
        { id: primary.id, primaryFile: "0" },
        { action: "/resources/attached-files/save", method: "post" }
      );
    }
  };
  const renderPanel = (opts: {
    header: string;
    zeroPadding?: boolean;
    body?: React.ReactNode;
  }) => {
    const af = attachedFile;
    return (
      <Panel
        mode="info"
        style={{ position: "sticky", top: 10, marginBottom: 0 }}
      >
        <Panel.Header
          title={opts.header}
          button={
            !!af?.upload && (
              <span className="space-x-2">
                {isAdmin &&
                  file?.__typename === "SourceFile" &&
                  file.metadata && (
                    <Button
                      small
                      onClick={() => setShowMetadata(!showMetadata)}
                    >
                      {showMetadata ? "Hide" : "Show"} Metadata
                    </Button>
                  )}
                {buttons}
                <ButtonGroup>
                  {shortLink && (
                    <ShortLinkGenerator
                      attachedFile={af}
                      href={af.upload.url}
                      key={af.upload.id}
                    />
                  )}
                  {!af.primaryFile && (
                    <Button onClick={() => makePrimary(af)} small>
                      <IconCheck /> Make Primary
                    </Button>
                  )}
                  {file?.sourceFile &&
                    file?.__typename === "Document" &&
                    viewer?.currentAttachedFile === "sourceFile" &&
                    file.attachedFiles.some((af) => af.primaryFile) && (
                      <Button onClick={() => makeSourceFilePrimary(file)} small>
                        <IconCheck /> Make Primary
                      </Button>
                    )}
                  <ButtonLink external to={af.upload.url} small>
                    <IconFullScreen /> Full Screen
                  </ButtonLink>
                  {onClose && (
                    <Button onClick={onClose} small>
                      <IconX /> Close
                    </Button>
                  )}
                </ButtonGroup>
              </span>
            )
          }
        />
        {isAdmin &&
        file?.__typename === "SourceFile" &&
        file.metadata &&
        showMetadata ? (
          <Panel.Body>
            <pre className="whitespace-pre-wrap break-all">
              {JSON.stringify(JSON.parse(file.metadata), null, 2)}
            </pre>
          </Panel.Body>
        ) : (
          <Panel.Body
            style={opts.zeroPadding ? { height, padding: 0 } : { height }}
            innerRef={viewerRef}
          >
            {opts.body}
          </Panel.Body>
        )}
      </Panel>
    );
  };

  const renderSelectedFileViewer = (file: File) => {
    if (fileRef.current !== file || !objectURLRef.current) {
      fileRef.current = file;
      objectURLRef.current = window.URL.createObjectURL(file);
    }

    return renderPanel({
      header: "PDF",
      zeroPadding: true,
      body: (
        <iframe
          style={{
            width: "100%",
            height: "100%",
            border: 0,
            display: "block"
          }}
          src={objectURLRef.current!}
        />
      )
    });
  };

  const renderNewFileViewer = () => {
    if (selectedFile && selectedFile.name.match(/\.pdf$/i)) {
      return renderSelectedFileViewer(selectedFile);
    }
    return renderPanel({
      header: "Notes",
      body: noFileWarning
    });
  };

  const af = attachedFile;
  if (!file) {
    return renderNewFileViewer();
  } else if (af?.upload) {
    const { filename } = af.upload;
    if (isPDF) {
      return renderPanel({
        header: "PDF",
        zeroPadding: true,
        body: (
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: 0,
              display: "block"
            }}
            src={`${af.upload.url}${
              file.__typename === "Document" && file.pageReference
                ? `#page=${file.pageReference}`
                : ""
            }`}
          />
        )
      });
    } else if (filename.match(/\.(jpe?g|png|gif)$/i)) {
      return renderPanel({
        header: "Image",
        zeroPadding: true,
        body: <img src={af.upload.url} className="img img-responsive" />
      });
    } else if (filename.match(/\.(mp4|m4v|mov|mpg|3g2)$/i)) {
      return renderPanel({
        header: "Video",
        zeroPadding: true,
        body: (
          <video
            style={{ width: "100%", display: "block", height: "100%" }}
            src={af.upload.url}
            controls
          />
        )
      });
    } else if (filename.match(/\.mp3$/i)) {
      return renderPanel({
        header: "Audio",
        // zeroPadding: true,
        body: (
          <audio
            style={{ width: "100%", display: "block" }}
            src={af.upload.url}
            controls
          />
        )
      });
    } else if (filename.match(/\.(txt|srt)$/i)) {
      return renderPanel({
        header: "Text",
        zeroPadding: true,
        body: <RemoteText url={af.upload.url} />
      });
    }
    return renderPanel({
      header: "Other Attachment",
      body: (
        <div>
          <a href={af.upload.url}>
            <strong>{filename}</strong>
          </a>
          <hr className="tight" />
          This file type cannot be displayed in the browser. Click above to
          download it.
        </div>
      )
    });
  } else if (file.__typename === "Document" && file.url) {
    if (file.url.match(/\.(mp4|m4v)$/i)) {
      return renderPanel({
        header: "Video",
        zeroPadding: true,
        body: (
          <video
            style={{ width: "100%", display: "block" }}
            src={file.url}
            controls
          />
        )
      });
    } else if (file.url.match(/youtube\.com/i)) {
      return renderPanel({
        header: "YouTube",
        zeroPadding: true,
        body: (
          <div className="embed-container">
            <iframe
              frameBorder="0"
              src={file.url
                .replace(/http:\/\//, "https://")
                .replace("youtube.com/watch?v=", "youtube.com/embed/")}
            />
          </div>
        )
      });
    } else if (file.url.match(/\.mp3$/i)) {
      return renderPanel({
        header: "Audio",
        zeroPadding: true,
        body: (
          <audio
            style={{ width: "100%", display: "block" }}
            src={file.url}
            controls
          />
        )
      });
    }
    const absoluteUrl = file.url.match(/[A-Za-z][A-Za-z0-9]*:.+/)
      ? file.url
      : `http://${file.url}`;
    return renderPanel({
      header: "Other URL",
      body: (
        <div>
          <a href={absoluteUrl} target="_blank">
            <strong>{absoluteUrl}</strong>
          </a>
          <hr className="tight" />
          This file is a reference to a website. Please click above to view the
          link.
        </div>
      )
    });
  } else if (file.__typename === "Document" && file.source) {
    return renderPanel({
      header: "File Server Reference",
      body: (
        <div>
          <p>
            This file can be found in the following folder on the file server:
          </p>
          <p className="font-bold">{file.source}</p>
        </div>
      )
    });
  }
  return renderPanel({
    header: "No Attachment",
    body: <em>There is no attached file or URL to display</em>
  });
}
