import type { PropsWithChildren } from "react";
import ButtonCloseModal from "~/components/button-close-modal";

export interface ModalFooterProps extends PropsWithChildren<unknown> {
  closeButton?: boolean;
}

export default function Footer({ children, closeButton }: ModalFooterProps) {
  return (
    <div className="modal-footer">
      {closeButton && <ButtonCloseModal />}
      {children}
    </div>
  );
}
