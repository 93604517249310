import type { PropsWithChildren } from "react";
import { Description } from "@headlessui/react";
import clsx from "clsx";

export interface ModalBodyProps extends PropsWithChildren<unknown> {
  className?: string;
}

export default function Body({ children, className = "" }: ModalBodyProps) {
  return (
    <Description as="div" className={clsx("modal-body", className)}>
      {children}
    </Description>
  );
}
