import type { PropsWithChildren } from "react";
import type { To } from "react-router-dom";
import type { ButtonModes } from "~/components/button";
import React, { useState, useEffect, useContext } from "react";
import invariant from "tiny-invariant";
import Button from "~/components/button";
import ButtonCloseModal from "~/components/button-close-modal";
import ButtonLink from "~/components/button-link";
import { IconLoading } from "~/components/icons";
import FormStatus from "~/components/remix-form/form-status";
import SaveWarning from "~/components/save-warning";
import { RemixFormContext } from "~/contexts";

interface ButtonProps extends PropsWithChildren<unknown> {
  redirectTo?: To | (() => void);
  modal?: boolean;
  disabled?: boolean;
  closeOnly?: boolean;
  closeLabel?: string;
  footer?: boolean;
  halfFooter?: boolean;
  panel?: boolean;
  primaryLabel?: React.ReactNode;
  primaryMode?: ButtonModes;
  savingLabel?: React.ReactNode;
  dangerLabel?: React.ReactNode;
  name?: string;
  value?: string;
  onDanger?: () => void;
  justSavedLabel?: React.ReactNode;
  errorLabel?: React.ReactNode;
  rightContent?: React.ReactNode;
  farRightContent?: React.ReactNode;
  noSaveWarning?: boolean;
  small?: boolean;
  large?: boolean;
  block?: boolean;
  warnings?: React.ReactNode;
  wrap?: boolean;
  showSuccess?: boolean;
  formStatusKey?: string;
}

export function Buttons({
  justSavedLabel = "Saved!",
  errorLabel = "Error!",
  savingLabel = "Saving...",
  primaryLabel = "Save",
  primaryMode = "primary",
  name,
  value,
  showSuccess = true,
  small,
  large,
  block,
  closeLabel,
  modal,
  dangerLabel,
  onDanger,
  footer,
  halfFooter,
  panel,
  rightContent,
  farRightContent,
  children,
  closeOnly,
  redirectTo,
  disabled,
  noSaveWarning,
  warnings,
  formStatusKey,
  wrap = true
}: ButtonProps) {
  const form = useContext(RemixFormContext);
  invariant(form, "Buttons must be used inside a RemixFormContext");
  const [buttonState, setButtonState] = useState("idle");

  useEffect(() => {
    let timeout: number;
    if (showSuccess) {
      if (form?.state === "done") {
        setButtonState((prev) => (prev === "uploading" ? "saving" : "saved"));
        timeout = window.setTimeout(() => setButtonState("idle"), 3000);
      } else if (form?.state === "errors") {
        setButtonState("errors");
        timeout = window.setTimeout(() => setButtonState("idle"), 3000);
      } else {
        setButtonState(
          form.state === "loading"
            ? "saving"
            : form.state === "uploading"
              ? "uploading"
              : "idle"
        );
      }
    }
    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [form?.state, showSuccess]);

  const save =
    buttonState === "saved" ? (
      <Button
        name={name}
        value={value}
        small={small}
        large={large}
        block={block}
        type="submit"
        mode="success"
        disabled
      >
        {justSavedLabel}
      </Button>
    ) : buttonState === "errors" ? (
      <Button
        name={name}
        value={value}
        small={small}
        large={large}
        block={block}
        type="submit"
        mode="danger"
      >
        {errorLabel}
      </Button>
    ) : ["saving", "uploading"].includes(buttonState) ? (
      <Button
        name={name}
        value={value}
        small={small}
        large={large}
        block={block}
        type="submit"
        mode={primaryMode}
        disabled
      >
        <IconLoading />{" "}
        {buttonState === "uploading" ? "Uploading..." : savingLabel}
      </Button>
    ) : (
      <Button
        name={name}
        value={value}
        small={small}
        large={large}
        block={block}
        type="submit"
        mode={primaryMode}
        disabled={disabled}
      >
        {primaryLabel}
      </Button>
    );

  return modal ? (
    <>
      <div className="pull-left space-x-4">
        {dangerLabel && (
          <Button
            small={small}
            large={large}
            block={block}
            onClick={onDanger}
            mode="danger"
          >
            {dangerLabel}
          </Button>
        )}
        <span>{children}</span>
      </div>
      {rightContent}
      <ButtonCloseModal
        small={small}
        large={large}
        block={block}
        mode="default"
      >
        {closeLabel ? closeLabel : closeOnly ? "Close" : "Cancel"}
      </ButtonCloseModal>
      {!closeOnly && save}
      {farRightContent}
    </>
  ) : (
    <>
      {footer && <div className="min-h-[100px]" />}
      <div
        className={
          !wrap
            ? undefined
            : `${
                footer
                  ? halfFooter
                    ? "form-actions-half-footer"
                    : "form-actions-footer"
                  : panel
                    ? ""
                    : "form-actions-bordered"
              }`
        }
      >
        {formStatusKey && !panel && <FormStatus room={formStatusKey} />}
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div>
            {save}
            {children}
            {redirectTo && (
              <ButtonLink
                small={small}
                large={large}
                block={block}
                to={redirectTo}
                mode="default"
              >
                Cancel
              </ButtonLink>
            )}
          </div>
          <div className="flex-1 space-y-4">
            {!noSaveWarning && <SaveWarning />}
            {warnings}
          </div>
          <div className="flex items-center justify-between space-x-2">
            {dangerLabel && (
              <Button
                small={small}
                large={large}
                block={block}
                mode="danger"
                onClick={onDanger}
              >
                {dangerLabel}
              </Button>
            )}
            {rightContent && <div>{rightContent}</div>}
            {farRightContent && <div>{farRightContent}</div>}
          </div>
        </div>
      </div>
    </>
  );
}
