import type { SaveUploadMutation, UploadFieldsFragment } from "~/types/api";
import type { NestedFile } from "~/utils/file-reader";
import clsx from "clsx";
import { produce } from "immer";
import { useContext, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import invariant from "tiny-invariant";
import { v1 } from "uuid";
import { IconReply, IconTrash, IconUpload } from "~/components/icons";
import Link from "~/components/link";
import { S3_CHUNK_SIZE } from "~/components/piles/uploader";
import ProgressBar from "~/components/progress-bar";
import { RemixFormContext } from "~/contexts";
import {
  convertDataTransferToFiles,
  filterDataTransferFiles
} from "~/utils/file-reader";
import { uploadMultipartWithProgress, uploadWithProgress } from "~/utils/http";
import { getFileHash } from "~/utils/md5";
import { lookup } from "~/utils/mime-types";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: string;
  }
}

export type FileUploaderProps = {
  width?: string;
  onChange?: (
    name: string,
    value: FileList | File | NestedFile[] | null
  ) => void; // Not actually used
  accept?: string;
  name: string;
  disabled?: boolean;
  value?: File | null;
  multiple?: boolean;
  children?: (uploads: UploadItem[]) => React.ReactNode;
  thumbs?: boolean;
};

export enum UploadStatus {
  WAITING = "waiting",
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  ERROR = "error"
}

type UploadItem = {
  data?: UploadFieldsFragment;
  uuid: string;
  presignedUrl?: string;
  progress?: number;
  status: UploadStatus;
  remove?: boolean;
  file?: File;
};

export const createUpload = async (
  file: File,
  opts: { embedded?: boolean; privateUpload?: boolean } = {}
) => {
  const hash = await getFileHash(file);
  const fd = new FormData();
  fd.append("filename", file.name);
  fd.append("size", file.size.toString());
  fd.append("xxHash", hash);
  fd.append("contentType", file.type);
  if (opts.embedded) {
    fd.append("embedded", "1");
  }
  if (opts.privateUpload) {
    fd.append("private", "1");
  }
  const result = await fetch("/resources/uploads/save", {
    method: "post",
    body: fd
  });
  return (await result.json()) as SaveUploadMutation;
};

type NotifyUploadCompleteParams = {
  etags?: string[];
  xxHash?: string;
};
export const notifyUploadComplete = async (
  id: string,
  params: NotifyUploadCompleteParams = {}
) => {
  let fd = new FormData();
  fd.append("id", id);
  fd.append("processAttachment", "1");
  for (const etag of params.etags || []) {
    fd.append("etags", etag);
  }
  if (params.xxHash) {
    fd.append("xxHash", params.xxHash);
  }
  return fetch("/resources/uploads/save", { method: "post", body: fd });
};

export default function FileUploader({
  width = "100%",
  disabled,
  accept,
  name,
  value: _value,
  multiple,
  onChange,
  children,
  thumbs
}: FileUploaderProps) {
  const ctx = useContext(RemixFormContext);
  invariant(ctx, "RemixFormContext is required");
  const [uploads, setUploads] = useState<UploadItem[]>(
    ctx.data?.uploads
      ? (ctx.data.uploads as UploadFieldsFragment[]).map((u) => ({
          uuid: v1(),
          status: UploadStatus.UPLOADED,
          data: u
        }))
      : ctx.data?.upload
        ? [
            {
              uuid: v1(),
              status: UploadStatus.UPLOADED,
              data: ctx.data.upload as UploadFieldsFragment
            }
          ]
        : []
  );

  const inputRef = useRef<HTMLInputElement>(null);

  // Only tell the form it's safe to save once all uploads are complete
  useEffect(() => {
    if (
      ctx.uploading &&
      !uploads.filter((d) => d.status === UploadStatus.UPLOADING).length
    ) {
      ctx.setUploading(false);
    }
  }, [ctx, uploads]);

  const uploadFile = async (f: File) => {
    // Add pending item to the list
    let item: UploadItem = {
      uuid: v1(),
      status: UploadStatus.UPLOADING,
      file: f,
      progress: 0
    };

    setUploads(
      produce((draft) => {
        draft.push(item);
      })
    );

    // Notify the parent form that it shouldn't submit the form yet
    ctx.setUploading(true);

    // Create the upload entry in the database and get a presigned url
    const json = await createUpload(f);
    item = {
      ...item,
      data: json.saveUpload,
      presignedUrl: json.saveUpload.presignedUrl
    };

    setUploads(
      produce((draft) => {
        const index = draft.findIndex((d) => d.uuid === item.uuid);
        draft[index] = item;
      })
    );

    // Upload to S3
    invariant(item.presignedUrl, "presignedUrl must be set");
    invariant(item.data, "data must be set");
    await uploadAndNotify(item.presignedUrl, item.data?.id, f, {
      onProgress: (percent) => {
        setUploads(
          produce((draft) => {
            const match = draft.find((d) => d.uuid === item.uuid);
            if (match) {
              match.progress = percent;
            }
          })
        );
      }
    });

    // invariant(item.data, "data must be set");
    // await notifyUploadComplete(item.data.id, { etags });

    setUploads(
      produce((draft) => {
        const match = draft.find((d) => d.uuid === item.uuid);
        if (match) match.status = UploadStatus.UPLOADED;
        if (!multiple) {
          for (const d of draft) {
            if (d.uuid !== item.uuid) {
              d.remove = true;
            }
          }
        }
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files: NestedFile[] = [];
    if (e.target.files) {
      for (const f of e.target.files) {
        if (
          !accept ||
          (lookup(f.name) &&
            accept.split(",").includes(lookup(f.name) as string))
        ) {
          files.push({ file: f, path: [] });
        }
      }

      if (multiple && files.length > 1) {
        onChange?.(name, files);
        for (const f of files) {
          uploadFile(f.file);
        }
      } else {
        const f = files[0];
        if (f) {
          onChange?.(name, f.file);
          uploadFile(f.file);
        }
      }
    }
  };

  const [{ canDrop }, dropRef] = useDrop({
    accept: NativeTypes.FILE,
    drop: async (item: { items: DataTransferItemList }) => {
      let filtered = await convertDataTransferToFiles(
        filterDataTransferFiles(item.items)
      );

      if (accept) {
        const mimeTypes = accept.split(",").map((a) => a.trim());
        filtered = filtered.filter(
          (f) =>
            lookup(f.file.name) &&
            mimeTypes.includes(lookup(f.file.name) as string)
        );
      }

      if (multiple && filtered.length > 1) {
        onChange?.(name, filtered);

        for (const f of filtered) {
          uploadFile(f.file);
        }
      } else {
        if (filtered.length > 1) {
          window.alert("Only one file can be uploaded.");
          return;
        }
        const f = filtered[0];
        if (f) {
          onChange?.(name, f.file);

          uploadFile(f.file);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div>
      <div style={{ width }} className="cursor-pointer">
        <div
          className={clsx(
            "flex items-center justify-center rounded-md border p-4",
            canDrop
              ? "border-dashed border-yellow-700 bg-yellow-100 text-yellow-900"
              : disabled
                ? "border-gray-300 bg-[#eee] text-gray-500"
                : "border-blue-100 bg-blue-50 text-blue-900"
          )}
          onClick={() => inputRef.current?.click()}
          ref={dropRef}
        >
          <div className="italic">
            {canDrop ? (
              <>
                <IconUpload /> Drop your {multiple ? "files" : "file"} here
              </>
            ) : (
              <>
                <IconUpload /> Drag & Drop {multiple ? "your files" : "a file"}{" "}
                or <u>Browse</u>
              </>
            )}
          </div>
        </div>
        <input
          type="file"
          className="hidden"
          onChange={handleChange}
          multiple={multiple}
          disabled={disabled}
          accept={accept}
          ref={inputRef}
        />
      </div>
      {/* Empty input to make sure this field gets processed */}
      {(multiple || !uploads.find((u) => !u.remove)) && (
        <input type="hidden" name={name} />
      )}
      {uploads.length > 0 && (
        <div className="mt-2">
          {children
            ? children(uploads)
            : uploads.map((u) => (
                <div key={u.uuid}>
                  {u.data?.id && !u.remove && (
                    <input type="hidden" name={name} value={u.data.id} />
                  )}
                  {u.data && (
                    <div className="flex items-center justify-between space-x-4">
                      {thumbs && u.data.thumbUrl && (
                        <img
                          src={u.data.thumbUrl}
                          className="max-w-32 object-cover"
                        />
                      )}
                      <div
                        className={clsx(
                          "min-w-0 flex-1 break-words py-1",
                          u.remove && "line-through"
                        )}
                      >
                        <a href={u.data.url} target="_blank">
                          {u.data.filename}
                        </a>
                      </div>
                      {u.status === UploadStatus.UPLOADING ? (
                        <div className="flex-1">
                          <ProgressBar
                            color="bg-blue-500"
                            percentage={u.progress || 0}
                          />
                        </div>
                      ) : u.status === UploadStatus.UPLOADED ? (
                        <div>
                          <Link
                            to={() => {
                              setUploads(
                                produce((draft) => {
                                  const match = draft.find(
                                    (d) => d.uuid === u.uuid
                                  );
                                  if (match) {
                                    match.remove = !match.remove;
                                    if (!match.remove && !multiple) {
                                      for (const d of draft) {
                                        if (d.uuid !== u.uuid) {
                                          d.remove = true;
                                        }
                                      }
                                    }
                                  }
                                })
                              );
                            }}
                            className="!no-underline"
                          >
                            {u.remove ? (
                              <>
                                <IconReply /> Restore
                              </>
                            ) : (
                              <IconTrash />
                            )}
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              ))}
        </div>
      )}
    </div>
  );
}

export const createUploadAndNotify = async (
  file: File,
  opts: { embedded?: boolean; privateUpload?: boolean } = {}
) => {
  const {
    saveUpload: { id, presignedUrl }
  } = await createUpload(file, opts);
  await uploadAndNotify(presignedUrl, id, file);

  return id;
};
export const uploadAndNotify = async (
  url: string,
  id: string,
  file: File,
  opts: { onProgress?: (percent: number) => void; submitHash?: boolean } = {}
) => {
  const { onProgress, submitHash } = opts;
  const urls = url.split("\n");
  let etags: string[] = [];
  if (urls.length > 1) {
    etags = await uploadMultipartWithProgress(file, url, (e, partNumber) => {
      if (e.lengthComputable) {
        onProgress?.(
          ((e.loaded + (partNumber - 1) * S3_CHUNK_SIZE) / file.size) * 100
        );
      }
    });
  } else {
    await uploadWithProgress("PUT", url, file, (e) => {
      if (e.lengthComputable) {
        onProgress?.((e.loaded / e.total) * 100);
      }
    });
  }
  if (submitHash) {
    const xxHash = await getFileHash(file);
    await notifyUploadComplete(id, { etags, xxHash });
  } else {
    await notifyUploadComplete(id, { etags });
  }
  return;
};
