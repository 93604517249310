import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { ContentTopicsQuery } from "~/types/api";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import ComboBox from "~/components/combobox";
import { CONTENT_CATEGORIES } from "~/components/content/form";
import { IconExternalLink } from "~/components/icons";
import Link from "~/components/link";
import { useFetcherData } from "~/utils/remix";

export type ContentTopicPickerProps = ComboBoxParentPropsSingle & {
  viewLink?: boolean;
};

export default function ContentTopicPicker({
  viewLink,
  placeholder = "Select a Content Page...",
  ...rest
}: ContentTopicPickerProps) {
  const fetcher = useFetcherData<ContentTopicsQuery>(
    "/resources/content/topic-picker"
  );
  const [localValue, setLocalValue] = useState(rest.defaultValue || rest.value);

  const contentTopics = useMemo(
    () => fetcher.data?.contentTopics || [],
    [fetcher.data]
  );

  const options = useMemo(() => {
    const sorted = _.sortBy(contentTopics, "title");
    return CONTENT_CATEGORIES.map(([cat]) => ({
      label: cat,
      options: sorted
        .filter((ct) => ct.category === cat)
        .map((ct) => ({
          value: ct.id,
          label: ct.title
        }))
    }));
  }, [contentTopics]);

  const selected = contentTopics.find((p) => p.id === localValue);
  const loading = !contentTopics.length;
  const onChange = (name: string, value: string) => {
    setLocalValue(value);
    const selected = contentTopics.find((p) => p.id === value);
    rest.onChange?.(name, value, selected);
  };
  return (
    <div className="inline-flex items-center">
      <div className="flex-1">
        <ComboBox
          {...rest}
          onChange={onChange}
          isLoading={loading}
          loadingMessage="Loading Content Pages..."
          placeholder={placeholder}
          options={options}
        />
      </div>
      {viewLink && selected && (
        <div>
          <Link to={`/content/${selected.id}`} target="_blank">
            <IconExternalLink /> View
          </Link>
        </div>
      )}
    </div>
  );
}
