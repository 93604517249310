import React, { useContext } from "react";
import { IconExclamation } from "~/components/icons";
import { FormSaveWarningContext } from "~/contexts";

const TEXT = "Unsaved Changes – Remember to Save!";

export default function SaveWarning() {
  const warn = useContext(FormSaveWarningContext);

  if (!warn) {
    return null;
  }

  return (
    <div>
      <big className="font-bold text-red-600">
        <IconExclamation /> {TEXT}
      </big>
    </div>
  );
}
