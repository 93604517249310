import clsx from "clsx";
import { useState, useEffect, useRef } from "react";

export interface DelayedTextInputProps {
  name: string;
  onChange: (name: string, text: string) => void;
  value?: string;
  placeholder?: string;
  className?: string;
}

export default function DelayedTextInput({
  value: text,
  onChange,
  name,
  placeholder,
  className
}: DelayedTextInputProps) {
  const [filterText, setFilterText] = useState(text || "");
  const timer = useRef<number | null>(null);
  const textRef = useRef(text);

  useEffect(() => {
    if (text !== textRef.current) {
      textRef.current = text;
      setFilterText(text || "");
    }
  }, [filterText, text]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, []);

  const updateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFilterText(value);
    if (timer.current) window.clearTimeout(timer.current);
    if (value) {
      timer.current = window.setTimeout(() => {
        onChange(name, value);
      }, 500);
    } else {
      onChange(name, "");
    }
  };

  const bypassEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      if (timer.current) window.clearTimeout(timer.current);
      onChange(name, filterText);
      event.preventDefault();
      return false;
    }
  };

  return (
    <input
      className={clsx("form-control", className)}
      type="text"
      value={filterText}
      onChange={updateText}
      placeholder={placeholder}
      onKeyDown={bypassEnter}
    />
  );
}
