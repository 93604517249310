import type {
  ComboBoxParentPropsSingle,
  GroupedComboBoxOption
} from "~/components/combobox";
import type { TrainingsQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { IconExternalLink } from "~/components/icons";
import Link from "~/components/link";
import { useFetcherData } from "~/utils/remix";

export interface TrainingPickerProps extends ComboBoxParentPropsSingle {
  exclude?: string;
  excludeIds?: string[];
  topicsOnly?: boolean;
  activeOnly?: boolean;
  viewLink?: boolean;
}

// type Option = {
//   label: string;
//   options: {
//     value: string;
//     label: string;
//     disabled: boolean;
//   }[];
// };

export default function TrainingPicker({
  exclude,
  excludeIds = [],
  topicsOnly,
  activeOnly,
  placeholder = "Select a training...",
  viewLink,
  ...rest
}: TrainingPickerProps) {
  const fetcher = useFetcherData<TrainingsQuery>("/resources/training/picker", {
    activeOnly
  });

  const options = useMemo(() => {
    const top = _.sortBy(fetcher.data?.trainings || [], "name");

    const collections = {
      label: "Collections",
      options: top
        .filter((t) => t.mode === "Collection")
        .map((t) => ({
          value: t.id,
          label: t.name,
          disabled: excludeIds.includes(t.id) || t.id === exclude
        }))
    } as GroupedComboBoxOption;

    const topics = {
      label: "Training Topics",
      options: top
        .filter((t) => t.mode === "Topic")
        .map((t) => ({
          value: t.id,
          label: t.name,
          disabled: excludeIds.includes(t.id) || t.id === exclude
        }))
    } as GroupedComboBoxOption;

    return topicsOnly ? [topics] : [collections, topics];
  }, [exclude, excludeIds, topicsOnly, fetcher.data]);

  return (
    <div className="inline-flex items-center">
      <div className="flex-1">
        <ComboBox {...rest} placeholder={placeholder} options={options} />
      </div>
      {viewLink && rest.value && (
        <div>
          <Link to={`/training/${rest.value}`} target="_blank">
            <IconExternalLink /> View
          </Link>
        </div>
      )}
    </div>
  );
}
