import type {
  Maybe,
  PhotosetFieldsFragment,
  PhotoViewerFieldsFragment,
  PhotoListFieldsFragment
} from "~/types/api";
import Link from "~/components/link";
import { formatDate } from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";

export const photoNumber = (photo: PhotoViewerFieldsFragment) => {
  if (photo.photoset.pfcs) {
    return `${photo.photoset.user!.login} ${formatNumber(photo.photoset.day!, {
      format: "00"
    })}.${formatNumber(photo.imageNumber, { format: "000" })}`;
  } else {
    return `${photo.photoset.party}-${formatNumber(photo.imageNumber, {
      format: "000"
    })}`;
  }
};

export const photosetNumber = (photoset: PhotosetFieldsFragment) => {
  return photoset.pfcs
    ? `${photoset.user!.login}-${formatNumber(photoset.day, { format: "00" })}`
    : null;
};

export const photosetName = (photoset: PhotosetFieldsFragment) => {
  const prefix = photoset.pfcs ? photosetNumber(photoset) : photoset.party;
  if (photoset.inspectionDate) {
    return `${prefix} (${formatDate(photoset.inspectionDate)})`;
  }
  return prefix;
};

export const reportLink = (
  project: { number: string },
  report: { id: string; name?: Maybe<string> }
) => {
  return (
    <Link to={`/projects/${project.number}/reports/${report.id}/edit`}>
      {report.name}
    </Link>
  );
};

export const cleanFilters = (filters: PhotoFilters) => ({
  ...filters,
  keyPhotos: filters.keyPhotos || undefined,
  matchAll: filters.matchAll || undefined,
  showTimes: filters.showTimes || undefined,
  query: filters.query || undefined,
  addressIds: filters.addressIds.length ? filters.addressIds : undefined,
  locationIds: filters.locationIds.length ? filters.locationIds : undefined,
  elementIds: filters.elementIds.length ? filters.elementIds : undefined,
  issueIds: filters.issueIds.length ? filters.issueIds : undefined,
  playerIds: filters.playerIds.length ? filters.playerIds : undefined,
  testLocationIds: filters.testLocationIds.length
    ? filters.testLocationIds
    : undefined,
  parties: filters.parties.length ? filters.parties : undefined
});

export const parsePhotoFilterParams = (params: URLSearchParams) => {
  return {
    query: params.get("query") || "",
    keyPhotos: params.get("keyPhotos") === "true",
    matchAll: params.get("matchAll") === "true",
    showTimes: params.get("showTimes") === "true",
    addressIds: params.getAll("addressIds[]"),
    locationIds: params.getAll("locationIds[]"),
    elementIds: params.getAll("elementIds[]"),
    issueIds: params.getAll("issueIds[]"),
    playerIds: params.getAll("playerIds[]"),
    testLocationIds: params.getAll("testLocationIds[]"),
    parties: params.getAll("parties[]"),
    page: params.get("page") ? parseInt(params.get("page") as string) : 1
  } as PhotoFilters;
};

export interface PhotoFilters {
  query: string;
  keyPhotos: boolean;
  matchAll: boolean;
  showTimes: boolean;
  addressIds: string[];
  locationIds: string[];
  elementIds: string[];
  issueIds: string[];
  playerIds: string[];
  testLocationIds: string[];
  parties: string[];
  page: number;
}

export interface PhotoData extends PhotoListFieldsFragment {
  index: number;
  date?: string | null;
  number: string;
  addressLinks: PhotoListFieldsFragment["photoLinks"];
  locationLinks: PhotoListFieldsFragment["photoLinks"];
  elementLinks: PhotoListFieldsFragment["photoLinks"];
  issueLinks: PhotoListFieldsFragment["photoLinks"];
  playerLinks: PhotoListFieldsFragment["photoLinks"];
  testingLinks: PhotoListFieldsFragment["photoLinks"];
}

export const isImage = (filename: string) =>
  /(jpg|jpeg|gif|png)/.test(filename.toLowerCase());
