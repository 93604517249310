import clsx from "clsx";
import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  small?: boolean;
}

export const ListGroup = ({
  small,
  className = "",
  children,
  ...rest
}: Props) => {
  return (
    <div
      className={clsx("list-group", small && "list-group-sm", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

interface ItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
  mode?: "warning" | "danger" | "info" | "success";
  flex?: boolean;
}

export const ListGroupItem = ({
  children,
  active,
  mode,
  flex,
  className = "",
  ...rest
}: ItemProps) => (
  <div
    className={clsx(
      "list-group-item",
      active && "active",
      className,
      mode,
      flex && "flex items-baseline justify-between space-x-4"
    )}
    {...(rest as React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >)}
  >
    {children}
  </div>
);
