import type { Fetcher, FormMethod } from "@remix-run/react";
import type { GraphQLError } from "graphql";
import type { GraphQLResponse } from "graphql-request";
import type { PropsWithChildren, Ref } from "react";
import type { ErrorsWithChangeset } from "~/components/remix-form/errors";
import {
  useActionData,
  useNavigation,
  useFetcher,
  Form,
  useSubmit
} from "@remix-run/react";
import _ from "lodash";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { Prompt } from "~/components/prompt";
import { RemixFormContext } from "~/contexts";

type FormPropsBase = PropsWithChildren<{
  method?: FormMethod;
  formRef?: Ref<HTMLFormElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any> | null;
  result?: Omit<GraphQLResponse, "errors"> & {
    errors?: (Partial<GraphQLError> & {
      changeset?: Record<string, unknown>;
    })[];
  };
  action?: string;
  prompt?: boolean;
  autoComplete?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (result: GraphQLResponse<any>["data"]) => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onChange?: () => void;
  className?: string;
}>;

// type FormPropsFetcher = FormPropsBase & { fetcher: true; redirect?: never };
type FormPropsFetcher = FormPropsBase & { fetcher?: boolean; redirect?: never };
type FormPropsRedirect = FormPropsBase & {
  fetcher?: never;
  redirect?: boolean;
};

/**
 * There are two ways to use this form. Either as a redirect, or a
 * fetcher.
 *
 * A redirect form expects the server to send a redirect response. It uses
 * useActionData() to extract any errors, and useNavigation() to detect
 * loading state.
 *
 * A fetcher form does not expect a redirect and should only be used in forms
 * that update in-place on the page. It uses useFetcher<unknown>() for both data/errors
 * and loading state. NOTE: A fetcher form *can* redirect, and will technically
 * work, however it will remove the form page from the history stack. So if you
 * go List > Form > Submit > Redirect to List, the history stack will actually
 * just show List > List since the fetcher's redirect executes the 302 in the
 * context of the currently viewed page.
 *
 * If neither redirect nor fetcher is specified, the form will default to a
 * "dumb" form with no loading state. Typically used for submitting search
 * forms as a GET a request.
 */
export const RemixForm = ({
  method,
  formRef,
  data,
  result,
  children,
  action,
  fetcher,
  redirect,
  onSuccess,
  autoComplete,
  onSubmit,
  onChange,
  className,
  prompt = true
}: FormPropsFetcher | FormPropsRedirect) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const f = useFetcher<any>();
  const nav = useNavigation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const a = useActionData<any>();
  const [dirty, setDirty] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [submitAfterUpload, setSubmitAfterUpload] =
    useState<HTMLFormElement | null>(null);

  const errors: ErrorsWithChangeset | string | null =
    (result?.errors as ErrorsWithChangeset) ||
    (fetcher
      ? f.data?.error ||
        f.data?.errors ||
        (f.data?.status === 500 && "Internal Server Error")
      : redirect
        ? a?.error || a?.errors
        : null);

  const fieldErrors = useMemo(() => {
    const keyed = errors?.[0]?.changeset?.errors || {};
    return _.mapKeys(keyed, (_value, key) => _.camelCase(key));
  }, [errors]);
  const Component = fetcher ? f.Form : Form;
  const sub = useSubmit();

  // If using a fetcher and the save is successful, close the modal
  if (
    fetcher &&
    onSuccess &&
    f.state === "idle" &&
    f.data &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !f.data?.errors &&
    !f.data?.error &&
    f.data?.status !== 500
  ) {
    onSuccess(f.data);
  }

  const prevState = useRef("idle");
  const state =
    uploading && submitAfterUpload
      ? "uploading"
      : fetcher
        ? f.state !== "submitting" &&
          (f.data?.errors || f.data?.error || f.data?.status === 500)
          ? "errors"
          : f.state === "idle" && f.data
            ? "done"
            : f.state !== "idle"
              ? "loading"
              : "idle"
        : redirect
          ? nav.state === "submitting" ||
            (nav.formAction && nav.state === "loading")
            ? "loading"
            : a?.error || a?.errors
              ? "errors"
              : prevState.current !== "idle"
                ? "done"
                : "idle"
          : "idle";

  prevState.current = state;

  useEffect(() => {
    state === "done" && setDirty(false);
  }, [state]);

  const submittingRef = useRef(false);

  // In case the form stays dirty after submit due to errors, reset the submittal
  useEffect(() => {
    if (dirty && prompt) {
      submittingRef.current = false;
    }
  }, [dirty, errors, prompt]);

  const ctx = useMemo(() => {
    return {
      data,
      state: state as "loading" | "errors" | "done" | "idle" | "uploading",
      errors: errors,
      fieldErrors: fieldErrors,
      fetcher: fetcher ? f : undefined,
      setDirty,
      dirty,
      uploading,
      setUploading
    };
    // Don't want full fetcher object as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dirty, errors, fetcher, fieldErrors, state, uploading]);

  // If we are still uploading a file, save the form reference and then
  // submit after the upload is complete.
  useEffect(() => {
    if (!uploading && submitAfterUpload) {
      setSubmitAfterUpload(null);
      fetcher ? f.submit(submitAfterUpload) : sub(submitAfterUpload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading, submitAfterUpload, fetcher]);

  return (
    <Component
      replace={false}
      method={method || "post"}
      onSubmit={(e) => {
        submittingRef.current = true;
        if (uploading) {
          setSubmitAfterUpload(e.target as HTMLFormElement);
          e.preventDefault();
        } else {
          onSubmit?.(e);
        }
      }}
      ref={formRef}
      action={action}
      className={className}
      onChange={() => {
        setDirty(true);
        onChange?.();
      }}
      autoComplete={autoComplete}
    >
      {prompt && (
        <Prompt
          when={dirty}
          submittingRef={submittingRef}
          message="You have not saved your changes! Are you sure you want to leave this page?"
          beforeUnload
        />
      )}

      {/* Always include the ID as a hidden field */}
      {data?.id && <input type="hidden" name="id" value={data.id} />}
      <RemixFormContext.Provider value={ctx}>
        {children}
      </RemixFormContext.Provider>
    </Component>
  );
};

export const fetcherSucceeded = (fetcher: Fetcher) =>
  fetcher.state === "idle" &&
  fetcher.data?.status !== 500 &&
  !!fetcher.data &&
  !fetcher.data.errors;

export const fetcherFailed = (fetcher: Fetcher) => {
  return (
    fetcher.state !== "submitting" &&
    (!!fetcher.data?.error ||
      !!fetcher.data?.errors ||
      fetcher.data?.status === 500)
  );
};

// Re-export Input from ./input default export
export { Input } from "./input";
export { Buttons } from "./buttons";
export { Errors, useErrorAlert } from "./errors";
export type { ErrorsWithChangeset } from "./errors";
