import type { ComboBoxParentProps } from "~/components/combobox";
import type { SocialMediaAccountsQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export default function SocialMediaAccountPicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<SocialMediaAccountsQuery>(
    "/resources/social-media/account-picker"
  );

  const options = useMemo(
    () =>
      _.sortBy(
        (fetcher.data?.socialMediaAccounts || []).filter(
          (sma) => sma.active || sma.id === props.value
        ),
        "rank"
      ).map((a) => ({
        value: a.id,
        label: `${a.user ? a.user.fullname : "Company"}: ${a.name}`
      })),
    [props.value, fetcher.data]
  );

  return (
    <ComboBox placeholder="Select an Account..." {...props} options={options} />
  );
}
