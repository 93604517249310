import type { ModalProps } from "~/components/modal";
import { useEffect, useRef } from "react";
import { IconLeft, IconRight, IconX } from "~/components/icons";

type Props = ModalProps & {
  url: string;
  label?: string;
  navigate?: (direction: "left" | "right") => void;
};

export default function Lightbox({ onClose, url, label, navigate }: Props) {
  const overlay = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
        e.preventDefault();
      } else if (e.key === "ArrowRight" && navigate) {
        navigate("right");
        e.preventDefault();
      } else if (e.key === "ArrowLeft" && navigate) {
        navigate("left");
        e.preventDefault();
      }
    };

    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", handleKey);
    // Re-enable scrolling when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKey);
      document.body.style.overflow = originalStyle;
    };
  }, [navigate, onClose]); // Empty array ensures effect is only run on mount and unmount

  return (
    <div
      style={{ zIndex: 2000 }}
      ref={overlay}
      className="fixed bottom-0 left-0 right-0 top-0 flex select-none items-center space-x-8 bg-black bg-opacity-95 p-8"
      onClick={(e) => overlay.current === e.target && onClose()}
    >
      {navigate && (
        <div className="cursor-pointer" onClick={() => navigate("left")}>
          <IconLeft className="text-5xl font-bold leading-normal text-white" />
        </div>
      )}
      <div className="flex h-screen flex-1 flex-col justify-between px-12 py-8">
        <div className="relative flex-1">
          <img
            className="full absolute max-h-full max-w-full object-scale-down"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)"
            }}
            src={url}
          />
        </div>
        {label && (
          <div className="py-8 text-center text-5xl leading-normal text-white">
            {label}
          </div>
        )}
      </div>
      {navigate && (
        <div className="cursor-pointer" onClick={() => navigate("right")}>
          <IconRight className="cursor-point text-5xl font-bold leading-normal text-white" />
        </div>
      )}
      <div
        className="absolute cursor-pointer"
        style={{ top: 20, right: 20, zIndex: 2500 }}
        onClick={onClose}
      >
        <IconX className="text-5xl font-bold leading-normal text-white" />
      </div>
    </div>
  );
}
