import type { ReactNode } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import Body from "~/components/modal-body";
import Footer from "~/components/modal-footer";
import Header from "~/components/modal-header";
import { ModalContext } from "~/contexts";

// To "lock" a modal so you can't click away, pass in onExplicitClose() instead.
// This will cause the modal to close only when the Close/Cancel button is clicked or
// the x in the upper right corner is clicked.
export interface ModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: (value?: any) => void;
  onExplicitClose?: undefined;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}

interface ModalPropsExplicit {
  onClose?: undefined;
  onExplicitClose: () => void;
  form?: undefined;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
}

type ModalPropsCombined = ModalProps | ModalPropsExplicit;

type Props = ModalPropsCombined & {
  size?: "Large" | "XL" | undefined;
  children?: ReactNode;
};

const Modal = ({
  onClose,
  onExplicitClose,
  size,
  initialFocus,
  children
}: Props) => {
  // const formClose = form ? form.closeHandler(onClose!) : undefined;

  return (
    <Dialog
      open
      initialFocus={initialFocus}
      // Don't just pass `close`, we want to strip out the event
      // since some close handlers depend on an actual returned value
      onClose={() => onClose?.()}
      className="fixed inset-0 z-[1000] overflow-y-auto"
    >
      <ModalContext.Provider value={{ close: onClose || onExplicitClose! }}>
        <div className="x-items-center flex min-h-screen justify-center">
          <div className="fixed inset-0 bg-black/50" />
          <DialogPanel
            className={clsx(
              "modal-dialog",
              size === "Large" && "modal-lg",
              size === "XL" && "modal-xl"
            )}
          >
            <div className="modal-content">{children}</div>
            <a
              href="#"
              className="h-0 w-0 overflow-hidden"
              onClick={(e) => e.preventDefault()}
            />
          </DialogPanel>
        </div>
      </ModalContext.Provider>
    </Dialog>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
