import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { ShowListQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { flattenListItems } from "~/utils/bpa";
import { useFetcherData } from "~/utils/remix";

export interface ListItemPickerProps extends ComboBoxParentPropsSingle {
  listId: string;
  excludeId?: string;
}

export default function ListItemPicker({
  listId,
  excludeId,
  ...props
}: ListItemPickerProps) {
  const fetcher = useFetcherData<ShowListQuery>(`/resources/lists/${listId}`);

  const options = useMemo(() => {
    const items = _.sortBy(fetcher.data?.list.items || [], "name");
    return flattenListItems(items)
      .filter((l) => l.id !== excludeId)
      .map((i) => ({
        value: i.id,
        label: i.name!,
        indentLevel: i.indent
      }));
  }, [fetcher.data, excludeId]);

  return <ComboBox {...props} options={options} />;
}
