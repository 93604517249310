import type { ComboBoxParentProps } from "~/components/combobox";
import type { MeetingsQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import DisplayNames from "~/utils/display-names";
import { useFetcherData } from "~/utils/remix";

export default function MeetingPicker(props: ComboBoxParentProps) {
  const project = useProject();
  const fetcher = useFetcherData<MeetingsQuery>("/resources/meetings/picker", {
    number: project.number
  });

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.interviews || [], "date", "description").map(
        (i) => ({
          value: i.id,
          label: DisplayNames.interview(i)
        })
      ),
    [fetcher.data]
  );

  return <ComboBox {...props} options={options} />;
}
