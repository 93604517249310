import type {
  ComboBoxOption,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type { SkUsQuery, SkuFieldsFragment } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import Badge from "~/components/badge";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export type SKUPickerProps = ComboBoxParentPropsSingle & {
  category?: string;
  reportType?: "Project" | "Employee" | "Candidate";
  overhead?: boolean;
  disableEmpty?: boolean;
  hideSamples?: boolean;
  templatesOnly?: boolean;
};

export default function SKUPicker({
  disableEmpty,
  category,
  reportType,
  overhead,
  hideSamples,
  templatesOnly,
  ...props
}: SKUPickerProps) {
  const fetcher = useFetcherData<SkUsQuery>("/resources/skus/picker");

  const options = useMemo(() => {
    return _.sortBy(
      (fetcher.data?.skus || [])
        .filter((s) => (category ? s.category === category : true))
        .filter((s) => (overhead ? true : !s.reportOverhead))
        .filter((s) => (templatesOnly ? s.templates.length > 0 : true))
        .filter((s) =>
          reportType === undefined ? true : s.reportTypes.includes(reportType)
        ),
      "name"
    ).map(
      (sku): ComboBoxOption => ({
        value: sku.id,
        label: sku.name,
        rightLabelMode: "default",
        rightLabel:
          hideSamples || disableEmpty ? undefined : sku.samples.length === 0 ? (
            <Badge label="No Samples" />
          ) : undefined,
        count: sku.samples.length,
        extra: sku,
        disabled: sku.samples.length === 0 && !!disableEmpty
      })
    );
  }, [
    fetcher.data,
    category,
    reportType,
    overhead,
    hideSamples,
    disableEmpty,
    templatesOnly
  ]);

  const grouped = useMemo(() => {
    if (reportType) {
      return [
        {
          label: "Blank Templates",
          options: options.filter(
            (o) => (o.extra as SkuFieldsFragment).templates.length === 0
          )
        },
        {
          label: "Prefilled Templates",
          options: options.filter(
            (o) => (o.extra as SkuFieldsFragment).templates.length > 0
          )
        }
      ];
    } else {
      return options;
    }
  }, [reportType, options]);

  const handleChange = (name: string, value: string) => {
    const match = value
      ? options.find((o) => o.value === value)!.extra
      : undefined;
    props.onChange?.(name, value, match);
  };

  return <ComboBox {...props} onChange={handleChange} options={grouped} />;
}
