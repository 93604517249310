import type { DocumentRowFieldsFragment } from "~/types/api";
import { useState } from "react";
import Button from "~/components/button";
import ButtonGroup from "~/components/button-group";
import ButtonLink from "~/components/button-link";
import LibraryPicker from "~/components/documents/library-picker";
import { IconAttachment } from "~/components/icons";
import Link from "~/components/link";
import DisplayNames from "~/utils/display-names";
import { getPrimaryFile, getDocLink } from "~/utils/documents";

export interface LibrarySelectProps {
  value?: DocumentRowFieldsFragment;
  name: string;
  onChange: (name: string, value: DocumentRowFieldsFragment) => void;
  thumbnails?: boolean;
}

export default function LibrarySelect({
  value,
  name,
  onChange,
  thumbnails
}: LibrarySelectProps) {
  const [selecting, setSelecting] = useState(false);

  const handleChange = (document: DocumentRowFieldsFragment) => {
    onChange(name, document);
    setSelecting(false);
  };

  const document = value;
  const attachment = getPrimaryFile(document);
  return (
    <div>
      <input type="hidden" name={name} value={value?.id} />
      <div className="float-right" style={{ marginLeft: 10 }}>
        <ButtonGroup>
          {document && attachment?.upload && (
            <ButtonLink external to={attachment.upload.url} target="_blank">
              <IconAttachment />
            </ButtonLink>
          )}
          <Button onClick={() => setSelecting(true)}>
            {document ? "Change" : "Choose"}...
          </Button>
        </ButtonGroup>
        {selecting && (
          <LibraryPicker
            thumbnails={thumbnails}
            onClose={() => setSelecting(false)}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="italic" style={{ paddingTop: 4 }}>
        {document ? (
          <Link to={getDocLink(document)}>
            {document.publicDescription || DisplayNames.document(document)}
          </Link>
        ) : (
          "Choose a File..."
        )}
      </div>
    </div>
  );
}
