import type { HTMLElement as ElementType } from "node-html-parser";
import type { ReactNode } from "react";
import clsx from "clsx";
import { parse } from "node-html-parser";
import { useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lightbox from "~/components/lightbox";
import { slateHTMLIsBlank } from "~/utils/text";

// Viewer to display persisted HTML from a Slate viewer
//
// Contains special logic to handle clicking of any links. If the link is to
// another intranet page, it gets pushed via history instead.
type Props = {
  html?: string | null;
  className?: string;
  /** Class name to use when there is no content */
  emptyClassName?: string;
  /**
   * Indicates if the content is known to be a single list with no other
   * elements, and if so, the list portion will not be indented 40px
   * like usual
   */
  singleList?: boolean;
  /** Show this label if the slate content is blank */
  emptyLabel?: ReactNode;
};

export default function SlateViewer({
  html,
  className,
  emptyClassName,
  emptyLabel,
  singleList
}: Props) {
  const navigate = useNavigate();
  const [src, setSrc] = useState("");
  const images = useMemo(
    () =>
      html
        ? [...html.matchAll(/<img src='(.*?)'/gi)].map((item) => item[1])
        : [],
    [html]
  );
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLElement).nodeName === "A") {
      const href = (e.target as HTMLAnchorElement).href;
      if (
        (href.startsWith("/") ||
          href.startsWith("https://intranet.petefowler.com/")) &&
        !e.metaKey &&
        !e.ctrlKey
      ) {
        const stripped = href.replace(
          /^https:\/\/intranet\.petefowler\.com/,
          ""
        );
        if (!stripped.startsWith("/download/")) {
          e.preventDefault();
          navigate(stripped);
        }
      }
    } else if ((e.target as HTMLElement).nodeName === "IMG") {
      setSrc((e.target as HTMLImageElement).src);
    }
  };
  const changeImage = useCallback(
    (direction: "left" | "right") => {
      setSrc((src) => {
        const index = images.indexOf(src);
        if (direction === "right") {
          return images[index === images.length - 1 ? 0 : index + 1];
        } else {
          return images[index > 0 ? index - 1 : images.length - 1];
        }
      });
    },
    [images]
  );

  if (!html || slateHTMLIsBlank(html)) {
    return emptyLabel ? (
      <div className={emptyClassName}>{emptyLabel}</div>
    ) : null;
  }

  const parsed = parse(html);
  const singleListParsed =
    parsed.childNodes.length === 1 &&
    ["ul", "ol"].includes((parsed.childNodes[0] as ElementType).rawTagName);

  return (
    <>
      <div
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: html }}
        className={clsx(
          className,
          "slate-editor readonly",
          singleList && singleListParsed && "single-list"
        )}
      />
      {src && (
        <Lightbox
          url={src}
          navigate={images.length > 1 ? changeImage : undefined}
          onClose={() => setSrc("")}
        />
      )}
    </>
  );
}
