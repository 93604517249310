import type { ComboBoxParentProps } from "~/components/combobox";
import type { TagsQuery } from "~/types/api";
import _ from "lodash";
import { useEffect, useState } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export default function SectionPicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<TagsQuery>("/resources/tags/picker");
  const [opts, setOpts] = useState<Array<string>>([]);
  useEffect(() => {
    if (fetcher.data) {
      setOpts(_.uniq((fetcher.data.tags || []).map((t) => t.section)).sort());
    }
  }, [fetcher.data]);

  const addOpt = (option: string) => {
    if (option && !opts.includes(option)) {
      setOpts([...opts, option]);
    }
  };

  return <ComboBox {...props} options={opts} onCreateOption={addOpt} />;
}
