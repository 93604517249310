import type { PhysicalAddressFieldsFragment } from "~/types/api";
import Link from "~/components/link";

interface Props {
  physicalAddress: PhysicalAddressFieldsFragment;
  className?: string;
  oneLine?: boolean;
}

export default function AddressCard({
  physicalAddress,
  className,
  oneLine
}: Props) {
  if (oneLine)
    return <div className={className}>{physicalAddress.formatted}</div>;

  let csz = `${
    physicalAddress.city || physicalAddress.county || physicalAddress.area || ""
  }, ${physicalAddress.state || ""} ${physicalAddress.zip || ""}`;
  csz = csz.replace(/^, {2}/g, "");
  csz = csz.replace(/^, /g, "");
  csz = csz.replace(/,\s*$/g, "");
  let streetAddress = [physicalAddress.number, physicalAddress.street]
    .filter((i) => i)
    .join(" ")
    .trim();
  if (!streetAddress && physicalAddress.neighborhood) {
    csz = csz
      ? `${physicalAddress.neighborhood}, ${csz}`
      : physicalAddress.neighborhood;
  }
  if (physicalAddress.unit) {
    const unitWithType = `${physicalAddress.unitType || ""} ${
      physicalAddress.unit
    }`.trim();
    streetAddress = streetAddress
      ? `${streetAddress}, ${unitWithType}`
      : unitWithType;
  }

  return (
    <div className={className}>
      {physicalAddress.premise && <div>{physicalAddress.premise}</div>}
      {streetAddress && <div className="auto-line-breaks">{streetAddress}</div>}
      {csz}
      {physicalAddress.country &&
        physicalAddress.country !== "United States" && (
          <div>{physicalAddress.country}</div>
        )}
      {physicalAddress.community && (
        <div>
          <Link to={`/communities/${physicalAddress.community.id}`}>
            Community: {physicalAddress.community.name}
          </Link>
        </div>
      )}
    </div>
  );
}
