import type { AttachedFileFieldsFragment } from "~/types/api";
import { getPrimaryFile } from "~/utils/documents";

interface Props {
  document: { attachedFiles: AttachedFileFieldsFragment[] };
  maxHeight?: number;
}

export default function Thumbnail({ document, maxHeight }: Props) {
  const primary = getPrimaryFile(document);

  return primary?.upload?.thumbUrl ? (
    <img
      className="img img-responsive"
      style={{
        maxHeight,
        borderRadius: 4,
        border: "1px solid #E6E8EB"
      }}
      src={primary.upload.thumbUrl}
    />
  ) : null;
}
