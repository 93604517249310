import type { ComboBoxParentProps } from "~/components/combobox";
import type { PlanTypesPickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export default function PlanTypePicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<PlanTypesPickerQuery>(
    "/resources/plans/plan-type-picker"
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.planTypes || [], "name").map((pt) => ({
        value: pt.id,
        label: pt.name
      })),
    [fetcher.data]
  );

  return <ComboBox {...props} options={options} />;
}
