import type { ButtonModes } from "~/components/button";
import clsx from "clsx";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  mode?: ButtonModes;
  stacked?: boolean;
  label?: React.ReactNode;
  pill?: boolean;
}

export default function Badge({
  mode = "default",
  label,
  stacked,
  pill,
  className = "",
  children,
  ...rest
}: Props) {
  return pill ? (
    <span className={clsx("badge", className)} {...rest}>
      {label ?? children}
    </span>
  ) : (
    <span
      className={clsx(
        `label label-${mode}`,
        stacked && "label-stacked",
        className
      )}
      {...rest}
    >
      {label ?? children}
    </span>
  );
}
