import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { US_STATES_PRIORITIZED } from "~/constants";

const options = Object.keys(US_STATES_PRIORITIZED).map((group) => ({
  label: group,
  options: US_STATES_PRIORITIZED[group].map((s) => ({
    label: s[0],
    value: s[1]
  }))
}));

export default function StatePicker({
  placeholder = "Select a State...",
  ...rest
}: ComboBoxParentProps) {
  return <ComboBox {...rest} placeholder={placeholder} options={options} />;
}
