import type { PropsWithChildren, ReactNode } from "react";
import type { DocRowType } from "~/components/documents/list";
import { useFetcher } from "@remix-run/react";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";
import Badge from "~/components/badge";
import DescriptionField from "~/components/bpa/description-field";
import Button from "~/components/button";
import ConfirmDelete from "~/components/confirm-delete";
import HeaderForm from "~/components/documents/header-form";
import Thumbnail from "~/components/documents/thumbnail";
import FormattedDate from "~/components/formatted-date";
import {
  IconStar,
  IconFolder,
  IconPrint,
  IconBook,
  IconWarning,
  IconTrash,
  IconUp,
  IconDown,
  IconBlank,
  IconDownload,
  IconEdit,
  IconAdd,
  IconLayer,
  Icon
} from "~/components/icons";
import Link from "~/components/link";
import Linkify from "~/components/linkify";
import PileProgress from "~/components/piles/progress";
import ProjectLink from "~/components/projects/link";
import SlateViewer from "~/components/rich-editor/viewer";
import ShortLinkGenerator from "~/components/short-link-generator";
import { useOptionalProject } from "~/contexts";
import { formatDate } from "~/utils/dates";
import DisplayNames from "~/utils/display-names";
import { getPrimaryFile, getDocLink, getFileType } from "~/utils/documents";
import { plural } from "~/utils/formatting";
import { slateHTMLIsBlank, slateIsBlank } from "~/utils/text";

interface Props extends PropsWithChildren<unknown> {
  counts?: { files: number; folders: number; pages: number };
  descriptionField?: boolean;
  disabled?: boolean;
  document: DocRowType;
  editable?: boolean;
  fileType?: boolean;
  hasBates?: boolean;
  hideSummary?: boolean;
  includeSource?: boolean;
  includeThumb?: boolean;
  library?: boolean;
  minimal?: boolean;
  nested?: boolean;
  onAdd?: (document: DocRowType) => void;
  onDelete?: (id: string) => void;
  onMoveDown?: (id: string) => void;
  onMoveUp?: (id: string) => void;
  onSelect?: (id: string, checked: boolean) => void;
  onView?: (id: string) => void;
  pdfWarning?: boolean;
  redirectFolders?: boolean;
  section?: boolean;
  selected?: boolean;
  toggleHidden?: (id: string) => void;
  viewing?: boolean;
}

export default React.memo(function DocumentRow({
  children,
  counts,
  descriptionField,
  disabled,
  document,
  editable,
  fileType,
  hasBates,
  hideSummary,
  includeSource,
  includeThumb,
  library,
  minimal,
  nested,
  onAdd,
  onDelete,
  onMoveDown,
  onMoveUp,
  onSelect,
  onView,
  pdfWarning,
  redirectFolders,
  section = true,
  selected,
  toggleHidden,
  viewing
}: Props) {
  const primary = document;
  const [params] = useSearchParams();
  const fetcherDelete = useFetcher<unknown>();
  const dataDoc = document.referenceDocument || document;
  const location = useLocation();
  const docLink = getDocLink(primary, location);
  const af = getPrimaryFile(primary);
  const isFile = ["File", "Reference"].includes(primary.mode);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [addingFolder, setAddingFolder] = useState(false);
  const fullSection = `${primary.section || ""}${
    primary.suffix ? `-${primary.suffix}` : ""
  }`;

  const deletePile = async () => {
    invariant(primary.pile, "Pile must exist");
    fetcherDelete.submit(
      { id: primary.pile.id },
      { action: "/resources/piles/delete", method: "post" }
    );
  };

  const linkTo = (value: ReactNode) => {
    if (!value) return null;
    if (primary.mode === "Folder" && primary.pile) {
      return <Link to={`./piles/${primary.pile.id}`}>{value}</Link>;
    }
    // We want a link that clicking toggles the section open, but CTRL/CMD-click or
    // right-clicking should just open the whole folder in a new tab.
    if (["Folder", "Header"].includes(primary.mode)) {
      return (
        <Link
          to={
            primary.mode === "Folder"
              ? { append: { parentId: primary.id, query: undefined } }
              : () => toggleHidden?.(primary.id)
          }
          onClick={(e) => {
            if (
              !redirectFolders &&
              primary.mode === "Folder" &&
              !e.ctrlKey &&
              !e.metaKey
            ) {
              e.stopPropagation();
              e.preventDefault();
              toggleHidden?.(primary.id);
            }
          }}
        >
          {value}
        </Link>
      );
    }

    return <Link to={docLink}>{value}</Link>;
  };

  let message = "";
  if (pdfWarning) {
    if (!af?.upload) {
      message =
        "There is no attachment on the selected document, so nothing will be attached to the final PDF.";
    } else if (!/\.pdf/i.test(af.upload.filename)) {
      message = "Non-PDF documents can't be attached to the final PDF.";
    } else if (af.upload.pdfSecurity) {
      message =
        "This is a secured PDF document, so it can't be automatically attached.";
    }
  }
  const warning = message ? (
    <div className="text-danger font-bold">
      <IconWarning /> {message}
    </div>
  ) : null;

  const date = !dataDoc.endDate
    ? dataDoc.date
      ? formatDate(dataDoc.date)
      : "N/A"
    : `${dataDoc.date ? formatDate(dataDoc.date) : "???"}-${formatDate(
        dataDoc.endDate
      )}`;

  const received =
    dataDoc.mode === "File"
      ? dataDoc.receivedDate
        ? formatDate(dataDoc.receivedDate)
        : "N/A"
      : "";

  const description = (
    <>
      {!dataDoc.description && af?.upload ? (
        <>
          <span className="text-warning font-bold">Uploaded Document:</span>{" "}
          {af.upload.filename.replace(/_/g, " ")}
        </>
      ) : (
        dataDoc.description
      )}
      {warning}
    </>
  );

  let { author } = dataDoc;
  if (dataDoc.author && dataDoc.recipient) {
    author = `${dataDoc.author} → ${dataDoc.recipient}`;
  } else if (dataDoc.recipient) {
    author = `N/A → ${dataDoc.recipient}`;
  }

  let indent = primary.path.replace(/[^.]*/g, "").length * 25;
  const project = useOptionalProject();

  // Base width is 4 fields: description, received date, pg count, and summary
  let width =
    4 +
    (hideSummary && !minimal ? -1 : 0) +
    (includeSource ? 1 : 0) +
    (includeThumb ? 1 : 0) +
    (fileType ? 1 : 0) +
    (hasBates && !minimal ? 1 : 0) +
    (minimal ? -1 : 0);

  if (primary.pile && !minimal) {
    width -= 1;
  }
  if (fullSection) {
    indent -= 25;
  }
  if (!nested) {
    indent = 0;
  }

  const toggle = (event: React.ChangeEvent<HTMLInputElement>) =>
    isFile && onSelect?.(primary.id, event.target.checked);

  const toggleRow = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (
      (event.target as HTMLInputElement).type !== "checkbox" &&
      (event.target as HTMLElement).nodeName !== "A"
    ) {
      isFile && onSelect?.(primary.id, !selected);
    }
  };

  const classes = [];
  if (primary.mode === "Header") {
    if (primary.author) {
      classes.push("success font-bold");
    } else {
      classes.push("danger font-bold");
    }
  }

  if (primary.mode === "Folder") {
    classes.push("info font-bold");
  }

  if (viewing) {
    classes.push("warning");
  }

  return (
    <>
      <tr
        onClick={
          !isFile
            ? undefined
            : onSelect && !disabled
              ? toggleRow
              : onView
                ? () => onView(document.id)
                : undefined
        }
        className={clsx(
          classes,
          (onSelect || onView) && !disabled && isFile && "cursor-pointer"
        )}
      >
        <td />
        <td className="text-center">{primary.key && <IconStar />}</td>
        {section && (
          <td
            className="max-w-[150px] text-center"
            style={{ wordBreak: "break-word" }}
          >
            {linkTo(
              fullSection || <span className="whitespace-nowrap">N/A</span>
            )}
          </td>
        )}
        {isFile ? (
          <>
            {/* FILES/REFERENCES */}
            <td className="text-center">{linkTo(date)}</td>
            <td>{linkTo(author)}</td>
            <td className="!p-0">
              <div className="flex space-x-4">
                {_.times(indent / 25, (num) => (
                  <div
                    key={num}
                    className="border-l border-gray-300 py-2 text-gray-300 first:ml-4"
                  >
                    &rarr;
                  </div>
                ))}
                <div className="flex-1 p-2">{linkTo(description)}</div>
              </div>
            </td>
            {includeSource && "project" in primary && (
              <td>
                {primary.contentTopic ? (
                  <Link to={`/content/${primary.contentTopic.id}/files`}>
                    {primary.contentTopic.title}
                  </Link>
                ) : primary.project ? (
                  <ProjectLink project={primary.project} page="files" />
                ) : (
                  "Library"
                )}
              </td>
            )}
            {fileType && (
              <td>
                {_.uniq(
                  _.sortBy(dataDoc.attachedFiles, (d) => !d.primaryFile)
                    .filter((af) => af.upload)
                    .map((af) => getFileType(af.upload!.filename))
                ).join(" ")}
              </td>
            )}
            <td className="text-center">{linkTo(received)}</td>
            {hasBates && !minimal ? (
              <td className="text-center">{linkTo(dataDoc.batesStamp)}</td>
            ) : null}
            <td className="text-center">{dataDoc.pages}</td>
            {!hideSummary && !minimal && (
              <td className="auto-line-breaks">
                {document.hidden && !library && (
                  <div className="float-right ml-4">
                    <Badge label="Hidden on Client Access" />
                  </div>
                )}
                <Linkify>
                  {dataDoc.summary || (
                    <em className="text-gray-400">No Summary</em>
                  )}
                </Linkify>
                {!project &&
                  !library &&
                  "project" in document &&
                  document.project && (
                    <div>
                      <em>
                        From {document.project.number} {document.project.name}
                      </em>
                    </div>
                  )}

                {!document.attachedFiles.length && document.source && (
                  <div className="mt-4">
                    <em>See</em>{" "}
                    <em className="font-bold">{document.source}</em>
                  </div>
                )}

                {document.referenceDocument && (
                  <>
                    <div className="mt-4">
                      <em>
                        Reference to{" "}
                        <Link to={getDocLink(dataDoc)}>
                          {DisplayNames.document(dataDoc, {
                            includeSection: true
                          })}
                        </Link>
                      </em>
                    </div>
                    {primary.summary && (
                      <div className="auto-line-breaks mt-4">
                        <Linkify>{primary.summary}</Linkify>
                      </div>
                    )}
                  </>
                )}
              </td>
            )}
            {includeThumb && (
              <td>
                <div className="w-[140px] text-center">
                  {!isFile ? null : linkTo(<Thumbnail document={dataDoc} />)}
                </div>
              </td>
            )}
            {!minimal && (
              <>
                {project?.billable && (
                  <td className="text-center">
                    {primary.printed && <IconPrint />}
                  </td>
                )}
                {project?.mode === "Overhead" && (
                  <td className="text-center">
                    {primary.library && (
                      <IconBook
                        title="Searchable in Library"
                        titleId="icon-searchable"
                      />
                    )}
                  </td>
                )}
                <td className="text-center">
                  {!slateIsBlank(primary.analysisSlate) && (
                    <Link
                      external
                      to={`/download/file_analysis/${primary.id}.pdf`}
                      target="_blank"
                      className="text-gray-700"
                    >
                      <Icon
                        name={
                          primary.analysisStatus === "Needs Peer Review"
                            ? "user"
                            : primary.analysisStatus === "Published"
                              ? "check"
                              : "comments"
                        }
                        title={`${
                          primary.analysisDate
                            ? `Analyzed ${formatDate(primary.analysisDate)}`
                            : "Has Analysis"
                        }${
                          primary.analysisStatus === "Internal"
                            ? ""
                            : `: ${primary.analysisStatus}`
                        }`}
                        titleId="analyzed-status"
                      />
                    </Link>
                  )}
                </td>
              </>
            )}
          </>
        ) : (
          <>
            {/* FOLDERS/HEADERS */}
            {/* Date/Author */}
            <td className="text-center">
              {linkTo(<FormattedDate date={dataDoc.date} />)}
            </td>
            <td />
            {/* Description */}
            <td colSpan={width} className="!p-0">
              <div className="flex">
                <div className="flex flex-1 space-x-4">
                  {_.times(indent / 25, (num) => (
                    <div
                      key={num}
                      className="border-l border-gray-300 py-2 text-gray-300 first:ml-4"
                    >
                      &rarr;
                    </div>
                  ))}
                  <div className="flex min-w-[100px] flex-1 space-x-2 p-2">
                    <div className="flex-1">
                      {linkTo(
                        dataDoc.mode === "Folder" ? (
                          <span>
                            {dataDoc.pile ? (
                              <>
                                <IconLayer />{" "}
                                <Badge label="Pile" mode="primary" />{" "}
                                {document.author}
                              </>
                            ) : (
                              <>
                                <IconFolder /> {document.author}
                              </>
                            )}
                          </span>
                        ) : (
                          author || "Missing Section Name"
                        )
                      )}
                    </div>
                    {!minimal && !primary.pile && (
                      <div className="text-right">
                        {counts ? (
                          <strong>
                            {counts.folders > 0 && (
                              <>
                                {plural("Subfolder", counts.folders, true)}{" "}
                                &bull;{" "}
                              </>
                            )}
                            {plural("File", counts.files, true)} &bull;{" "}
                            {plural("Page", counts.pages, true)}
                          </strong>
                        ) : (
                          <strong>
                            {primary.folderCount! > 0 && (
                              <>
                                {plural(
                                  "Subfolder",
                                  primary.folderCount!,
                                  true
                                )}{" "}
                                &bull;{" "}
                              </>
                            )}
                            {plural("File", primary.fileCount!, true)} &bull;{" "}
                            {plural("Page", primary.pageCount!, true)}
                          </strong>
                        )}
                        {document.hidden && (
                          <div>
                            <Badge label="Hidden on Client Access" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </td>
            {!minimal && primary.pile && (
              <td>
                <div className="text-right">
                  <PileProgress pile={primary.pile} tight />
                </div>

                {primary.pile.legacy &&
                slateHTMLIsBlank(primary.pile.summaryHtml) ? null : (
                  <div className="mt-2 font-normal">
                    <SlateViewer
                      html={primary.pile.summaryHtml}
                      emptyLabel={<em className="text-gray-400">No Summary</em>}
                    />
                  </div>
                )}
              </td>
            )}
            {!minimal && (
              <td
                colSpan={
                  1 +
                  (project?.billable ? 1 : 0) +
                  (project?.mode === "Overhead" ? 1 : 0)
                }
              />
            )}
          </>
        )}
        <td className="w-1 whitespace-nowrap text-right">
          {children}
          {onMoveUp && (
            <Link to={() => onMoveUp(primary.id)}>
              <IconUp fixed />
            </Link>
          )}
          {onMoveDown ? (
            <Link to={() => onMoveDown(primary.id)}>
              <IconDown fixed />
            </Link>
          ) : onMoveUp ? (
            <IconBlank />
          ) : null}
          {editable && document.mode === "Header" && (
            <Link to={() => setAddingFolder(true)}>
              <IconAdd /> Folder{" "}
            </Link>
          )}
          {editable && !onSelect && (
            <>
              {isFile && af?.upload && !document.employeeId && (
                <ShortLinkGenerator
                  mode="link"
                  href={af.upload.url}
                  attachedFile={af}
                />
              )}
              {!isFile && (
                <>
                  {!redirectFolders && primary.mode === "Folder" && (
                    <>
                      <Link to={{ search: `parentId=${primary.id}` }}>
                        <IconFolder /> Open
                      </Link>{" "}
                    </>
                  )}
                  <Link to={() => setEditing(true)}>
                    <IconEdit fixed />
                  </Link>
                </>
              )}
              {af?.upload && (
                <a
                  href={`${af.upload.url}${
                    primary.pageReference
                      ? `#page=${primary.pageReference}`
                      : ""
                  }`}
                  title="Download Attachment"
                >
                  <IconDownload fixed />
                </a>
              )}
              {document.mode === "File" && (
                <Link to={() => setEditing(true)}>
                  <IconEdit fixed />
                </Link>
              )}
            </>
          )}
          {onDelete &&
            !onSelect &&
            (primary.pile ? (
              <Link to={() => setDeleting(true)}>
                <IconTrash fixed />
              </Link>
            ) : (
              <Link to={() => onDelete(primary.id)}>
                <IconTrash fixed />
              </Link>
            ))}
          {onAdd && (
            <Button
              small
              disabled={disabled}
              mode={disabled ? "success" : "default"}
              onClick={() => onAdd(primary)}
            >
              {disabled ? "Added" : "Add"}
            </Button>
          )}
          {onSelect &&
            isFile &&
            (disabled ? (
              <Badge>Already Added</Badge>
            ) : (
              <input type="checkbox" onChange={toggle} checked={selected} />
            ))}
          {editing && (
            <HeaderForm id={document.id} onClose={() => setEditing(false)} />
          )}
          {addingFolder && (
            <HeaderForm
              onClose={() => setAddingFolder(false)}
              parent={document}
            />
          )}
          {deleting && (
            <ConfirmDelete
              message={`Are you sure you want to delete this pile?${
                primary.pile!.fileCount > 0
                  ? ` This will also delete the ${plural(
                      "file",
                      primary.pile!.fileCount,
                      true
                    )} in this pile.`
                  : ""
              }`}
              onClose={() => setDeleting(false)}
              fetcher={fetcherDelete}
              onConfirm={deletePile}
            />
          )}
        </td>
        {params.get("debug") && <td>{primary.path}</td>}
      </tr>
      {descriptionField && <DescriptionField source={primary} />}
    </>
  );
});
