import type {
  ComboBoxOption,
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type { TestLocationsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import { useMemo } from "react";
import Badge from "~/components/badge";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import DisplayNames from "~/utils/display-names";
import { normalizedNumber, plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export type TestingPickerPropsSingle = ComboBoxParentPropsSingle & {
  disableNoPhotos?: boolean;
  keyPhotoCount?: boolean;
  untagged?: boolean;
};
export type TestingPickerPropsMulti = ComboBoxParentPropsMultiple & {
  disableNoPhotos?: boolean;
  keyPhotoCount?: boolean;
  untagged?: boolean;
};

export default function TestingPicker({
  disableNoPhotos,
  keyPhotoCount,
  untagged,
  ...rest
}: TestingPickerPropsSingle | TestingPickerPropsMulti) {
  const project = useProject();
  const loader = useLoaderData<{
    testLocationPicker?: TestLocationsQuery;
  } | null>();
  const fetcher = useFetcherData<TestLocationsQuery>(
    loader?.testLocationPicker ? undefined : "/resources/test-locations/picker",
    { projectId: project.id }
  );

  const options = useMemo(() => {
    const sorted = _.sortBy(
      loader?.testLocationPicker?.testLocations ||
        fetcher.data?.testLocations ||
        [],
      (tl) => normalizedNumber(tl.number)
    );

    const options = sorted.map((item): ComboBoxOption => {
      const count = item.pfcsPhotoCount + item.otherPhotoCount;
      const disabled = disableNoPhotos && count === 0;
      return {
        value: item.id,
        rightLabel:
          keyPhotoCount && item.pfcsKeyPhotoCount + item.otherKeyPhotoCount ? (
            <Badge
              mode="primary"
              label={plural(
                "Key Photo",
                item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
                true
              )}
            />
          ) : undefined,
        label: `${DisplayNames.testLocation(item)}${
          disabled && disableNoPhotos ? " (No Photos)" : ""
        }`,
        disabled
      };
    });

    if (untagged) {
      options.push({
        value: "untagged",
        label: "*** Without Test Locations ***",
        disabled: false
      });
    }
    return options;
  }, [
    loader?.testLocationPicker?.testLocations,
    fetcher.data?.testLocations,
    untagged,
    disableNoPhotos,
    keyPhotoCount
  ]);

  return (
    <ComboBox
      placeholder="Select a Test Location..."
      {...rest}
      options={options}
    />
  );
}
