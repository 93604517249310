import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { config } from "@fortawesome/fontawesome-svg-core";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import {
  faTrashAlt,
  faImage,
  faCalendarAlt,
  faStar as faEmptyStar,
  faPaperPlane,
  faCircle,
  faCircleDot,
  faCircleXmark,
  faCheckCircle,
  faSquare,
  faCheckSquare,
  faMap,
  faEnvelope,
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faCopy,
  faEye,
  faEyeSlash,
  faBuilding,
  faArrowAltCircleRight,
  faFile,
  faChartBar,
  faCommentDots as faCommentDotsLight,
  faFolder as faFolderOutline,
  faClock
} from "@fortawesome/free-regular-svg-icons";
import {
  faPencilAlt,
  faPrint,
  faStar,
  faEllipsisH,
  faEllipsisV,
  faIndent,
  faOutdent,
  faListUl,
  faListOl,
  faUndoAlt,
  faExclamationTriangle,
  faTachometerAlt,
  faHome,
  faFolder,
  faLayerGroup,
  faCodeBranch,
  faCheck,
  faCircle as faCircleSolid,
  faDownload,
  faUpload,
  faSyncAlt,
  faUser,
  faUsers,
  faShareAlt,
  faFlag,
  faExternalLinkAlt,
  faLink,
  faCog,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faPlus,
  faReply,
  faSignOutAlt,
  faSpinner,
  faQuestionCircle,
  faSearch,
  faTimes,
  faBars,
  faPaperclip,
  faBook,
  faArrowsAlt,
  faExpandArrowsAlt,
  faExpandAlt,
  faLock,
  faUnlock,
  faSortAmountDownAlt,
  faDollarSign,
  faCut,
  faSitemap,
  faBold,
  faItalic,
  faUnderline,
  faRocket,
  faColumns,
  faCommentDots,
  faVideo,
  faRotateRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

config.autoAddCss = false;

interface Props extends Omit<FontAwesomeIconProps, "icon" | "fixedWidth"> {
  fixed?: boolean;
  large?: boolean;
}

export const IconBold = (props: Props) => <Icon name="bold" {...props} />;
export const IconClock = (props: Props) => <Icon name="clock" {...props} />;
export const IconComments = (props: Props) => (
  <Icon name="comments" {...props} />
);
export const IconCommentsLight = (props: Props) => (
  <Icon name="commentsLight" {...props} />
);
export const IconGoogleDrive = (props: Props) => (
  <Icon name="googleDrive" {...props} />
);
export const IconVideo = (props: Props) => <Icon name="video" {...props} />;
export const IconItalic = (props: Props) => <Icon name="italic" {...props} />;
export const IconUnderline = (props: Props) => (
  <Icon name="underline" {...props} />
);
export const IconPage = (props: Props) => <Icon name="page" {...props} />;
export const IconUser = (props: Props) => <Icon name="user" {...props} />;
export const IconUsers = (props: Props) => <Icon name="users" {...props} />;
export const IconOrgChart = (props: Props) => (
  <Icon name="orgChart" {...props} />
);
export const IconFlag = (props: Props) => <Icon name="flag" {...props} />;
export const IconRocket = (props: Props) => <Icon name="rocket" {...props} />;
export const IconCut = (props: Props) => <Icon name="cut" {...props} />;
export const IconMoney = (props: Props) => <Icon name="money" {...props} />;
export const IconSort = (props: Props) => <Icon name="sort" {...props} />;
export const IconFullScreen = (props: Props) => (
  <Icon name="fullScreen" {...props} />
);
export const IconLock = (props: Props) => <Icon name="lock" {...props} />;
export const IconUnlock = (props: Props) => <Icon name="unlock" {...props} />;
export const IconShare = (props: Props) => <Icon name="share" {...props} />;
export const IconMap = (props: Props) => <Icon name="map" {...props} />;
export const IconMove = (props: Props) => <Icon name="move" {...props} />;
export const IconCircleRight = (props: Props) => (
  <Icon name="circleRight" {...props} />
);
export const IconPrint = (props: Props) => <Icon name="print" {...props} />;
export const IconExpand = (props: Props) => <Icon name="expand" {...props} />;
export const IconFolder = (props: Props) => <Icon name="folder" {...props} />;
export const IconFolderOutline = (props: Props) => (
  <Icon name="folderOutline" {...props} />
);
export const IconCompany = (props: Props) => <Icon name="company" {...props} />;
export const IconBook = (props: Props) => <Icon name="book" {...props} />;
export const IconAttachment = (props: Props) => (
  <Icon name="attachment" {...props} />
);
export const IconCalendar = (props: Props) => (
  <Icon name="calendar" {...props} />
);
export const IconImage = (props: Props) => <Icon name="image" {...props} />;
export const IconBars = (props: Props) => <Icon name="bars" {...props} />;
export const IconEdit = (props: Props) => <Icon name="edit" {...props} />;
export const IconSearch = (props: Props) => <Icon name="search" {...props} />;
export const IconX = (props: Props) => <Icon name="x" {...props} />;
export const IconCopy = (props: Props) => <Icon name="copy" {...props} />;
export const IconTrash = (props: Props) => <Icon name="trash" {...props} />;
export const IconHelp = (props: Props) => <Icon name="help" {...props} />;
export const IconDashboard = (props: Props) => (
  <Icon name="dashboard" {...props} />
);
export const IconWarning = (props: Props) => <Icon name="warning" {...props} />;
export const IconHome = (props: Props) => <Icon name="home" {...props} />;
export const IconLogout = (props: Props) => <Icon name="logout" {...props} />;
export const IconReply = (props: Props) => <Icon name="reply" {...props} />;
export const IconStar = (props: Props) => <Icon name="star" {...props} />;
export const IconEmptyStar = (props: Props) => (
  <Icon name="emptyStar" {...props} />
);
export const IconDownload = (props: Props) => (
  <Icon name="download" {...props} />
);
export const IconUpload = (props: Props) => <Icon name="upload" {...props} />;
export const IconRefresh = (props: Props) => <Icon name="refresh" {...props} />;
export const IconSettings = (props: Props) => (
  <Icon name="settings" {...props} />
);
export const IconPDF = (props: Props) => <Icon name="pdf" {...props} />;
export const IconExcel = (props: Props) => <Icon name="excel" {...props} />;
export const IconPowerPoint = (props: Props) => (
  <Icon name="powerPoint" {...props} />
);
export const IconWord = (props: Props) => <Icon name="word" {...props} />;
export const IconAdd = (props: Props) => <Icon name="add" {...props} />;
export const IconCheck = (props: Props) => <Icon name="check" {...props} />;
export const IconExclamation = (props: Props) => (
  <Icon name="exclamation" {...props} />
);
export const IconEmail = (props: Props) => <Icon name="email" {...props} />;
export const IconLink = (props: Props) => <Icon name="link" {...props} />;
export const IconExternalLink = (props: Props) => (
  <Icon name="externalLink" {...props} />
);
export const IconUp = (props: Props) => <Icon name="up" {...props} />;
export const IconMerge = (props: Props) => <Icon name="merge" {...props} />;
export const IconLayer = (props: Props) => <Icon name="layer" {...props} />;
export const IconDown = (props: Props) => <Icon name="down" {...props} />;
export const IconLeft = (props: Props) => <Icon name="left" {...props} />;
export const IconRight = (props: Props) => <Icon name="right" {...props} />;
export const IconView = (props: Props) => <Icon name="view" {...props} />;
export const IconHide = (props: Props) => <Icon name="hide" {...props} />;
export const IconList = (props: Props) => <Icon name="list" {...props} />;
export const IconEllipsis = (props: Props) => (
  <Icon name="ellipsis" {...props} />
);
export const IconEllipsisV = (props: Props) => (
  <Icon name="ellipsisV" {...props} />
);
export const IconIndent = (props: Props) => <Icon name="indent" {...props} />;
export const IconOutdent = (props: Props) => <Icon name="outdent" {...props} />;
export const IconNumberedList = (props: Props) => (
  <Icon name="numberedList" {...props} />
);
export const IconKanban = (props: Props) => <Icon name="kanban" {...props} />;
export const IconMetrics = (props: Props) => <Icon name="metrics" {...props} />;
export const IconLoading = (props: Props) => <Icon name="loading" {...props} />;
export const IconRotate = (props: Props) => <Icon name="rotate" {...props} />;
export const IconRotateRight = (props: Props) => (
  <Icon name="rotateRight" {...props} />
);
export const IconDeliver = (props: Props) => <Icon name="deliver" {...props} />;
export const IconCircle = (props: Props) => <Icon name="circle" {...props} />;
export const IconCircleDot = (props: Props) => (
  <Icon name="circleDot" {...props} />
);
export const IconCircleXmark = (props: Props) => (
  <Icon name="circleXmark" {...props} />
);
export const IconCircleSolid = (props: Props) => (
  <Icon name="circleSolid" {...props} />
);
export const IconCheckedCircle = (props: Props) => (
  <Icon name="checkedCircle" {...props} />
);
export const IconSquare = (props: Props) => <Icon name="square" {...props} />;
export const IconCheckedSquare = (props: Props) => (
  <Icon name="checkedSquare" {...props} />
);
export const IconBlank = () => (
  <span style={{ display: "inline-block", height: "1em", width: "1.25em" }}>
    &nbsp;
  </span>
);

export const Icon = ({
  name,
  fixed,
  large,
  size,
  ...rest
}: { name: string } & Props) => {
  const props: Partial<FontAwesomeIconProps> = {
    size: large ? "lg" : size,
    fixedWidth: fixed,
    spin: name === "loading"
  };

  const map: { [key: string]: IconProp } = {
    metrics: faChartBar,
    clock: faClock,
    kanban: faColumns,
    edit: faPencilAlt,
    trash: faTrashAlt,
    print: faPrint,
    image: faImage,
    calendar: faCalendarAlt,
    star: faStar,
    emptyStar: faEmptyStar,
    list: faListUl,
    ellipsis: faEllipsisH,
    ellipsisV: faEllipsisV,
    indent: faIndent,
    outdent: faOutdent,
    numberedList: faListOl,
    rotate: faUndoAlt,
    rotateRight: faRotateRight,
    deliver: faPaperPlane,
    circle: faCircle,
    circleDot: faCircleDot,
    circleXmark: faCircleXmark,
    circleSolid: faCircleSolid,
    checkedCircle: faCheckCircle,
    square: faSquare,
    checkedSquare: faCheckSquare,
    exclamation: faExclamationTriangle,
    dashboard: faTachometerAlt,
    home: faHome,
    folder: faFolder,
    folderOutline: faFolderOutline,
    merge: faCodeBranch,
    layer: faLayerGroup,
    check: faCheck,
    comments: faCommentDots,
    commentsLight: faCommentDotsLight,
    googleDrive: faGoogleDrive,
    video: faVideo,
    download: faDownload,
    upload: faUpload,
    refresh: faSyncAlt,
    map: faMap,
    user: faUser,
    users: faUsers,
    email: faEnvelope,
    pdf: faFilePdf,
    excel: faFileExcel,
    word: faFileWord,
    powerPoint: faFilePowerpoint,
    share: faShareAlt,
    flag: faFlag,
    externalLink: faExternalLinkAlt,
    link: faLink,
    settings: faCog,
    up: faArrowUp,
    down: faArrowDown,
    left: faArrowLeft,
    right: faArrowRight,
    add: faPlus,
    reply: faReply,
    logout: faSignOutAlt,
    warning: faExclamationTriangle,
    loading: faSpinner,
    help: faQuestionCircle,
    copy: faCopy,
    search: faSearch,
    x: faTimes,
    bars: faBars,
    view: faEye,
    hide: faEyeSlash,
    attachment: faPaperclip,
    book: faBook,
    company: faBuilding,
    move: faArrowsAlt,
    fullScreen: faExpandArrowsAlt,
    expand: faExpandAlt,
    circleRight: faArrowAltCircleRight,
    lock: faLock,
    unlock: faUnlock,
    sort: faSortAmountDownAlt,
    money: faDollarSign,
    cut: faCut,
    rocket: faRocket,
    orgChart: faSitemap,
    bold: faBold,
    italic: faItalic,
    underline: faUnderline,
    page: faFile
  };

  return <FontAwesomeIcon icon={map[name]} {...props} {...rest} />;
};
