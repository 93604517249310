import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { SocialMediaPostPickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { addMonths, formatDate } from "~/utils/dates";
import { useFetcherData } from "~/utils/remix";

export interface SocialMediaPostPickerProps extends ComboBoxParentPropsSingle {
  excludeId?: string;
}
export default function SocialMediaPostPicker({
  excludeId,
  onChange,
  ...props
}: SocialMediaPostPickerProps) {
  const startDate = addMonths(new Date(), -3);
  const fetcher = useFetcherData<SocialMediaPostPickerQuery>(
    "/resources/social-media/post-picker",
    {
      startDate: formatDate(startDate, { format: "YYYY-MM-DD" }),
      includeId: (props.value as string) || undefined
    }
  );

  const options = useMemo(
    () =>
      _.sortBy(
        (fetcher.data?.socialMediaPosts || []).filter(
          (i) => excludeId !== i.id
        ),
        "date",
        "description",
        "id"
      ).map((post) => ({
        value: post.id,
        label: `${formatDate(post.date)} ${
          post.account.user?.fullname || "Company"
        } ${post.account.name}${
          post.description ? `: ${post.description}` : ""
        }`,
        full: post
      })),
    [fetcher.data, excludeId]
  );

  const handleChange = (name: string, value: string) => {
    const extra = value && options.find((o) => o.value === value)?.full;
    onChange?.(name, value, extra);
  };

  return (
    <ComboBox
      placeholder="Share a Post..."
      {...props}
      options={options}
      onChange={handleChange}
    />
  );
}
