import type { CSSProperties } from "react";
import type { LinkProps } from "~/components/link";
import clsx from "clsx";
import React from "react";
import Link, { useMatch } from "~/components/link";

interface Props extends LinkProps {
  label?: React.ReactNode;
  exact?: boolean;
  match?: boolean;
  style?: CSSProperties;
  matchPath?: string;
}

export default function ListLink({
  label,
  children,
  className = "",
  match: forceMatch,
  exact = true,
  matchPath,
  ...rest
}: Props) {
  const match = useMatch(matchPath, rest.to, exact);
  return (
    <Link
      className={clsx(
        "list-group-item",
        (forceMatch ?? match) && "active",
        className
      )}
      {...rest}
    >
      {label || children}
    </Link>
  );
}
