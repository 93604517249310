import type { Node, NodeEntry, Point } from "slate";
import type { CustomElement, MyEditor } from "~/components/rich-editor";
import _ from "lodash";
import { Editor, Element, Text, Transforms, Path, Range } from "slate";
import { isList } from "~/components/rich-editor/lists";

const isListNode = (node: Node) =>
  Element.isElement(node) &&
  ["ordered-list", "unordered-list"].includes(node.type as string);

export const insertSlateFragment = (
  editor: MyEditor,
  fragment: Node[],
  at?: Path | Point | Range
) => {
  // If there's a selection, delete it
  if (editor.selection && Range.isExpanded(editor.selection!)) {
    Transforms.delete(editor);
  }

  // Get the current block
  const point = editor.selection && Range.start(editor.selection!);
  const [[element, elementPath]] = Editor.nodes(editor, {
    match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
    at: point || at
  });

  // If the destination block is an empty non-list node, we'll want to insert
  // new nodes before the block and then remove the empty one. Otherwise,
  // we'll let Slate handle merging the fragments
  const replaceFirstNode =
    Editor.isEmpty(editor, element as Element) && !isList(editor);

  if (replaceFirstNode) {
    Transforms.insertNodes(editor, fragment, { at: elementPath });
  } else {
    const focus = editor.selection?.focus;
    // Slate defaults to replace an "empty" node if you paste into it. This breaks
    // pasting list items into new lines. Instead, we insert a placeholder character
    // and then delete it after the fragment is inserted.
    editor.insertText("#");
    // insert actual content of clipboard
    Transforms.insertFragment(editor, fragment, {
      at: at || undefined
    });
    // delete placeholder
    Transforms.delete(editor, {
      at: focus
    });
  }
  // Move to the end of the pasted data
  if (replaceFirstNode) {
    if (editor.selection) {
      Transforms.move(editor, { distance: 1, unit: "character" });
    }
    try {
      editor.deleteBackward("character");
    } catch (e) {
      // noop
    }
  }
};

export default function withNormalization(_editor: Editor) {
  const editor = _editor as MyEditor;
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]: NodeEntry) => {
    // Require paragraph after page break
    if (Editor.isEditor(node)) {
      const last = _.last(node.children);
      if (last && (last as CustomElement).type === "page-break") {
        Transforms.insertNodes(
          editor,
          {
            type: "paragraph",
            children: [{ text: "" }]
          },
          { at: [node.children.length] }
        );
        return;
      }
    }

    // Clean up text, newlines, etc
    if (Text.isText(node)) {
      const parent = Editor.parent(editor, path);

      // Make sure voids aren't flagged
      if (
        Element.isElement(parent[0]) &&
        editor.isVoid(parent[0]) &&
        node.highlight
      ) {
        Editor.withoutNormalizing(editor, () => {
          Transforms.setNodes(
            editor,
            { highlight: undefined },
            {
              at: path,
              voids: true
            }
          );
          return;
        });
      }

      // If the text node contains a new line, break apart the containing element
      const text = node.text.replace(/\n+/g, "\n");
      const index = text.indexOf("\n");
      if (index >= 0) {
        const parts = text
          .split(/\n/)
          // .map((t) => t.trim())
          .filter((t) => t);
        Editor.withoutNormalizing(editor, () => {
          Transforms.removeNodes(editor, { at: path });
          parts.reverse().forEach((part, index) => {
            Transforms.insertNodes(editor, { text: part }, { at: path });
            if (parts.length > 1 && index > 0) {
              Transforms.splitNodes(editor, { at: Path.next(path) });
              Transforms.move(editor, { distance: 1, unit: "line" });
            }
          });
        });
        return;
      }
    }

    // Fall back to default normalize node for all other non-elements
    if (!Element.isElement(node)) {
      normalizeNode([node, path]);
      return;
    }

    // Can't have empty links
    if (
      editor.isInline(node) &&
      node.type === "link" &&
      node.children.length === 1 &&
      node.children[0].text === ""
    ) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }

    // Void nodes need an empty paragraph after them
    if (
      path.length === 1 &&
      path[0] === editor.children.length - 1 &&
      editor.isVoid(node)
    ) {
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }]
        },
        { at: Path.next(path) }
      );
      return;
    }

    // Ensure lists have at least one list item
    if (["ordered-list", "unordered-list"].includes(node.type as string)) {
      const [parent, parentPath] = Editor.parent(editor, path);

      // If a list is the only child of a list-item, then this list's children
      // should just be merged into the parent list. Only do this if there is
      // no previous to the parent, otherwise further normalization will merge
      // these automatically.
      if (
        Element.isElement(parent) &&
        parent.type === "list-item" &&
        parent.children.length === 1 &&
        parentPath[parentPath.length - 1] === 0
      ) {
        Editor.withoutNormalizing(editor, () => {
          Transforms.unwrapNodes(editor, { at: path });
        });
        return;
      }

      // (un)ordered-list directly under an (un)ordered-list
      if (isListNode(parent)) {
        Transforms.wrapNodes(
          editor,
          { type: "list-item", children: [] },
          { at: path }
        );
        return;
      }

      const first = (node.children as CustomElement[])[0];
      if (first && first.type !== "list-item") {
        Transforms.setNodes(
          editor,
          { type: "list-item" },
          { at: path.concat(0) }
        );
        return;
      }

      const prev = Editor.previous(editor, { at: path });
      const next = Editor.next(editor, { at: path });
      let a: NodeEntry | null = null;
      let b: NodeEntry | null = null;
      if (prev && (prev[0] as CustomElement).type === node.type) {
        a = prev;
        b = [node, path];
      }
      if (next && (next[0] as CustomElement).type === node.type) {
        a = [node, path];
        b = next;
      }
      if (a && b) {
        const size = (a[0] as CustomElement).children.length;
        Editor.withoutNormalizing(editor, () => {
          (b![0] as CustomElement).children.forEach((_child, index) => {
            Transforms.moveNodes(editor, {
              at: b![1].concat(0),
              to: a![1].concat(size + index)
            });
          });
          Transforms.removeNodes(editor, { at: b![1] });
        });
        return;
      }
    }
    // Unwrap nested list-item-child nodes, and convert pasted list-item-childs not
    // in a list to paragraphs
    else if (node.type === "list-item-child") {
      const [parent] = Editor.parent(editor, path) as [CustomElement, Path];

      // Fix nested list-items
      if (
        node.children.length &&
        Element.isElement(node.children[0]) &&
        (node.children[0].type as string).startsWith("list-item")
      ) {
        Transforms.unwrapNodes(editor, { at: [...path, 0] });
        return;
      }
      // Fix nested list-item-childs
      if (parent.type === "list-item-child") {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }

      // Fix top-level list-item-childs
      if (Editor.isEditor(parent) || parent.type !== "list-item") {
        Transforms.setNodes(editor, { type: "paragraph" }, { at: path });
        return;
      }
      // });
    }
    // Clean up list item nesting
    else if (node.type === "list-item") {
      const [parent] = Editor.parent(editor, path) as [CustomElement, Path];
      // Nested list-items are usually from pasting
      if (parent && parent.type === "list-item") {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      } else if (parent && !isListNode(parent)) {
        if (node.children.length === 1 && isListNode(node.children[0])) {
          Editor.withoutNormalizing(editor, () => {
            Transforms.unwrapNodes(editor, {
              at: path
            });
          });
        } else {
          Editor.withoutNormalizing(editor, () => {
            Transforms.wrapNodes(
              editor,
              { type: "unordered-list", children: [] },
              { at: path }
            );
          });
        }
        return;
      }
      // No children, add a child
      if (
        node.children.length === 0
        // (node.children.length === 1 && isListNode(node.children[0]))
      ) {
        Transforms.insertNodes(
          editor,
          { type: "list-item-child", children: [{ text: "" }] },
          { at: path.concat(0) }
        );
        return;
        // Only a single nested list and no text, merge with previous item or outdent
      } else if (node.children.length === 1 && isListNode(node.children[0])) {
        Editor.withoutNormalizing(editor, () => {
          const prev = Editor.previous(editor, { at: path });
          if (prev) {
            Transforms.mergeNodes(editor, { at: path });
          } else {
            Transforms.moveNodes(editor, { at: path.concat(0), to: path });
            Transforms.removeNodes(editor, { at: Path.next(path) });
          }
        });
        return;
      }
      // Make sure all children are valid types
      (node.children as Node[]).forEach((child, index) => {
        if (Text.isText(child)) {
          Transforms.wrapNodes(
            editor,
            { type: "list-item-child", children: [] },
            { at: path.concat(index) }
          );
          return;
        }

        if (
          Element.isElement(child) &&
          ((index === node.children.length - 1 &&
            !["list-item-child", "ordered-list", "unordered-list"].includes(
              child.type as string
            )) ||
            (index < node.children.length - 1 &&
              child.type !== "list-item-child"))
        ) {
          Transforms.setNodes(
            editor,
            { type: "list-item-child" },
            { at: path.concat(index) }
          );
          return;
        }
      });
      // Now make sure we don't have more than 1 list-item-child. If we do, split
      // the node
      if (
        node.children.length > 1 &&
        node.children[1].type === "list-item-child"
      ) {
        if (isListNode(node.children[0])) {
          Transforms.moveNodes(editor, { at: path.concat(0), to: path });
          return;
        } else {
          Transforms.splitNodes(editor, { at: path.concat(1) });
          return;
        }
      } else if ((node.children[0] as CustomElement).type === "list-item") {
        Transforms.unwrapNodes(editor, { at: path.concat(0) });
        return;
      } else if (node.children[0].type !== "list-item-child") {
        Transforms.setNodes(
          editor,
          { type: "list-item-child" },
          { at: path.concat(0) }
        );
        return;
      }
    }
    // Take any paragraph children and push them into the list item child
    if (node.type === "paragraph") {
      const [parent] = Editor.parent(editor, path) as [CustomElement, Path];
      if (parent.type === "list-item-child") {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
    }
    // Rich Text
    if (node.type === "paragraph" || node.type === "list-item-child") {
      let index = node.children.length - 1;
      let changed = false;
      const reversed = [...node.children].reverse();
      for (const child of reversed) {
        if (Element.isElement(child) && !editor.isInline(child)) {
          Transforms.liftNodes(editor, { at: path.concat(index) });
          changed = true;
        }
        index--;
      }
      if (changed) {
        return;
      }
      // Old approach
      // const [item, itemPath] = Editor.parent(editor, path);
      // // if (!Editor.isEditor(item) || item.type) {
      // if (["list-item-child", "paragraph"].includes(item.type as string)) {
      //   Transforms.unwrapNodes(editor, { at: itemPath });
      //   return;
      // }
    }
    normalizeNode([node, path]);
  };
  return editor;
}
