import _ from "lodash";
import { useContext } from "react";
import invariant from "tiny-invariant";
import Alert from "~/components/alert";
import { IconExclamation } from "~/components/icons";
import { RemixFormContext } from "~/contexts";
import { usePresence } from "~/utils/hooks";

type Props = {
  footer?: boolean;
  room: string;
};

export default function FormStatus({ footer, room }: Props) {
  const form = useContext(RemixFormContext);
  invariant(form, "RemixFormContext is required");
  const presences = usePresence(room, form.dirty);
  const dirtyByMe = presences.mine.filter((p) => p.dirty).length > 0;
  const others = _.uniq(
    presences.others.filter((p) => p.dirty).map((p) => p.fullname)
  );

  if (!dirtyByMe && !others.length) {
    return null;
  }
  return footer ? (
    <div className="min-w-[300px]">
      {dirtyByMe && (
        <big className="text-danger font-bold">
          <IconExclamation /> You have unsaved changes to this page in another
          tab/window!
        </big>
      )}
      {others.length > 0 && (
        <big className="text-danger font-bold">
          <IconExclamation /> {others.join(" and ")}{" "}
          {others.length === 1 ? "has" : "have"} unsaved changes to this page.
        </big>
      )}
    </div>
  ) : (
    <div>
      {dirtyByMe && (
        <Alert mode="danger">
          <IconExclamation /> You have unsaved changes to this page in another
          tab/window!
        </Alert>
      )}
      {others.length > 0 && (
        <Alert mode="danger">
          <IconExclamation /> {others.join(" and ")}{" "}
          {others.length === 1 ? "has" : "have"} unsaved changes to this page.
        </Alert>
      )}
    </div>
  );
}
