import type { ComboBoxParentProps } from "~/components/combobox";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { addMinutes, formatDate } from "~/utils/dates";

export default function TimePicker(props: ComboBoxParentProps) {
  const options = useMemo(() => {
    let date = new Date(2020, 0, 1, 0, 0, 0);
    const options = [];
    while (date.getDate() === 1) {
      options.push({
        value: formatDate(date, { format: "HH:mm:ss" }),
        label: formatDate(date, { format: "h:mm a" })
      });
      date = addMinutes(date, 30);
    }
    return options;
  }, []);

  return (
    <ComboBox options={options} placeholder="Choose a time..." {...props} />
  );
}
