import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";

export const MARKETING_STATUS_OPTIONS = [
  [
    "Name",
    "Name",
    "Imported contact or someone in our system we haven't reviewed/touched yet. As soon as *any* action is performed on a contact, they should be moved to one of the other statuses."
  ],
  [
    "Suspect",
    "Suspect",
    "Anyone who could possibly utilize our services until we dig deeper and qualify – in other words, we suspect that they use experts like PFCS."
  ],
  [
    "Not A Prospect",
    "Not A Prospect",
    "Vendors, organization contacts (they could move into one of the above categories after an introduction)"
  ],
  ["Marketing Contact", "Marketing Contact", "Industry colleagues/contacts"],
  [
    "Influencer",
    "Influencer",
    "An individual who is well connected and a great referral source"
  ],
  [
    "Prospect",
    "Prospect",
    "An individual/company that we see as a potential client who can utilize our services; we have direct knowledge of their interest in working with PFCS or have confirmed that they use experts like PFCS."
  ],
  [
    "Prospect - Whale",
    "Prospect - Whale",
    "An individual/company that could change our playing field"
  ]
];

const opts = MARKETING_STATUS_OPTIONS.map((o) => ({
  value: o[0],
  label: o[0],
  subtitle: o[2]
}));

export default function MarketingStatusPicker({
  placeholder = "Select a status...",
  ...rest
}: ComboBoxParentProps) {
  return <ComboBox {...rest} placeholder={placeholder} options={opts} />;
}
