import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { AffiliationTypesQuery } from "~/types/api";
import _ from "lodash";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export default function AffiliationTypePicker(
  props: ComboBoxParentPropsSingle
) {
  const fetcher = useFetcherData<AffiliationTypesQuery>(
    "/resources/affiliation-types/picker"
  );

  const affiliationTypes = fetcher.data?.affiliationTypes || [];
  const options = _.sortBy(affiliationTypes, "name").map((at) => ({
    value: at.id,
    label: at.name
  }));

  const onChange = (name: string, value: string) => {
    const match = affiliationTypes.find((at) => at.id === value);
    props.onChange?.(name, value, match);
  };

  return (
    <ComboBox
      placeholder="Select a Type..."
      {...props}
      onChange={onChange}
      options={options}
    />
  );
}
