import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type { CampaignFieldsQuery } from "~/types/api";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export type CampaignFieldPickerPropsSingle = ComboBoxParentPropsSingle & {
  projectId?: string;
};
export type CampaignFieldPickerPropsMulti = ComboBoxParentPropsMultiple & {
  projectId?: string;
};

// const MultiValue = ({ children, ...props }: MultiValueProps<OptionType>) => {
//   return (
//     <components.MultiValue {...props}>
//       {_.truncate(children as string, { length: 25 })}
//     </components.MultiValue>
//   );
// };

// const COMPONENTS = { MultiValue };

export default function CampaignFieldPicker({
  projectId,
  ...rest
}: CampaignFieldPickerPropsSingle | CampaignFieldPickerPropsMulti) {
  const fetcher = useFetcherData<CampaignFieldsQuery>(
    projectId ? "/resources/campaigns/field-picker" : undefined,
    { id: projectId }
  );

  const options = useMemo(() => {
    return (fetcher.data?.project.campaignFields || [])
      .filter((cf) => cf.mode === "Static")
      .map((cf) => ({
        value: cf.name,
        label: `${cf.name}${cf.description ? `: ${cf.description}` : ""}`
      }));
  }, [fetcher.data]);

  const prompt = projectId
    ? rest.placeholder ||
      (rest.multiple ? "Select some fields..." : "Select a field...")
    : "Select a campaign first...";

  return (
    <ComboBox
      {...rest}
      options={options}
      placeholder={prompt}
      // components={COMPONENTS}
    />
  );
}
