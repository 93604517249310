import type { ComboBoxParentProps } from "~/components/combobox";
import type { FeeSchedulesQuery } from "~/types/api";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { formatDate } from "~/utils/dates";
import { useFetcherData } from "~/utils/remix";

export default function FeeSchedulePicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<FeeSchedulesQuery>(
    "/resources/fee-schedules/picker"
  );

  const options = useMemo(
    () =>
      _.orderBy(fetcher.data?.feeSchedules, "effectiveDate", "desc").map(
        (fs) => ({
          value: fs.id,
          label: `${formatDate(fs.effectiveDate)}: ${fs.name}`
        })
      ),
    [fetcher.data]
  );

  return <ComboBox {...props} options={options} />;
}
