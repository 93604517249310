import _ from "lodash";
import { useState } from "react";
import ButtonGroup from "~/components/button-group";
import ButtonOption from "~/components/button-select-option";

export interface ButtonSelectProps {
  value?: string;
  defaultValue?: string;
  name: string;
  onChange?: (name: string, value: string) => void;
  options: (string | string[])[];
}

export default function ButtonSelect({
  value,
  name,
  onChange,
  defaultValue,
  options
}: ButtonSelectProps) {
  const handleClick = (option: string) => {
    setLocalValue(option);
    onChange?.(name, option);
  };

  const [localValue, setLocalValue] = useState(defaultValue || value);

  return (
    <>
      <ButtonGroup>
        {options.map((option) => (
          <ButtonOption
            key={_.isArray(option) ? option[0] : option}
            option={option}
            onClick={handleClick}
            className="px-4"
            selected={
              (value || localValue) === (_.isArray(option) ? option[0] : option)
            }
          />
        ))}
      </ButtonGroup>
      <input type="hidden" name={name} value={value || localValue} />
    </>
  );
}
