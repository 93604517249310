import type { Node } from "slate";
import type { RenderElementProps } from "slate-react";
import type { ModalProps } from "~/components/modal";
import type {
  ImageElement,
  MyEditor,
  PhotoElement
} from "~/components/rich-editor";
import type { SaveAttachmentMutation, ViewOnePhotoQuery } from "~/types/api";
import clsx from "clsx";
import { useState } from "react";
import { Editor, Transforms, Range, Element } from "slate";
import {
  useSlate,
  useSelected,
  useFocused,
  ReactEditor,
  useEditor
} from "slate-react";
import { v1 as uuidv1 } from "uuid";
import Alert from "~/components/alert";
import { createUploadAndNotify } from "~/components/file-uploader";
import { IconImage, IconLoading } from "~/components/icons";
import Modal from "~/components/modal";
import { Buttons, Input, RemixForm } from "~/components/remix-form";
import { useOptionalProject } from "~/contexts";
import { photoNumber } from "~/utils/photos";
import { useFetcherData } from "~/utils/remix";

const insertVoidNode = (editor: MyEditor, node: Node, range: Range) => {
  const point = Range.start(range);
  if (Range.isExpanded(range)) {
    Transforms.delete(editor, { at: range });
  }
  const [element] = Editor.nodes(editor, {
    at: point,
    match: (node) => Element.isElement(node) && Editor.isBlock(editor, node)
  });

  if (
    Element.isElement(element[0]) &&
    Editor.isBlock(editor, element[0]) &&
    Editor.isEmpty(editor, element[0])
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Transforms.setNodes(editor, node as any, { at: point });
  } else {
    Transforms.insertNodes(editor, node, { at: point });
  }
};
export const withImages = (editor: MyEditor) => {
  const { isVoid, insertData } = editor;

  editor.isVoid = (element) =>
    ["pfcs-photo", "image"].includes(element.type as string)
      ? true
      : isVoid(element);

  editor.insertData = async (data) => {
    if (data.files.length) {
      const images = Array.from(data.files)
        .filter((f) => f.type.split("/")[0] === "image")
        .map((f) => ({ file: f, uuid: uuidv1() }));

      // Create the upload placeholders first
      for (const file of images) {
        const newNode = {
          type: "image",
          pending: file.file.name,
          uuid: file.uuid,
          children: [{ text: "" }]
        } as ImageElement;
        insertVoidNode(editor, newNode, editor.selection!);
        Transforms.move(editor, { distance: 1, unit: "line" });
      }

      // Then upload all of the files asynchronously
      const promises = images.map(async ({ file, uuid }) => {
        // Get presigned url and upload file to S3
        const id = await createUploadAndNotify(file, { embedded: true });

        // Link it to an Attachment item
        const fd = new FormData();
        fd.append("uploadId", id);
        const result = (await (
          await fetch("/resources/attachments/save", {
            body: fd,
            method: "post"
          })
        ).json()) as SaveAttachmentMutation;

        // Find the placeholder and update it with the url
        const [element] = Editor.nodes(editor, {
          at: [],
          match: (node) =>
            Element.isElement(node) &&
            Editor.isBlock(editor, node) &&
            node.type === "image" &&
            node.uuid === uuid
        });
        if (element) {
          Transforms.setNodes(
            editor,
            {
              pending: undefined,
              uuid: undefined,
              url: result.saveAttachment.upload!.url
            },
            { at: element[1] }
          );
        }
      });
      await Promise.all(promises);
      return;
    }
    insertData(data);
  };

  return editor;
};

export const Image = (props: RenderElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  const editor = useEditor() as MyEditor;
  const [editing, setEditing] = useState(false);
  const element = props.element as ImageElement;
  return (
    <div {...props.attributes}>
      <div contentEditable={false}>
        {element.pending ? (
          <em className="text-gray-500">
            <IconLoading /> Uploading {element.pending}...
          </em>
        ) : (
          <img
            onDoubleClick={editor.readOnly ? undefined : () => setEditing(true)}
            src={element.url}
            className={clsx(
              selected && focused && "focused",
              editor.readOnly && "cursor-pointer"
            )}
            onClick={
              editor.readOnly
                ? () => window.open(element.url, "_blank")
                : undefined
            }
          />
        )}
      </div>
      {editing && (
        <PFCSPhotoForm element={element} onClose={() => setEditing(false)} />
      )}
      {props.children}
    </div>
  );
};

type PFCSPhotoFormProps = {
  range?: Range;
  onClose: () => void;
  element?: Element;
};

const PFCSPhotoForm = ({ range, onClose, element }: PFCSPhotoFormProps) => {
  const editor = useSlate() as MyEditor;
  const [url, setUrl] = useState(element?.type === "image" ? element.url : "");
  const [id, setId] = useState(
    element?.type === "pfcs-photo" ? element.id : ""
  );
  const project = useOptionalProject();

  const insert = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newNode = url
      ? ({
          type: "image",
          url: url,
          children: [{ text: "" }]
        } as ImageElement)
      : ({
          type: "pfcs-photo",
          id: id,
          children: [{ text: "" }]
        } as PhotoElement);
    if (element) {
      const path = ReactEditor.findPath(editor, element);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Transforms.setNodes(editor, newNode as any, { at: path });
    } else if (range) {
      insertVoidNode(editor, newNode, range);
    }
    setUrl("");
    setId("");
    onClose();
  };

  // const update = (next: Input, field: string, value?: unknown) => {
  //   form.setData(
  //     produce(next, (draft) => {
  //       if (field === "url" && value) {
  //         draft.id = "";
  //       } else if (field === "id" && value) {
  //         draft.url = "";
  //       }
  //     })
  //   );
  // };

  const gmailUrl = url && url.match(/^https:\/\/mail.google.com\//);
  const validUrl = url && !gmailUrl;
  return (
    <Modal size="Large" onClose={onClose}>
      <Modal.Header title="Insert Image" />
      <RemixForm onSubmit={insert}>
        <Modal.Body>
          <Input
            name="url"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              if (e.target.value) {
                setId("");
              }
            }}
            label="Image URL"
            placeholder="Paste a direct URL to an image..."
          />
          {gmailUrl && (
            <Alert mode="danger">
              You can't use URLs from Gmail. They only work when you are signed
              into your Google account. Other staff won't be able to view the
              images and it will also break PDFing. If you need to use an image
              from your inbox, download the photo to your desktop instead and
              drag it into the editor.
            </Alert>
          )}
          {project && (
            <Input
              name="id"
              type="photo"
              value={id}
              onChange={(_name, value) => {
                setId(value);
                if (value) {
                  setUrl("");
                }
              }}
              label="Investigation Photo"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Buttons
            modal
            disabled={!validUrl && !id}
            primaryLabel={element ? "Update" : "Insert"}
          />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};

export const PFCSPhotoButton = () => {
  const editor = useSlate();
  const [range, setRange] = useState<Range>();
  const insertPFCSPhoto = () => {
    if (editor.selection) {
      setRange(editor.selection);
    }
  };

  return (
    <>
      <span
        className={editor.selection ? "cursor-pointer" : ""}
        onMouseDown={(e) => {
          e.stopPropagation();
          insertPFCSPhoto();
        }}
      >
        <IconImage fixed />
      </span>
      {range && (
        <PFCSPhotoForm range={range} onClose={() => setRange(undefined)} />
      )}
    </>
  );
};

export const PFCSPhoto = (props: RenderElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  const editor = useEditor() as MyEditor;
  const element = props.element as PhotoElement;
  const [editing, setEditing] = useState(false);

  const fetcher = useFetcherData<ViewOnePhotoQuery>(
    `/resources/photos/${element.id}/viewer-one`
  );

  return (
    <div {...props.attributes} className="mb-6">
      <div contentEditable={false}>
        {fetcher.data ? (
          <div
            className="pfcs-slate-photo"
            data-id={element.id}
            onDoubleClick={editor.readOnly ? undefined : () => setEditing(true)}
          >
            <img
              className={clsx(
                selected && focused && "focused",
                editor.readOnly && "cursor-pointer",
                "with-caption"
              )}
              src={fetcher.data.photo.upload?.thumbUrl || ""}
              style={{
                boxShadow:
                  selected && focused ? "0 0 0 2px blue !important" : undefined,
                ...(editor.readOnly ? { cursor: "pointer" } : {})
              }}
            />
            <span className="caption">
              {photoNumber(fetcher.data.photo)}{" "}
              {fetcher.data.photo.finalAnnotation}
            </span>
          </div>
        ) : (
          <IconLoading />
        )}
      </div>
      {editing && (
        <PFCSPhotoForm element={element} onClose={() => setEditing(false)} />
      )}
      {props.children}
    </div>
  );
};

interface PhotoSettingsFormProps extends ModalProps {
  editor: Editor;
  path: number[];
}

export const PhotoSettingsForm = ({
  editor,
  path,
  onClose
}: PhotoSettingsFormProps) => {
  const image = Editor.node(editor, path)[0] as ImageElement;

  const [size, setSize] = useState(image.size || "Default");
  const [width, setWidth] = useState(image.width || "");

  const save = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newSize = size === "Default" ? undefined : size;
    Transforms.setNodes(
      editor,
      { size: newSize, width: size === "Custom" ? width : undefined },
      { at: path }
    );
    onClose();
  };

  return (
    <Modal onExplicitClose={onClose}>
      <RemixForm onSubmit={save}>
        <Modal.Header title="Image Settings" />
        <Modal.Body>
          <Input
            name="size"
            type="combo"
            value={size}
            onChange={(_name, value) => setSize(value)}
            options={[
              [
                "Default (expand to fit width, max height of 3-1/3 inches)",
                "Default"
              ],
              [
                "Full Width (expand to fit full width/height of page)",
                "Full Width"
              ],
              ["Original (no resizing)", "Original"],
              ["Custom (enter width in inches)", "Custom"]
            ]}
            isClearable={false}
          />
          {size === "Custom" && (
            <Input
              name="width"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              label="Width (inches)"
              placeholder="Enter width..."
              isClearable={false}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
