import type { ReactNode } from "react";
import clsx from "clsx";
import { formatNumber } from "~/utils/formatting";

interface Props {
  percentage: number;
  className?: string;
  label?: ReactNode;
  color?: string;
  progressColor?: string;
}

export default function ProgressBar({
  percentage,
  className,
  label,
  color,
  progressColor
}: Props) {
  if (percentage > 99 && percentage < 100) {
    percentage = 99;
  }
  return (
    <div
      className={clsx(
        "h-8 w-full rounded-full border border-gray-300 bg-gray-100 shadow-sm",
        className
      )}
    >
      <div
        className={clsx(
          "h-full rounded-full",
          percentage === 0
            ? ""
            : percentage === 100
              ? color || "bg-green-500"
              : progressColor || "bg-blue-500"
        )}
        style={{
          transition: "width 0.25s ease, background-color 0.25s ease",
          minWidth: "4rem",
          width: `${percentage}%`
        }}
      >
        <span className="flex h-full items-center">
          <span
            className={clsx(
              percentage !== 0 && "text-white",
              "flex w-full justify-center"
            )}
          >
            {label || (
              <>{formatNumber(percentage, { zeroes: true, format: "0" })}%</>
            )}
          </span>
        </span>
      </div>
    </div>
  );
}
