import type { PropsWithChildren } from "react";
import type { UploadFieldsFragment } from "~/types/api";
import clsx from "clsx";
import React from "react";
import EditableThumb from "~/components/editable-thumb";

interface Props extends PropsWithChildren<unknown> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitleBelow?: React.ReactNode;
  childrenBelow?: React.ReactNode;
  small?: boolean;
  wrap?: boolean;
  className?: string;
  photo?: UploadFieldsFragment | null;
  editablePhoto?: boolean;
}

export default function Heading({
  title,
  subtitle,
  subtitleBelow,
  className = "",
  small,
  wrap,
  children,
  childrenBelow,
  photo,
  editablePhoto
}: Props) {
  const Tag = small ? "h4" : "h3";

  return (
    <>
      <div
        className={clsx(
          "mb-4 flex flex-wrap items-center justify-between gap-4 border-b border-gray-200 pb-4",
          small ? "mt-0" : "mt-4",
          className
        )}
      >
        {photo?.thumbUrl && (
          <EditableThumb upload={photo} editable={editablePhoto} />
        )}
        <div className="flex-1">
          <Tag className="m-0 flex-1">
            {title}
            {subtitle && (
              <>
                {" "}
                <span className="text-2xl text-gray-500 print:mt-4 print:block">
                  {subtitle}
                </span>
              </>
            )}
          </Tag>
          {subtitleBelow && (
            <div className="mt-4 text-gray-600">{subtitleBelow}</div>
          )}
          {childrenBelow && (
            <div className="mt-4 flex flex-wrap items-center gap-2">
              {childrenBelow}
            </div>
          )}
        </div>
        <div
          className={clsx(
            "flex items-center justify-end gap-2",
            // Prevent button jump
            small && "min-h-[28px] print:min-h-0",
            wrap && "flex-wrap"
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
}
