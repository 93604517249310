import type { RemixLinkProps } from "@remix-run/react/dist/components";
import type { Location, To } from "react-router-dom";
import { Link as RRLink } from "@remix-run/react";
import _ from "lodash";
import { useCallback, useContext } from "react";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import invariant from "tiny-invariant";
import { NavContext } from "~/contexts";

export interface LinkProps extends Omit<RemixLinkProps, "to"> {
  to?:
    | To
    | { append: Record<string, string | undefined> }
    | (() => void)
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void);
  disabled?: boolean;
  external?: boolean;
}

export const useMatch = (
  path: string | undefined,
  to: LinkProps["to"],
  exact = true
) => {
  const location = useLocation();
  if (_.isFunction(to)) return false;
  let adjustedPath =
    path ||
    (_.isObject(to) && "append" in to
      ? location.pathname
      : _.isObject(to) && "pathname" in to
        ? to.pathname
        : (to as string));
  if (!adjustedPath) {
    return false;
  }
  adjustedPath = exact ? adjustedPath : `${adjustedPath}/*`;
  return !!matchPath(adjustedPath, location.pathname);
};

export const useLocationPusher = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (
      args: Record<string, string | number | boolean | string[] | undefined>,
      options: {
        reset?: boolean;
        replace?: boolean;
        state?: unknown;
        pathname?: string;
        arrayBrackets?: boolean;
      } = {}
    ) => {
      const pathname = options.pathname || location.pathname;
      const search = stringifyParams(args, {
        arrayBrackets: options.arrayBrackets,
        init: options.reset ? undefined : location.search
      });
      console.log({ search, pathname, x: `?${search}` });
      navigate(`${pathname}${search ? `?${search}` : ""}`, {
        replace: options.replace,
        state: options.state
      });
    },
    [navigate, location]
  );
};

export default function Link({ external, state, ...props }: LinkProps) {
  const { disabled, onClick } = props;
  const context = useContext(NavContext);
  const location = useLocation();
  const handleFunc = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (!disabled && _.isFunction(props.to)) {
      props.to(event);
    }
  };

  const { children, ...allProps } = props;

  // Setting a target will make React Router treat it like a normal <a ... /> link
  if (context.stale && !allProps.target) {
    allProps.target = "_top";
  }
  const { to, ...rest } = allProps;

  if (_.isFunction(to)) {
    return (
      <a
        href="#"
        {...rest}
        onClick={(e) => {
          // @headlessui/react passes it's own onClick handler that we need to call
          props.onClick?.(e);
          handleFunc(e);
        }}
      >
        {children}
      </a>
    );
  }
  if (external)
    return (
      <a
        {...rest}
        href={to as string}
        onClick={disabled ? handleFunc : onClick}
      >
        {children}
      </a>
    );

  invariant(to, "Link must have a `to` prop");
  return (
    <RRLink
      to={
        _.isObject(to) && "append" in to
          ? appendParams(location, to.append)
          : to!
      }
      {...rest}
      state={state}
      onClick={disabled ? handleFunc : onClick}
    >
      {children}
    </RRLink>
  );
}
/**
 * Takes an object and creates a URLSearchParams string. If the value is an array
 * it will append the key for each value in the array.
 * @param params Object with key value pairs
 * @returns URLSearchParams string
 */
export const stringifyParams = (
  params: Record<string, string | number | boolean | string[] | undefined>,
  opts: { arrayBrackets?: boolean; init?: URLSearchParams | string } = {}
) => {
  const searchParams = new URLSearchParams(opts.init);
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      // Delete all values for this key first
      searchParams.delete(key);

      value.forEach((v) => {
        searchParams.append(opts.arrayBrackets ? `${key}[]` : key, v);
      });
    } else {
      if (value === undefined) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value.toString());
      }
    }
  }
  return searchParams.toString();
};

export const appendParams = (
  location: Location,
  params: Record<string, string | undefined>
) => {
  const searchParams = new URLSearchParams(location.search);
  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
  }
  return `${location.pathname}?${searchParams.toString()}`;
};
