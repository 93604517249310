import type {
  ContactSearchQuery,
  ContactSearchContactFieldsFragment,
  ContactPickerFieldsFragment
} from "~/types/api";
import React, { useRef, useReducer, useState } from "react";
import ReactPaginate from "react-paginate";
import Alert from "~/components/alert";
import Button from "~/components/button";
import ButtonCloseModal from "~/components/button-close-modal";
import AddCompany from "~/components/contacts/add-company";
import {
  IconEdit,
  IconTrash,
  IconAdd,
  IconLoading,
  IconCompany
} from "~/components/icons";
import Link from "~/components/link";
import Modal from "~/components/modal";
import NumberedTable from "~/components/numbered-table";
import { Input } from "~/components/remix-form";
import { ClientOnly, useFetcherData } from "~/utils/remix";

export interface ContactPickerProps {
  value?: ContactPickerFieldsFragment | null;
  mode?: "Company" | "Contact";
  onChange(name: string, value: ContactPickerFieldsFragment | null): void;
  name: string;
  compact?: boolean;
  isClearable?: boolean;
}

type State = {
  selecting: boolean;
  adding: boolean;
  query: string;
  executeQuery: string;
};

type Action =
  | {
      type: "select" | "stopSelecting" | "addContact" | "stopAdding" | "search";
    }
  | { type: "setQuery"; value: string };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "select":
      return { ...state, selecting: true };
    case "stopSelecting":
      return { ...state, selecting: false, query: "", executeQuery: "" };
    case "addContact":
      return { ...state, selecting: false, adding: true };
    case "stopAdding":
      return { ...state, query: "", executeQuery: "", adding: false };
    case "search":
      return { ...state, executeQuery: state.query };
    case "setQuery":
      return { ...state, query: action.value };
    default:
      throw new Error();
  }
};

export default function ContactPicker({
  value,
  onChange,
  name,
  mode,
  compact,
  isClearable = true
}: ContactPickerProps) {
  const [state, dispatch] = useReducer(reducer, {
    selecting: false,
    adding: false,
    query: "",
    executeQuery: ""
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const select = () => {
    dispatch({ type: "select" });
  };

  const deselect = () => onChange(name, null);

  const stopSelecting = () => dispatch({ type: "stopSelecting" });

  const addContact = () => dispatch({ type: "addContact" });

  const stopAdding = () => dispatch({ type: "stopAdding" });

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch({ type: "search" });
  };

  const updateQuery = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "setQuery", value: event.target.value });

  const handleChange = (company: ContactPickerFieldsFragment) => {
    onChange(name, company);
    dispatch({ type: "stopSelecting" });
  };

  const contact = value;
  return (
    <div>
      <div className="flex">
        <div
          className={
            contact
              ? "form-control flex-1 truncate"
              : "form-control flex-1 cursor-pointer"
          }
          style={{
            padding: "4px 6px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          onClick={contact ? undefined : select}
        >
          {contact ? (
            <Link to={`/contacts/${contact.id}`}>
              {contact.informalDisplayAs}
              {contact.company && ` / ${contact.company.name}`}
            </Link>
          ) : (
            <em>Choose a {mode || "Contact or Company"}...</em>
          )}
        </div>
        {contact && isClearable && (
          <Button
            small
            onClick={deselect}
            style={{ borderRadius: 0, marginLeft: -1 }}
          >
            <IconTrash />
            {!compact && " Remove"}
          </Button>
        )}

        <Button
          small
          onClick={select}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            marginLeft: -1
          }}
        >
          <IconEdit />
          {!compact && " Change..."}
        </Button>
      </div>

      {(state.selecting || state.adding) && (
        <Modal
          onClose={state.selecting ? stopSelecting : stopAdding}
          initialFocus={inputRef}
        >
          {state.adding && (
            <AddCompany
              onClose={stopAdding}
              onSave={handleChange}
              prefill={state.query}
            />
          )}
          {state.selecting && (
            <>
              <Modal.Header title={`Find a ${mode || "Contact or Company"}`} />
              <form onSubmit={search} autoComplete="off">
                <Modal.Body>
                  <Input
                    name="query"
                    forwardRef={inputRef}
                    noLabel
                    placeholder={`Search for a ${(
                      mode || "Contact or Company"
                    ).toLowerCase()}...`}
                    value={state.query}
                    onChange={updateQuery}
                    wrapperStyle={{ marginBottom: 0 }}
                  />
                </Modal.Body>
                {state.executeQuery && (
                  <List
                    mode={mode}
                    // Force it to reset with a new query
                    key={state.executeQuery}
                    search={state.executeQuery}
                    onChange={handleChange}
                  />
                )}
                <Modal.Footer>
                  <ButtonCloseModal>Cancel</ButtonCloseModal>
                  {state.executeQuery && mode === "Company" && (
                    <Button mode="success" onClick={addContact}>
                      <IconAdd /> Add a Company
                    </Button>
                  )}
                  <Button type="submit" mode="primary">
                    Search
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Modal>
      )}
    </div>
  );
}

interface ListProps {
  search: string;
  onChange(company: ContactPickerFieldsFragment): void;
  mode?: "Company" | "Contact";
}

const List = ({ search, onChange, mode }: ListProps) => {
  const [page, setPage] = useState(1);
  const fetcher = useFetcherData<ContactSearchQuery>(
    "/resources/contacts/picker",
    {
      mode,
      query: search,
      page
    }
  );

  if (fetcher.state !== "idle" || !fetcher.data?.contactSearch)
    return (
      <Modal.Body className="pt-0">
        <IconLoading />
      </Modal.Body>
    );
  if (!fetcher.data.contactSearch.contacts.length)
    return (
      <Modal.Body className="pt-0">
        <Alert mode="warning" className="mb-0">
          No matches found!
        </Alert>
      </Modal.Body>
    );

  const totalCount =
    fetcher.data.contactSearch.filteredContactCount +
    fetcher.data.contactSearch.filteredCompanyCount;
  const pagination = totalCount > 250 && (
    <ClientOnly>
      {() => (
        <ReactPaginate
          pageCount={Math.ceil(totalCount / 250)}
          pageRangeDisplayed={3}
          hrefBuilder={() => "#"}
          containerClassName={"pagination"}
          marginPagesDisplayed={1}
          previousLabel="Prev"
          pageLinkClassName="tight"
          breakLinkClassName="tight"
          previousLinkClassName="tight"
          nextLinkClassName="tight"
          forcePage={page - 1}
          onPageChange={({ selected }) => setPage(selected + 1)}
          activeClassName={"active"}
        />
      )}
    </ClientOnly>
  );
  return (
    <>
      <NumberedTable offset={(page - 1) * 250}>
        <thead>
          <tr>
            <th />
            {mode !== "Company" && <th>Contact</th>}
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {fetcher.data.contactSearch.contacts.map((c) => (
            <Row searchMode={mode} contact={c} key={c.id} onChange={onChange} />
          ))}
        </tbody>
      </NumberedTable>
      {pagination && <Modal.Body>{pagination}</Modal.Body>}
    </>
  );
};

interface RowProps {
  onChange(company: ContactPickerFieldsFragment): void;
  contact: ContactSearchContactFieldsFragment;
  searchMode?: string;
}

const Row = ({ contact, onChange, searchMode }: RowProps) => (
  <tr>
    <td />
    <td>
      <Link to={() => onChange(contact)}>
        {contact.mode === "Contact" ? (
          contact.informalDisplayAs
        ) : (
          <>
            {searchMode !== "Company" && (
              <>
                <IconCompany />{" "}
              </>
            )}
            {contact.name}
            {contact.company && <> (Subsidiary of {contact.company.name})</>}
          </>
        )}
      </Link>
    </td>
    <td>
      {contact.mode === "Contact"
        ? contact.company?.name || <em>No Company</em>
        : ""}
    </td>
  </tr>
);
