import type {
  ComboBoxOption,
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle,
  GroupedComboBoxOption
} from "~/components/combobox";
import type {
  PlayersPickerQuery,
  PlayersPickerPlayerFieldsFragment
} from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import React, { useMemo } from "react";
import Badge from "~/components/badge";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import DisplayNames from "~/utils/display-names";
import { plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export type PlayerPickerPropsSingle = ComboBoxParentPropsSingle & {
  disableNoPhotos?: boolean;
  keyPhotoCount?: boolean;
  untagged?: boolean;
};
export type PlayerPickerPropsMulti = ComboBoxParentPropsMultiple & {
  disableNoPhotos?: boolean;
  keyPhotoCount?: boolean;
  untagged?: boolean;
};

export default function PlayerPicker({
  disableNoPhotos,
  keyPhotoCount,
  untagged,
  ...rest
}: PlayerPickerPropsSingle | PlayerPickerPropsMulti) {
  const project = useProject();
  const loader = useLoaderData<{ playerPicker?: PlayersPickerQuery } | null>();
  const fetcher = useFetcherData<PlayersPickerQuery>(
    loader?.playerPicker ? undefined : "/resources/players/picker",
    { projectId: project.id }
  );

  const players = useMemo(
    () => loader?.playerPicker?.players || fetcher.data?.players || [],
    [fetcher.data?.players, loader?.playerPicker?.players]
  );
  const playerGroups = useMemo(
    () =>
      loader?.playerPicker?.playerGroups || fetcher.data?.playerGroups || [],
    [fetcher.data?.playerGroups, loader?.playerPicker?.playerGroups]
  );

  const options = useMemo(() => {
    const optionFor = (
      item: PlayersPickerPlayerFieldsFragment
    ): ComboBoxOption => {
      const count = item.pfcsPhotoCount + item.otherPhotoCount;
      const disabled = disableNoPhotos && count === 0;

      return {
        value: item.id,
        indentLevel: 1,
        rightLabel:
          keyPhotoCount && item.pfcsKeyPhotoCount + item.otherKeyPhotoCount ? (
            <Badge
              mode="primary"
              label={plural(
                "Key Photo",
                item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
                true
              )}
            />
          ) : undefined,
        label: `${DisplayNames.player(item)}${
          disabled && disableNoPhotos ? " (No Photos)" : ""
        }`,
        disabled
      };
    };

    const sortedPlayers = _.sortBy(players, ["company", "name"]);
    const sortedPlayerGroups = _.sortBy(playerGroups, "position");

    const options: GroupedComboBoxOption[] = [];

    sortedPlayerGroups.forEach((group) => {
      options.push({
        label: group.name,
        options: sortedPlayers
          .filter((p) => p.playerGroup && p.playerGroup.id === group.id)
          .map(optionFor)
      });
    });

    const uncategorized = sortedPlayers.filter((p) => !p.playerGroup);
    if (uncategorized.length) {
      options.push({
        label: "Uncategorized Players",
        options: uncategorized.map(optionFor)
      });
    }

    if (untagged) {
      options.push({
        label: "Without Players",
        options: [
          {
            value: "untagged",
            label: "*** Without Players ***"
          }
        ]
      });
    }
    return options;
  }, [disableNoPhotos, keyPhotoCount, playerGroups, players, untagged]);

  return (
    <ComboBox placeholder="Select a Player..." {...rest} options={options} />
  );
}
