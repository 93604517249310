import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { TenTouchItemsQuery } from "~/types/api";
import _ from "lodash";
import React, { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { CONTENT_CATEGORIES } from "~/components/content/form";
import { useFetcherData } from "~/utils/remix";

export interface TenTouchItemPickerProps extends ComboBoxParentPropsSingle {
  touchType?: string;
}

export default React.memo(function TenTouchItemPicker({
  touchType,
  onChange,
  ...rest
}: TenTouchItemPickerProps) {
  const fetcher = useFetcherData<TenTouchItemsQuery>(
    `/resources/ten-touches/item-picker?active=true${
      rest.value ? `&includeId=${rest.value}` : ""
    }`
  );

  const tenTouchItems = useMemo(
    () => fetcher.data?.tenTouchItems || [],
    [fetcher.data]
  );

  const handleChange = (name: string, value: string) => {
    if (!value) {
      onChange?.(name, "", undefined);
    } else {
      const item = tenTouchItems.find((i) => i.id === value);
      onChange?.(name, value, item);
    }
  };

  const options = useMemo(() => {
    if (!touchType) {
      return [];
    }

    const items = _.sortBy(
      tenTouchItems.filter((item) => item.touchTypes.includes(touchType)),
      (i) => CONTENT_CATEGORIES.findIndex(([cat]) => cat === i.category),
      "contentTopic.title",
      "name"
    );

    return CONTENT_CATEGORIES.map(([cat]) => ({
      label: cat,
      options: items
        .filter((i) => i.contentTopic?.category === cat)
        .map((i) => ({
          value: i.id,
          label: `${i.contentTopic!.title}: ${i.name}`
        }))
    }));
  }, [tenTouchItems, touchType]);

  return (
    <ComboBox
      {...rest}
      placeholder={
        touchType
          ? "Select a 10-Touch Item..."
          : "Please select a type first..."
      }
      onChange={handleChange}
      options={options}
    />
  );
});
