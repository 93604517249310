import type { ButtonModes } from "~/components/button";
import type { LinkProps } from "~/components/link";
import clsx from "clsx";
import Link from "~/components/link";

interface Props extends LinkProps {
  mode?: ButtonModes;
  small?: boolean;
  large?: boolean;
  block?: boolean;
}

export default function ButtonLink({
  mode = "default",
  className = "",
  children,
  small,
  large,
  block,
  ...rest
}: Props) {
  return (
    <Link
      className={clsx(
        `btn btn-${mode}`,
        small ? "btn-sm" : large ? "btn-lg" : null,
        block && "btn-block",
        className
      )}
      {...rest}
    >
      {children}
    </Link>
  );
}
