import type { SetStateAction } from "jotai";
import type { CSSProperties, PropsWithChildren } from "react";
import clsx from "clsx";
import { produce } from "immer";
import React, { useMemo, useCallback, useRef } from "react";
import HeaderCell from "~/components/numbered-table-header-cell";
import { NumberedTableContext } from "~/contexts";

export type TableSortState = { key: string; direction: "asc" | "desc" }[];

interface Props extends PropsWithChildren<unknown> {
  className?: string;
  sort?: TableSortState;
  setSort?: React.Dispatch<SetStateAction<TableSortState>>;
  style?: CSSProperties;
  fixedColumn?: boolean;
  offset?: number;
  onSort?: (value: string, shift?: boolean) => void;
}

export default function NumberedTable({
  children,
  className,
  onSort,
  sort = [],
  setSort,
  fixedColumn,
  offset = 0,
  style
}: Props) {
  const tableRef = useRef(null);

  const localSetSort = useCallback(
    (value: string, shift?: boolean) => {
      setSort?.(
        produce((draft) => {
          const match = draft.find((s) => s.key === value);
          if (match && match.direction === "desc") {
            draft.splice(draft.indexOf(match), 1);
          } else if (match) {
            match.direction = "desc";
          } else if (shift) {
            draft.push({ key: value, direction: "asc" });
          } else {
            draft.splice(0, draft.length, { key: value, direction: "asc" });
          }
        })
      );
    },
    [setSort]
  );

  const sortHandler = useCallback(
    (value: string, shift?: boolean) => {
      if (setSort) {
        localSetSort(value, shift);
      } else {
        onSort?.(value, shift);
      }
    },
    [localSetSort, setSort, onSort]
  );

  const value = useMemo(
    () => ({ onSort: sortHandler, sort }),
    [sortHandler, sort]
  );

  const styleWithOffset = offset
    ? { ...(style || {}), counterReset: `row-num ${offset}` }
    : style;

  return (
    <NumberedTableContext.Provider value={value}>
      <table
        className={clsx(
          "numbered-table table-condensed table-hover numbered-table-next table",
          className
        )}
        ref={tableRef}
        style={styleWithOffset}
      >
        {children}
      </table>
    </NumberedTableContext.Provider>
  );
}

export { HeaderCell };
