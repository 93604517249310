import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { TrainingPlanPickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo, useCallback } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export default function TrainingPlanPicker({
  onChange,
  placeholder = "Select a Training Plan...",
  ...rest
}: ComboBoxParentPropsSingle) {
  const fetcher = useFetcherData<TrainingPlanPickerQuery>(
    "/resources/training/plan-picker"
  );

  const localOnChange = useCallback(
    (name: string, value: string) => {
      const plan = (fetcher.data?.trainingPlans || []).find(
        (p) => p.id === value
      );
      onChange?.(name, value, plan);
    },
    [fetcher.data, onChange]
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.trainingPlans || [], "name").map((o) => ({
        value: o.id,
        label: o.name
      })),
    [fetcher.data]
  );

  return (
    <ComboBox
      {...rest}
      placeholder={placeholder}
      onChange={localOnChange}
      options={options}
    />
  );
}
