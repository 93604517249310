import type { CSSProperties, PropsWithChildren } from "react";
import clsx from "clsx";

export interface PanelFooterProps extends PropsWithChildren<unknown> {
  style?: CSSProperties;
  className?: string;
}

export default function Footer({
  children,
  className = "",
  style
}: PanelFooterProps) {
  return (
    <div className={clsx("panel-footer", className)} style={style}>
      {children}
    </div>
  );
}
