import clsx from "clsx";
import _ from "lodash";
import Button from "~/components/button";

interface Props {
  selected: boolean;
  option: string | string[];
  onClick: (value: string) => void;
  className?: string;
}

export default function ButtonSelectOption({
  selected,
  option,
  onClick,
  className = ""
}: Props) {
  const handleClick = () => onClick(_.isArray(option) ? option[0] : option);
  const label = _.isArray(option) ? option[1] : option;

  return (
    <Button
      mode={selected ? "primary" : "default"}
      className={clsx(selected && "active", className)}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
}
