import type { ButtonProps } from "~/components/button";
import Button from "~/components/button";
import { ModalContext } from "~/contexts";

export default function ButtonCloseModal({ children, ...rest }: ButtonProps) {
  return (
    <ModalContext.Consumer>
      {({ close }) => (
        // Don't just pass `close`, we want to strip out the event
        // since some close handlers depend on an actual returned value
        <Button onClick={() => close()} {...rest}>
          {children || "Close"}
        </Button>
      )}
    </ModalContext.Consumer>
  );
}
