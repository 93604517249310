import type { Node, Point } from "slate";
import type { RenderElementProps } from "slate-react";
import type { ModalProps } from "~/components/modal";
import type {
  CustomElement,
  ListElement,
  ListItemElement,
  MyEditor
} from "~/components/rich-editor";
import * as Sentry from "@sentry/react";
import clsx from "clsx";
import _ from "lodash";
import { useState } from "react";
import { Editor, Range, Path, Transforms, Element } from "slate";
import { useSlate, ReactEditor } from "slate-react";
import { IconIndent, IconOutdent, IconSettings } from "~/components/icons";
import Modal from "~/components/modal";
import { Buttons, Input, RemixForm } from "~/components/remix-form";
import { PhotoSettingsForm } from "~/components/rich-editor/images";
import { isBlockActive } from "~/components/rich-editor/toolbar";

const isListNode = (node: Node) =>
  Element.isElement(node) &&
  ["ordered-list", "unordered-list"].includes(node.type as string);

export const isList = (editor: Editor) =>
  editor.selection &&
  (Editor.parent(editor, Range.start(editor.selection))[0] as CustomElement)
    .type === "list-item-child";

const increaseDepth = (editor: Editor) => {
  if (!isList(editor)) return;

  const start = Range.start(editor.selection!);

  // Get the current list-item
  const item = Editor.parent(editor, Path.parent(start.path));
  // Get the current list
  const list = Editor.parent(editor, item[1]);
  const itemPath = item[1];

  // Get the previous list-item
  const previousPath =
    itemPath[itemPath.length - 1] === 0 ? undefined : Path.previous(item[1]);

  if (previousPath) {
    const prev = Editor.node(editor, previousPath)[0] as CustomElement;

    if (prev.children.length === 2) {
      Transforms.moveNodes(editor, {
        at: item[1],
        to: [
          ...previousPath,
          1,
          (prev.children[1] as CustomElement).children.length
        ]
      });
    } else {
      const newPath = [...previousPath, 1];
      Editor.withoutNormalizing(editor, () => {
        Transforms.insertNodes(
          editor,
          { type: (list[0] as ListElement).type, children: [] },
          { at: newPath }
        );
        Transforms.moveNodes(editor, {
          at: item[1],
          to: [...newPath, 0]
        });
      });
    }
  }
};

const decreaseDepth = (editor: Editor) => {
  if (!isList(editor)) return;
  const start = Range.start(editor.selection!);
  const [item, itemPath] = Editor.parent(editor, Path.parent(start.path)) as [
    ListItemElement,
    Path
  ];
  const [list, listPath] = Editor.parent(editor, itemPath) as [
    ListElement,
    Path
  ];
  const [parentListItem, parentListItemPath] = Editor.parent(
    editor,
    listPath
  ) as [CustomElement, Path];
  if (parentListItem.type !== "list-item") return;

  const [parentList, parentListPath] = Editor.parent(
    editor,
    parentListItemPath
  ) as [ListElement, Path];
  const index = parentList.children.indexOf(parentListItem);

  const x = list.children.indexOf(item);
  const otherItems = list.children.slice(x + 1);

  Editor.withoutNormalizing(editor, () => {
    if (otherItems.length) {
      if (item.children.length === 1) {
        Transforms.insertNodes(
          editor,
          { type: list.type, children: [] },
          { at: [...itemPath, 1] }
        );
      }
      const [newList] = Editor.node(editor, [...itemPath, 1]);

      otherItems.forEach((_item, index) => {
        Transforms.moveNodes(editor, {
          at: [...listPath, x + 1],
          to: [
            ...itemPath,
            1,
            index + (newList as CustomElement).children.length
          ]
        });
      });
    }
    Transforms.moveNodes(editor, {
      at: itemPath,
      to: [...parentListPath, index + 1]
    });
    if (x === 0) {
      Transforms.removeNodes(editor, { at: listPath });
    }
  });
};

const unwrapList = (editor: Editor) => {
  Editor.withoutNormalizing(editor, () => {
    Transforms.unwrapNodes(editor, { match: isListNode, split: true });
    const [item, itemPath] = Editor.parent(
      editor,
      Path.parent(Range.start(editor.selection!).path)
    ) as [ListItemElement, Path];
    const listIndex = editor.children.indexOf(item);

    item.children.forEach((child, index) => {
      Transforms.moveNodes(editor, {
        at: [...itemPath, 0],
        to: [index + 1 + listIndex]
      });
      if (child.type === "list-item-child") {
        Transforms.setNodes(
          editor,
          { type: "paragraph" },
          { at: [index + 1 + listIndex] }
        );
      }
    });
    Transforms.removeNodes(editor, { at: itemPath });
  });
};

const decreaseOrUnwrap = (editor: Editor) => {
  const point = Range.start(editor.selection!);
  if (point.path.length === 4) {
    unwrapList(editor);
  } else {
    decreaseDepth(editor);
  }
};

const onEnter = (editor: Editor) => {
  if (Range.isExpanded(editor.selection!)) {
    Transforms.delete(editor);
  }

  let point: Point | undefined = Range.start(editor.selection!);
  const [block] = Editor.nodes(editor, {
    at: point,
    mode: "lowest",
    match: (node) => Element.isElement(node) && Editor.isBlock(editor, node)
  });

  if (
    Element.isElement(block[0]) &&
    Editor.isBlock(editor, block[0]) &&
    Editor.isStart(editor, point, point.path) &&
    Editor.isEmpty(editor, block[0])
  ) {
    decreaseOrUnwrap(editor);
    return;
  }

  // Fix hitting enter at the end of an inline
  const [inline] = Editor.nodes(editor, {
    match: (node) => editor.isInline(node as Element)
  });

  if (inline && Editor.isEnd(editor, editor.selection!.anchor, inline[1])) {
    point = Editor.after(editor, inline[1]);
    Transforms.setSelection(editor, { anchor: point, focus: point });
  }

  editor.insertBreak();
};

// Handle backspacing at the beginning of a list item
const onBackspace = (editor: Editor, event: React.KeyboardEvent) => {
  if (Range.isExpanded(editor.selection!)) return;

  // Only handle beginning of a line
  const point = Range.start(editor.selection!);
  if (!Editor.isStart(editor, point, point.path)) return;

  // Ensure we're in a list item child
  const [element] = Editor.nodes(editor, {
    at: point,
    mode: "lowest",
    match: (node) => Element.isElement(node) && Editor.isBlock(editor, node)
  });
  if (!element || (element[0] as Element).type !== "list-item-child") return;

  // If the previous node is a list at the same level, let Slate merge it instead
  const prevNode = Editor.previous(editor, { at: point });
  const prevIsList = prevNode
    ? (Editor.parent(editor, prevNode[1])[0] as CustomElement).type ===
      "list-item-child"
    : false;
  if (prevIsList && prevNode![1].length === point.path.length) return;

  // Prevent backspace and unwrap the list instead
  event.preventDefault();
  decreaseOrUnwrap(editor);
};

export const handleListKeys = (
  editor: Editor,
  event: React.KeyboardEvent,
  callback: (event: React.KeyboardEvent) => void
) => {
  if (!editor.selection) {
    callback(event);
  } else {
    const [block] = Editor.nodes(editor, {
      at: Range.start(editor.selection),
      mode: "lowest",
      match: (node) => Element.isElement(node) && Editor.isBlock(editor, node)
    });

    // Not sure how, but this can sometimes be empty?
    if (!block) {
      callback(event);
      return;
    }

    const next = Editor.next(editor, { at: block[1] }) as [CustomElement, Path];
    if (
      next &&
      isListNode(next[0]) &&
      event.key === "Delete" &&
      Editor.isEnd(editor, Range.start(editor.selection), block[1]) &&
      ((next[0].children as ListItemElement[])[0].children as Node[]).length > 1
    ) {
      Editor.withoutNormalizing(editor, () => {
        Transforms.move(editor, { distance: 1, unit: "character" });
        decreaseOrUnwrap(editor);
        Transforms.move(editor, {
          distance: 1,
          unit: "character",
          reverse: true
        });
      });
    }

    if (!Element.isElement(block[0]) || block[0].type !== "list-item-child") {
      callback(event);
      return;
    }

    if (!event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      onEnter(editor);
    } else if (!event.shiftKey && event.key === "Backspace") {
      onBackspace(editor, event);
    } else if (event.key === "Tab") {
      event.preventDefault();
      event.shiftKey ? decreaseDepth(editor) : increaseDepth(editor);
    } else {
      callback(event);
    }
  }
};

export const OrderedList = ({
  element,
  attributes,
  children
}: RenderElementProps) => {
  const e = element as ListElement;
  return (
    <ol
      start={e.start as number}
      {...attributes}
      style={e.mode ? { listStyleType: e.mode } : undefined}
    >
      {children}
    </ol>
  );
};

type ListIndentButtonProps = { mode: "indent" | "outdent" };

export const ListIndentButton = ({ mode }: ListIndentButtonProps) => {
  const editor = useSlate();
  const list =
    editor.selection && Range.isCollapsed(editor.selection) && isList(editor);
  return (
    <span
      title={`${mode === "indent" ? "Increase" : "Decrease"} indent`}
      className={clsx(list ? "cursor-pointer" : "text-gray-300", "mr-2")}
      onMouseDown={(event) => {
        event.preventDefault();
        if (list) {
          mode === "indent" ? increaseDepth(editor) : decreaseDepth(editor);
        }
      }}
    >
      {mode === "indent" ? <IconIndent fixed /> : <IconOutdent fixed />}
    </span>
  );
};
export const ListSettingsButton = () => {
  const editor = useSlate();
  const [path, setPath] = useState<number[] | null>(null);
  const [mode, setMode] = useState("list");
  const [savedSelection, setSavedSelection] = useState<Range | null>(null);
  let button = <IconSettings fixed className="text-gray-300" />;
  if (
    editor.selection &&
    Range.isCollapsed(editor.selection) &&
    isList(editor)
  ) {
    Sentry.addBreadcrumb({
      category: "is-list",
      message: "editor state",
      data: {
        editor: JSON.stringify(editor.children)
          .replace(/"children"/g, '"c"')
          .replace(/"type"/g, '"t"')
      },
      level: "info"
    });
    Sentry.addBreadcrumb({
      category: "is-list",
      message: "editor selection",
      data: {
        selection: JSON.stringify(editor.selection)
      },
      level: "info"
    });
    const [, licPath] = Editor.parent(editor, Range.start(editor.selection));
    const [, liPath] = licPath.length
      ? Editor.parent(editor, licPath)
      : [null, []];
    const [list, listPath] = liPath.length
      ? (Editor.parent(editor, liPath) as [CustomElement, Path])
      : [null, []];
    if (list?.type === "ordered-list") {
      button = (
        <IconSettings
          fixed
          className="cursor-pointer"
          onClick={(e) => {
            setSavedSelection(editor.selection);
            setPath(listPath);
            setMode("list");
          }}
        />
      );
    }
  } else if (editor.selection && Range.isCollapsed(editor.selection)) {
    const match = Editor.above(editor, {
      match: (node) =>
        Element.isElement(node) && ["pfcs-photo", "image"].includes(node.type)
    });
    if (match) {
      button = (
        <IconSettings
          fixed
          className="cursor-pointer"
          onClick={(e) => {
            setSavedSelection(editor.selection);
            setPath(match[1]);
            setMode("image");
          }}
        />
      );
    }
  }

  return (
    <>
      <span className={editor.selection ? "cursor-pointer" : ""}>{button}</span>
      {path &&
        (mode === "list" ? (
          <ListSettingsForm
            editor={editor}
            path={path}
            onClose={() => {
              ReactEditor.focus(editor);
              setTimeout(() => Transforms.select(editor, savedSelection!), 10);
              setPath(null);
              setMode("");
            }}
          />
        ) : mode === "image" ? (
          <PhotoSettingsForm
            editor={editor}
            path={path}
            onClose={() => {
              ReactEditor.focus(editor);
              setTimeout(() => Transforms.select(editor, savedSelection!), 10);
              setPath(null);
              setMode("");
            }}
          />
        ) : null)}
    </>
  );
};

interface ListSettingsFormProps extends ModalProps {
  editor: Editor;
  path: number[];
}

const ListSettingsForm = ({ onClose, editor, path }: ListSettingsFormProps) => {
  const list = Editor.node(editor, path)[0] as ListElement;
  const [mode, setMode] = useState(list.mode || "default");
  const [start, setStart] = useState(list.start?.toString() || "1");

  const save = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let num: string | number = start;
    if (_.isString(num)) {
      const s = start.trim();
      num = s.match(/^\d+$/) ? parseInt(s) : 1;
    }
    const m = mode === "default" ? undefined : mode;
    Transforms.setNodes(editor, { start: num, mode: m }, { at: path });
    onClose();
  };
  return (
    <Modal onExplicitClose={onClose}>
      <RemixForm onSubmit={save}>
        <Modal.Header title="List Options" />
        <Modal.Body>
          <Input
            name="start"
            value={start}
            onChange={(e) => setStart(e.target.value)}
            label="Starting Number"
            autoFocus
          />
          <Input
            name="mode"
            type="combo"
            value={mode}
            onChange={(_name, value) => setMode(value)}
            isClearable={false}
            options={[
              ["Default for this level", "default"],
              ["Number", "decimal"],
              ["Upper Letter", "upper-alpha"],
              ["Lower Letter", "lower-alpha"]
            ]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};

export const withLists = (editor: MyEditor) => {
  const { toggleBlock } = editor;
  editor.toggleBlock = (format) => {
    if (format !== "page-break") {
      const isActive = isBlockActive(editor, format);
      const isList = ["ordered-list", "unordered-list"].includes(format);

      Editor.withoutNormalizing(editor, () => {
        const topNodes = Array.from(
          Editor.nodes(editor, {
            match: (node) => Element.isElement(node),
            mode: "highest"
          })
        );
        const [parentEntry] = Editor.nodes(editor, {
          match: (node) =>
            Element.isElement(node) &&
            ["ordered-list", "unordered-list"].includes(node.type as string),
          mode: "lowest"
        });

        if (
          Range.isCollapsed(editor.selection!) &&
          parentEntry &&
          Element.isElement(parentEntry[0]) &&
          parentEntry[0].type !== format &&
          isList
        ) {
          Transforms.setNodes(
            editor,
            { type: format as CustomElement["type"] },
            { at: parentEntry[1] }
          );
          return;
        }
        // If everything highlighted is already part of a list and at least some is not the list type we clicked,
        // then we're just toggling the list type
        else if (
          topNodes.every(
            (n) =>
              Element.isElement(n[0]) &&
              ["ordered-list", "unordered-list"].includes(n[0].type)
          ) &&
          topNodes.some(
            (n) => Element.isElement(n[0]) && n[0].type !== format
          ) &&
          isList
        ) {
          Editor.withoutNormalizing(editor, () => {
            Transforms.setNodes(
              editor,
              { type: format as CustomElement["type"] },
              {
                match: (n) =>
                  Element.isElement(n) &&
                  ["ordered-list", "unordered-list"].includes(n.type),
                mode: "highest"
              }
            );
          });
          return;
        }
        Transforms.unwrapNodes(editor, {
          match: (n) =>
            Element.isElement(n) &&
            ["ordered-list", "unordered-list", "list-item"].includes(n.type),
          mode: "all",
          split: true
        });
        // This expects arg 2 to be the same type as arg 3's match...
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Transforms.setNodes<any>(
          editor,
          { type: "paragraph" },
          {
            match: (n) => Element.isElement(n) && n.type === "list-item-child"
          }
        );
        Transforms.setNodes(editor, {
          type: isActive
            ? "paragraph"
            : isList
              ? "list-item-child"
              : (format as CustomElement["type"])
        });
        if (!isActive && isList) {
          Transforms.wrapNodes(editor, {
            type: format as "list-item-child", // not sure if this is the right typecast?
            children: []
          });
          Transforms.wrapNodes(editor, {
            type: "list-item",
            children: []
          });
        }
      });
      return;
    }

    toggleBlock(format);
  };

  return editor;
};
