import type { ReactNode } from "react";
import type {
  ShowContactFieldsFragment,
  InvoiceDashProjectFieldsFragment,
  TagFieldsFragment
} from "~/types/api";
import { useFetcher, useLoaderData } from "@remix-run/react";
import _ from "lodash";
import React, { useState, useRef } from "react";
import Alert from "~/components/alert";
import Button from "~/components/button";
import { IconEdit, IconCheck, IconX } from "~/components/icons";
import { ListGroup } from "~/components/list-group";
import Panel from "~/components/panel";
import { Input } from "~/components/remix-form";
import TagGroup from "~/components/tags/group";

export const TAG_SECTIONS = [
  "Area",
  "Industry",
  "Practice Areas",
  "Role",
  "Filters",
  "Segment",
  "Other"
];

interface Props {
  parent?: ShowContactFieldsFragment | InvoiceDashProjectFieldsFragment;
  onClick?: (tag: TagFieldsFragment) => void;
  selected?: string[];
  noGroups?: boolean;
  button?: ReactNode;
  noSubsections?: boolean;
}

export default function Tags({
  onClick,
  parent,
  button,
  selected,
  noGroups,
  noSubsections
}: Props) {
  const { tags } = useLoaderData<{ tags: TagFieldsFragment[] }>();
  const [editing, setEditing] = useState(false);
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const fetcherDelete = useFetcher<unknown>();
  const fetcherAdd = useFetcher<unknown>();

  let allTags = _.sortBy(tags, "position", "category");

  if (search) {
    allTags = allTags.filter((t) =>
      t.category.toLowerCase().includes(search.toLowerCase())
    );
  }
  const tagIds = parent?.taggings.map((t) => t.tag.id);

  const toggleTag = (tag: TagFieldsFragment) => {
    if (!parent) return null;

    const tagIds = parent.taggings.map((t) => t.tag.id);
    if (tagIds.includes(tag.id)) {
      const tagging = parent.taggings.find((t) => t.tag.id === tag.id);
      tagging &&
        fetcherDelete.submit(
          { action: "delete-tagging", id: tagging.id },
          { method: "post" }
        );
    } else {
      const variables = {
        action: "create-tagging",
        tagId: tag.id,
        contactId: parent.__typename === "Contact" ? parent.id : "",
        projectId: parent.__typename === "Project" ? parent.id : ""
      };
      fetcherAdd.submit(variables, { method: "post" });
    }
    if (inputRef?.current) {
      inputRef.current.focus({ preventScroll: true });
      inputRef.current.select();
    }
  };

  const groups = _.groupBy(
    _.sortBy(
      parent ? parent.taggings.map((t) => t.tag) : allTags,
      "position",
      "category"
    ),
    "section"
  );

  const toggleEdit = () => {
    if (editing) {
      setEditing(false);
      setSearch("");
    } else {
      setEditing(true);
      setTimeout(() => inputRef.current && inputRef.current.focus(), 50);
    }
  };

  return (
    <Panel>
      <Panel.Header
        title="Tags"
        button={
          button ? (
            button
          ) : parent ? (
            <Button
              onClick={toggleEdit}
              small
              mode={editing ? "primary" : "default"}
            >
              {editing ? (
                <span>
                  <IconCheck /> Done
                </span>
              ) : (
                <span>
                  <IconEdit /> Edit
                </span>
              )}
            </Button>
          ) : null
        }
      >
        {editing && (
          <div className="form-inline -my-4">
            <Input
              name="search"
              noLabel
              autoFocus
              forwardRef={inputRef}
              placeholder="Filter tags..."
              className="input-sm"
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              buttons={
                <Button
                  small
                  onClick={() => {
                    setSearch("");
                    inputRef.current!.focus();
                  }}
                >
                  <IconX />
                </Button>
              }
            />
          </div>
        )}
      </Panel.Header>
      {parent && parent.taggings.length === 0 && !editing ? (
        <Alert mode="warning">
          This{" "}
          {parent.__typename === "Project"
            ? "project"
            : parent.__typename === "Contact" && parent.mode === "Company"
              ? "company"
              : "contact"}{" "}
          doesn&apos;t have any tags!
        </Alert>
      ) : search && !allTags.length ? (
        <Alert mode="warning">No tags match your search</Alert>
      ) : (
        <ListGroup small>
          {TAG_SECTIONS.map((g) => (
            <TagGroup
              onClick={editing ? toggleTag : onClick}
              noGroups={noGroups}
              selected={editing ? tagIds : selected}
              noSubsections={editing ? false : noSubsections}
              section={g}
              tags={
                editing ? allTags.filter((t) => t.section === g) : groups[g]
              }
              key={g}
              parent={parent}
            />
          ))}
        </ListGroup>
      )}
    </Panel>
  );
}
