import type { DateOptTypes, ParseDateInput } from "~/utils/dates";
import clsx from "clsx";
import { useId, type ReactNode } from "react";
import { Tooltip } from "react-tooltip";
import { dayIsBefore, formatDate } from "~/utils/dates";
import { ClientOnly } from "~/utils/remix";

type Props = {
  date?: ParseDateInput | null;
  format?: DateOptTypes["format"];
  fallback?: ReactNode;
  className?: string;
  title?: string;
  tooltip?: string;
  highlightPastDue?: boolean;
};

export default function FormattedDate({
  date,
  format,
  fallback,
  className = "",
  tooltip,
  title,
  highlightPastDue
}: Props) {
  const id = useId();
  const pastDue = highlightPastDue && date && dayIsBefore(date, new Date());

  return (
    <span
      suppressHydrationWarning
      className={clsx(
        className,
        "whitespace-nowrap",
        !!pastDue && "font-bold text-red-600"
      )}
      title={title}
      data-tooltip-id={id}
    >
      {date ? formatDate(date, { format }) : fallback || ""}
      {tooltip && (
        <ClientOnly>
          {() => (
            <Tooltip id={id} place="bottom" content={tooltip} delayShow={500} />
          )}
        </ClientOnly>
      )}
    </span>
  );
}
